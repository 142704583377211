import PropTypes from "prop-types"
import { useRef } from "react"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import { Video } from "./Video.jsx"
import useIsMobile from "../../../hooks/useIsMobile.js"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import { LeagueModalBase } from "../LeagueModalBase/LeagueModalBase.jsx"
import "swiper/css"
import "swiper/css/pagination"

import "./VideoCard.scss"

export const VideoCard = ({ group }) => {
    const isNameString = typeof group.name === "string"

    const openModal = () => {
        ModalHandler.show(VideoModal, {
            videos: group.angles,
        })
    }

    return (
        <div className={"video-card"}>
            <div className="video-preview" data-testid="videoPreview" onClick={openModal}>
                <img
                    className={"video-thumbnail"}
                    src={group.thumbnail}
                    alt={isNameString ? group.name : "video thumbnail"}
                />
                <img className={"play-button"} src="/assets/icons/video-play-button.svg" alt="play button"/>
            </div>
            <div className="video-info">
                {isNameString ? (
                    <h3 className="video-title">{group.name}</h3>
                ) : (
                    <>
                        {group.name}
                    </>
                )}
            </div>
        </div>
    )
}
/* istanbul ignore next */
const VideoModal = ModalHandler.create(({ visible, hide, videos }) => {
    const isMobile = useIsMobile()
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <LeagueModalBase onClose={hide} visible={visible} className={"video-modal"}>
            <div className={"videos"}>
                <Swiper
                    updateOnWindowResize={true}
                    modules={[ Navigation ]}
                    slidesPerView={(isMobile || videos?.length < 2) ? 1 : 2}
                    spaceBetween={20}
                    navigation={
                        {
                            nextEl: navigationNextRef.current,
                            prevEl: navigationPrevRef.current,
                        }
                    }
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                    }}
                    draggable
                >

                    {videos?.map((video, index) => (
                        <SwiperSlide key={index}>
                            <Video video={video}/>
                        </SwiperSlide>
                    ))}
                    {videos?.length > 1 && (
                        <div className={"swiper-navigation"}>
                            <div className="swiper-button-prev" ref={navigationPrevRef}>
                                <img src="/assets/icons/arrow-left-black.svg" alt="Précédent"/>
                            </div>
                            <div className="swiper-button-next" ref={navigationNextRef}>
                                <img src="/assets/icons/arrow-right-black.svg" alt="Précédent"/>
                            </div>
                        </div>
                    )}
                </Swiper>
            </div>

            <div className={"swiper-pagination"}/>
        </LeagueModalBase>
    )
})

VideoCard.propTypes = {
    group: PropTypes.shape({
        angles: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            thumbnail: PropTypes.string,
            url: PropTypes.string.isRequired,
        })),
        name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ]),
        thumbnail: PropTypes.string,
    }),
}

