import classNames from "classnames"
import PropTypes, { bool } from "prop-types"
import { forwardRef, useState } from "react"

import useMediaPath from "../../../hooks/useMediaPath.js"
import Preloader from "../../loaders/preloader/preloader.jsx"
import { TooltipLight } from "../../tooltip/tooltipLight/tooltipLight.jsx"

import "./TextInput.scss"

export const TextInput = forwardRef(({
    label,
    name,
    onChange,
    placeholder,
    required,
    type,
    value,
    maxWidth,
    inputMode,
    error,
    customRootCls,
    customCls,
    maxLength,
    disabled,
    helperText,
    onEnter,
    onFocus,
    onBlur,
    id,
    autoFocus,
    actions,
    fullWidth,
    minLength,
    iconSize,
    defaultValue,
    loading,
    labelOnTop,
}, ref) => {
    const [ isPasswordVisible, setIsPasswordVisible ] = useState(false)
    const mediaPath = useMediaPath()
    const togglePasswordVisibility = () => setIsPasswordVisible(!isPasswordVisible)
    const maxWidthStyle = maxWidth === "full" ? "100%" : `${maxWidth}px`
    const isErrorString = typeof error === "string"

    return (
        <div
            className={classNames("field-container", {
                [customRootCls]:customRootCls,
                fullWidth:fullWidth,
                labelOnTop,
            })}
            style={{ maxWidth: maxWidthStyle }}
        >
            <div className={classNames("text-input-wrapper", {
                disabled: disabled,
                error: error,
            })}>
                <div
                    className={classNames("text-input", {
                        [customCls]:customCls,
                    })}
                >
                    <label className="text-input-label" htmlFor={name}>
                        {label}
                        {required && <span className="text-input-required">*</span>}
                    </label>
                    <input
                        id={id}
                        ref={ref}
                        type={type === "password" && !isPasswordVisible ? "password" : "text"}
                        name={name ? name : id}
                        maxLength={maxLength}
                        minLength={minLength ? minLength : type === "email" ? "5" : undefined}
                        inputMode={inputMode}
                        onChange={(e) => {
                            if (onChange) {
                                onChange(e.target.value)
                            }
                        }}
                        value={value}
                        disabled={disabled}
                        placeholder={placeholder}
                        required={required}
                        className="text-input-field"
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={
                            onEnter ?
                                (ev) => {
                                    if (ev.key === "Enter") {
                                        ev.preventDefault()
                                        onEnter()
                                    }
                                }
                                :
                                undefined
                        }
                        autoFocus={autoFocus}
                        defaultValue={defaultValue}
                    />
                </div>
                <div className={classNames("input-actions", {
                    [iconSize]:iconSize,
                })}>
                    {type === "password" && (
                        <div className={"input-action"} onClick={togglePasswordVisibility}>
                            <img
                                alt="password-visibility"
                                src={isPasswordVisible ? "/assets/icons/visibilityVisible.svg" : "/assets/icons/visibility.svg"}
                            />
                        </div>
                    )}
                    {helperText && (
                        <div className={"input-action"}>
                            <TooltipLight text={helperText}>
                                <img
                                    alt="helper-text"
                                    src={
                                        mediaPath([ "/assets/icons/questionMark.svg", "/assets/images/padel/icons/icons-notifications.svg" ])
                                    }
                                />
                            </TooltipLight>
                        </div>
                    )}
                    {actions && actions.map((action, index) => (
                        <div
                            key={index}
                            className={"input-action"}
                            onClick={action.onClick}
                        >
                            {typeof action.render === "string" ? (
                                <img alt="action-icon" src={action.render} />
                            ) : (
                                action.render
                            )}
                        </div>
                    ))}
                    {loading && (
                        <div className={"input-action"}>
                            <Preloader cls={"inputPreloader"} />
                        </div>
                    )}
                </div>
            </div>
            {isErrorString ? <p className="text-input-error">{error}</p> : error}
        </div>
    )
})

TextInput.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        render: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
            PropTypes.node,
        ]),
    })),
    autoFocus: PropTypes.bool,
    customCls: PropTypes.string,
    customRootCls: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    iconSize: PropTypes.oneOf([ "small", "medium", "large" ]),
    id: PropTypes.string,
    inputMode: PropTypes.string,
    label: PropTypes.string,
    labelOnTop: bool,
    loading: PropTypes.bool,
    maxLength: PropTypes.number,
    maxWidth: PropTypes.oneOf([ "full", PropTypes.number ]),
    minLength: PropTypes.number,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string,
}

TextInput.defaultProps = {
    iconSize: "large",
    maxWidth: "full",
    required: false,
    type: "text",
}

TextInput.displayName = "TextInput"
