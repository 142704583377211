import classNames from "classnames"
import PropTypes, { string } from "prop-types"
import { forwardRef } from "react"

import useMediaPath from "../../../hooks/useMediaPath.js"
import Preloader from "../../loaders/preloader/preloader.jsx"

import "./SelectInput.scss"

export const SelectInput = forwardRef(
    (
        {
            label,
            name,
            options,
            disabled,
            id,
            autoFocus,
            error,
            customRootCls,
            value,
            onChange,
            required,
            customArrow,
            hasAfterSelect,
            maxWidth,
            fullWidth,
            placeholderOption,
            customCls,
            loading,
            ...rest
        },
        ref,
    ) => {
        const mediaPath = useMediaPath()
        const backgroundSelector = mediaPath(customArrow ?? [
            "url('/assets/icons/arrow-down-orange.svg')",
            "url('/assets/images/padel/icons/icon-arrow-down.svg')",
        ])

        const maxWidthStyle = maxWidth === "full" ? "100%" : `${maxWidth}px`
        const isErrorString = typeof error === "string"

        return (
            <div
                className={classNames("field-container", {
                    [customRootCls]: customRootCls,
                    fullWidth:fullWidth,
                })}
                style={{ maxWidth: maxWidthStyle }}
            >
                <div className={classNames("select-input-wrapper", {
                    disabled: disabled,
                    error: error,
                    noLabel: !label,
                })}>
                    <div
                        className={classNames("select-input", {
                            [customCls]:customCls,
                        })}
                    >
                        <label className="select-input-label" htmlFor={name ?? id}>
                            {label}
                            {required && label && <span className="select-input-required">*</span>}
                        </label>
                        <select
                            id={id}
                            name={name ?? id}
                            ref={ref}
                            required={required}
                            disabled={disabled}
                            value={value}
                            onChange={onChange}
                            autoFocus={autoFocus}
                            className="select-input-field"
                            style={{
                                backgroundImage: disabled ?
                                    "url('/assets/icons/arrow-down-disabled.svg')"
                                    :
                                    backgroundSelector,
                            }}
                            {...rest}
                        >
                            {options === undefined ?
                                (<option value="">Chargement...</option>)
                                :
                                <option value="" disabled={required}>{placeholderOption}</option>
                            }
                            {options.map((option, index) => (
                                <option key={index} value={option.value} disabled={option.isDisabled}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {loading && (
                            <div className={"input-loader"}>
                                <Preloader cls={"inputPreloader"} />
                            </div>
                        )}
                    </div>
                    {hasAfterSelect && (
                        <div className={"after-select-wrapper"}>
                            { hasAfterSelect }
                        </div>
                    )}
                </div>
                {isErrorString && <p className="select-input-error">{error}</p>}
            </div>
        )
    },
)

SelectInput.propTypes = {
    autoFocus: PropTypes.bool,
    customArrow: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf(PropTypes.string) ]),
    customCls: PropTypes.string,
    customRootCls: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    fullWidth: PropTypes.bool,
    hasAfterSelect: PropTypes.oneOfType([ PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]),
    id: PropTypes.string,
    label: PropTypes.string,
    loading: PropTypes.bool,
    maxWidth: PropTypes.oneOf([ "full", PropTypes.number ]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            isDisabled: PropTypes.bool,
            label: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
            value: PropTypes.any.isRequired,
        }),
    ),
    placeholderOption: string,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
}

SelectInput.defaultProps = {
    options: [],
}

SelectInput.displayName = "SelectInput"
