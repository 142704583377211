import classNames from "classnames"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import { deleteMVPVote, getMVP, invitationChangeState, remindPendingPlayers, voteForMVP } from "./api"
import APP_CONFIG from "../../../../business/config"
import ButtonCta from "../../../../components/Buttons/button/button-cta"
import Preloader from "../../../../components/loaders/preloader/preloader"
import Modal from "../../../../components/Modal/Modal"
import playersNumber from "../../../../components/reservation/LocationBlock/playersNumber"
import PlayerLine from "../../../../components/reservation/PlayerLine/PlayerLine"
import ShareLink from "../../../../components/ShareLink/ShareLink"
import SuperSubLocationRecap from "../../../../components/SuperSub/SuperSubLocationRecap/SuperSubLocationRecap"
import { useTheme } from "../../../../components/ThemeContext/ThemeContext"
import { getLocation } from "../../../../globalAPI/api"
import useMediaPath from "../../../../hooks/useMediaPath"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import usePath from "../../../../routes/services/usePath"
import generateShareLink from "../../../../services/generateShareLink"
import { processPlayersCompositionResponse } from "../../../../services/locationManagementService"
import mustChangeTeamsNames from "../../../../services/mustChangeTeamsNames"
import goalAlphabeticalPlayerSort from "../../../../services/Sorting/goalAlphabeticalPlayerSort"
import { useLocationStore } from "../../store/store.js"
import BookingInvitation from "../components/BookingInvitation/BookingInvitation.jsx"
import RemindPopin from "../components/RemindPopin"
import {
    getPlayersComposition,
    modifyPlayersComposition,
    registerLocationTeamsNames,
} from "../LocationCompositionManagement/api"

import "./LocationPlayersManagement.scss"

function LocationPlayersManagement() {
    const [ linkToShare, setLinkToShare ] = useState("")
    const [ publicDetailsGenerated, setPublicDetailsGenerated ] = useState(false)
    const {
        currentLocation: location,
        setCurrentLocation,
    } = useLocationStore()

    const userLoaded = useSelector(state => state.userLoaded)
    const routerLocation = useLocation()
    const mediaPath = useMediaPath()
    const presentPlayers = location.composition

    const MVP = location.MVP
    const goals = location.goal
    const theme = useTheme()
    const themeHeader = useThemeHeader()
    const path = usePath()
    const locationPlayersNumber = location ? playersNumber?.find(item => item.types.includes(location.resourceType))?.players : 10
    const teamDisplay = locationPlayersNumber ?
        Array.from(Array(locationPlayersNumber / 2)).map((_, i) => `${i + 1}`)
        :
        [ "joueur1", "joueur2", "joueur3", "joueur4", "joueur5" ]

    const nextPossibleRemindDate = (location?.lastAttendeesReminderDate) ?
        dayjs(location.lastAttendeesReminderDate).add(1, "day").add(1, "minute").format("DD/MM/YYYY HH:mm")
        :
        null

    const isMatchPlayed = location?.end && new Date(location.end) <= new Date()
    const navigate = useNavigate()

    const noPlayer = location?.invites ?
        location.invites.length === 0
        || (location.invites.length === 1 && location.invites[0].playerId === userLoaded.contactId)
        :
        true

    const filterStatusAttente = item => item.status === 0
    const filterStatusPresent = item => item.status === 1
    const filterStatusAbsent = item => item.status === 2

    const filterTeam1Players = player => player.teamId && player.teamId === 1// && player.teamSlot !== null;
    const filterTeam2Players = player => player.teamId && player.teamId === 2// && player.teamSlot !== null;
    const filterNoTeamPlayers = player => !player.teamId || (player.teamSlot !== 0 && !player.teamSlot)

    const playerScored = (player) => {
        if (goals?.length) {
            return goals.reduce(
                (goalsCount, newGoalsCount) => {
                    if (newGoalsCount.playerId === player.playerId) {
                        return goalsCount + 1
                    } else {
                        return goalsCount
                    }
                }, 0,
            )
        }
        return false
    }
    const addGoalsToPlayer = player => {
        return { ...player, goals:  playerScored(player) }
    }
    const attente = location?.invites ?
        location.invites.filter(filterStatusAttente).map(addGoalsToPlayer).sort(goalAlphabeticalPlayerSort)
        :
        []

    const presents = location?.invites ?
        location.invites.filter(filterStatusPresent).map(addGoalsToPlayer).sort(goalAlphabeticalPlayerSort).map(player => ({
            ...player,
            teamId: presentPlayers?.find(present => present.playerId === player.playerId)?.teamId,
            teamSlot: presentPlayers?.find(present => present.playerId === player.playerId)?.teamSlot,
        }))
        :
        []

    const presentsTeam1 = presentPlayers ? presentPlayers.filter(filterTeam1Players).map(addGoalsToPlayer).sort(goalAlphabeticalPlayerSort) : []
    const presentsTeam2 = presentPlayers ? presentPlayers.filter(filterTeam2Players).map(addGoalsToPlayer).sort(goalAlphabeticalPlayerSort) : []
    const presentsNoTeam = presentPlayers ? presentPlayers.filter(filterNoTeamPlayers).map(addGoalsToPlayer).sort(goalAlphabeticalPlayerSort) : []

    const absents = location?.invites ?
        location.invites.filter(filterStatusAbsent).map(addGoalsToPlayer).sort(goalAlphabeticalPlayerSort)
        :
        []

    const [ selectedPlayerId, setSelectedPlayerId ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(!location?.id)

    function checkRemindPossible() {
        let isPossible = false
        if (location?.lastAttendeesReminderDate) {
            const lastRemindDate = dayjs(location.lastAttendeesReminderDate)
            const today = dayjs()
            if (today.diff(lastRemindDate,"hour") >= 24) {isPossible = true}
        } else {
            isPossible = true
        }
        return isPossible
    }

    const isRemindPossible = checkRemindPossible()

    const modalProps = {
        confirmAction: () => {
            setIsLoading(true)
            remindPendingPlayers(location.id).then(
                res => {
                    setCurrentLocation({
                        ...location,
                        ...res,
                    })
                    setIsLoading(false)
                },
            )
        },
        location: location,
        nextPossibleRemindDate: nextPossibleRemindDate,
    }

    function openRemindConfirmation() {
        ModalHandler.show(RemindPopin, modalProps)
    }

    useEffect(() => {
        if (location) {
            ModalHandler.update(RemindPopin, modalProps)
        }
    }, [ location ])

    useEffect(() => {
        if (routerLocation.state?.openInvitationPopin) {
            history.replaceState({}, document.title)
        }
    }, [ location, routerLocation ])

    function openVoteConfirmation(playerId) {
        setIsLoading(true)
        if (MVP?.selectedPlayer && playerId === MVP.selectedPlayer.id) {
            deleteMVPVote(location.id).then(
                res => {
                    setCurrentLocation({
                        ...location,
                        MVP: res,
                    })
                    getLocation(location.id).then(
                        (res) => {
                            setCurrentLocation({
                                ...location,
                                res,
                            })
                            setIsLoading(false)
                        },
                    )
                },
            )
        } else {
            voteForMVP(location.id, playerId).then(
                res => {
                    setCurrentLocation({
                        ...location,
                        MVP: res,
                    })

                    ModalHandler.show(VoteConfirmationPopIn)
                    setIsLoading(false)
                },
            )
        }
    }

    function handleInvitationStateChange(inviteId, value, e) {
        e.stopPropagation()
        invitationChangeState(inviteId, value)
        const inviteStatus = location.invites.find(invite => invite.id === inviteId)?.status
        const addAttendees = value === 1 ? 1 : 0
        const subAttendees = inviteStatus === 1 ? -1 : 0
        const currentAttendees = location.currentAttendees + addAttendees + subAttendees
        const invites = location.invites.map(invite => invite.id === inviteId ? { ...invite, status: value } : invite)
        const presentsInvites = invites?.filter(player => player.status === 1).map(addGoalsToPlayer).sort(goalAlphabeticalPlayerSort)
        const filteredAttendees = presentsInvites.map(player => ({
            ...player,
            teamId: presentPlayers?.find(attendee => player.id === attendee.id)?.teamId ,
            teamSlot: presentPlayers?.find(attendee => player.id === attendee.id)?.teamSlot,
        }))
        const team1 = filteredAttendees.filter(attendee => attendee.teamId === 1)
        const team2 = filteredAttendees.filter(attendee => attendee.teamId === 2)
        const team1Name = team1?.length === 2 ?  `${team1[0].firstname}/${team1[1].firstname}` : APP_CONFIG.padelDefaultTeamsNames.team1
        const team2Name = team2?.length === 2 ?  `${team2[0].firstname}/${team2[1].firstname}` : APP_CONFIG.padelDefaultTeamsNames.team2
        const data = {
            ...location,
            composition: filteredAttendees,
            currentAttendees,
            invites,
        }

        if (theme === "theme-padel") {
            const { notCustomizedTeam1Name, notCustomizedTeam2Name } = mustChangeTeamsNames(location)
            const team1ToChange = notCustomizedTeam1Name ? team1Name : location.team1Name
            const team2ToChange = notCustomizedTeam2Name ? team2Name : location.team2Name
            data.team1Name = team1ToChange
            data.team2Name = team2ToChange
            data.team1CustomName = team1ToChange
            data.team2CustomName = team2ToChange
            registerLocationTeamsNames(location.id, team1ToChange, team2ToChange, themeHeader)
        }

        setCurrentLocation(data)
        setCurrentLocation(data)
        setSelectedPlayerId(null)
    }

    const changeTeam = (team, invite) => {
        setIsLoading(true)
        const team1 = location.team1.filter(player => player.id !== invite.playerId).map(player1 => ({ ...player1, id: player1.userId, playerId: player1.id }))
        const team2 = location.team2.filter(player => player.id !== invite.playerId).map(player1 => ({ ...player1, id: player1.userId, playerId: player1.id }))
        const formation = "1-1-2-1"
        if (team === 1) {team1.push(invite)}
        if (team === 2) {team2.push(invite)}

        const formatTeamPlayers = (teamArray, teamId) => {
            const object = { formation: formation }
            teamArray.forEach((item, i) => {
                object[i] = { ...item, teamId: teamId, teamSlot: i }
            })
            return object
        }

        modifyPlayersComposition(
            location.id, {
                team1: team1.length > 0 ? JSON.stringify(formatTeamPlayers(team1, 1)) : {},
                team2: team2.length > 0 ? JSON.stringify(formatTeamPlayers(team2, 2)) : {},
            }, themeHeader,
        ).then(res => processPlayersCompositionResponse(res, themeHeader, setIsLoading, setCurrentLocation, location))
    }

    const bottomPart = (type, invite) => {
        const disableInvitationStatus = type !== 0 && location.currentAttendees >= location.maxAttendees
        const isInviteInOneTeam = !!(location.team1.find(player => player.id === invite.playerId)
            || location.team2.find(player => player.id === invite.playerId))

        return (
            location.isOwner &&
            (theme === "theme-padel" || userLoaded.contactId !== invite.playerId) &&
            (!invite.supersubResponse || invite.supersubResponse.status !== 1)
        ) && (
            <>
                <div className={classNames("movePlayerTo", {
                    disableActions: disableInvitationStatus,
                    noTeamButtons: type !== 0,
                })}>
                    <ButtonCta
                        isCta3
                        text="Présent"
                        disabled={type === 0 || disableInvitationStatus}
                        onClick={(e) => handleInvitationStateChange(invite.id, 1, e)}
                        className={classNames({ notVisible: userLoaded.contactId === invite.playerId })}
                    />
                    <ButtonCta
                        isCta3
                        text="En attente"
                        disabled={type === 1 || disableInvitationStatus}
                        onClick={(e) => handleInvitationStateChange(invite.id, 0, e)}
                        className={classNames({ notVisible: userLoaded.contactId === invite.playerId })}
                    />
                    <ButtonCta
                        isCta3
                        text="Absent"
                        disabled={type === 2 || disableInvitationStatus}
                        onClick={(e) => handleInvitationStateChange(invite.id, 2, e)}
                        className={classNames({
                            noMarginRight: type !== 0,
                            notVisible: userLoaded.contactId === invite.playerId,
                        })}
                    />

                    {theme === "theme-padel" && type === 0 && (
                        <div className="padelTeamSelection">
                            <div className="buttonsWrapper">
                                <div className={!location?.team1?.find(player => player.id === invite.playerId) && isTeamFull(1) ? "disabled" : "active"}>
                                    <ButtonCta
                                        isCta3Alt
                                        text={!location?.team1?.find(player => player.id === invite.playerId) && isTeamFull(1) ? `${APP_CONFIG.padelDefaultTeamsNames.team1} (complète) ` : APP_CONFIG.padelDefaultTeamsNames.team1}
                                        disabled={!!location?.team1?.find(player => player.id === invite.playerId) || isTeamFull(1)}
                                        onClick={() => {
                                            changeTeam(1, invite)
                                        }}
                                    />
                                </div>
                                <div className={!location?.team2?.find(player => player.id === invite.playerId) && isTeamFull(2) ? "disabled" : "active"}>
                                    <ButtonCta
                                        isCta3Alt
                                        text={!location?.team2?.find(player => player.id === invite.playerId) && isTeamFull(2) ? `${APP_CONFIG.padelDefaultTeamsNames.team2} (complète) ` : APP_CONFIG.padelDefaultTeamsNames.team2}
                                        disabled={!!location?.team2?.find(player => player.id === invite.playerId) || isTeamFull(2)}
                                        onClick={() => {
                                            changeTeam(2, invite)
                                        }}
                                    />
                                </div>
                                <div className={!isInviteInOneTeam && "disabled"}>
                                    <ButtonCta
                                        isCta3Alt
                                        text={"Aucune"}
                                        disabled={!isInviteInOneTeam}
                                        onClick={() => {
                                            changeTeam(null, invite)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {disableInvitationStatus && (
                    <p className="reachMax">Tu ne peux plus ajouter de joueurs car le nombre maximum de participant a été atteint.</p>
                )}
            </>
        )
    }

    const supersubRatingS = location && location.supersubConfirmedPlayers > 1 ? "tes supersubs?" : "ton supersub?"

    const customClsSupersub = (guest) => {
        if ((userLoaded.contactId !== guest.playerId || (userLoaded.contactId === guest.playerId && theme === "theme-padel"))
            && location.isOwner
            && ((guest.playerInvite
                && !guest.playerInvite.supersubResponse)
                || !guest.supersubResponse
            )
        ) {
            return "isClickable"
        }
    }

    const isUserPlayer = player => player.playerId === userLoaded.contactId

    function checkPresentPlayers(data) {
        if (location && !presentPlayers) {
            getPlayersComposition(location.id, { activity: themeHeader }).then(
                (res) => {
                    if (res?.location?.id) {
                        const presentsInvites = res?.location?.invites?.filter(player => player.status === 1)
                        data.composition = presentsInvites.map(player => ({
                            ...player,
                            teamId: res?.attendees?.find(attendee => player.id === attendee.id)?.teamId,
                            teamSlot: res?.attendees?.find(attendee => player.id === attendee.id)?.teamSlot,
                        }))
                    }
                    if (res.goals) {
                        data.goal = res.goals
                    }
                    setCurrentLocation({
                        ...location,
                        data,
                    })
                    setIsLoading(false)
                },
            ).catch(() => setIsLoading(false))
        } else {
            setCurrentLocation({
                ...location,
                data,
            })
            setIsLoading(false)
        }
    }

    useEffect(
        () => {
            let data = location

            if (!data.id) {
                return
            }

            if (location && !MVP) {
                getMVP(location.id).then(
                    res => {
                        data.MVP = res
                        checkPresentPlayers(data)
                    },
                )
            } else {
                checkPresentPlayers(data)
            }
            if (location?.state?.openInvitationPopin) {
                window.history.replaceState({}, document.title)
                show()
            }
        }, [],
    )

    useEffect(() => {
        if (location && Object.keys(location).length && !location.Message) {
            setLinkToShare(generateShareLink(location, theme, publicDetailsGenerated, setPublicDetailsGenerated))
        }
    }, [ location ])

    const isTeamFull = (team) => {
        return presentPlayers?.filter(player => player.teamId === team).length >= teamDisplay.length
    }

    const { show } = ModalHandler.useReactiveModal(BookingInvitation, {
        location: location,
    })

    return (
        <div className="c-row justify-center locationPlayerManagement">
            {isLoading  && <Preloader fixed /> }
            {location && (
                <div className="c-col c-col--12">
                    {location?.isOwner && isMatchPlayed && location.supersubConfirmedPlayers > 0 && theme === "theme-soccer" && (
                        <div className="played-match">
                            <div className="played-match-title">
                                <img src="/assets/images/supersub--white.svg" />
                                <p>
                                    Qu'as-tu pensé de<br />
                                    {supersubRatingS}
                                </p>
                            </div>
                            <ButtonCta
                                text={location.supersubConfirmedPlayers > 1 ? "NOTER LES SUPERSUBS" : "NOTER LE SUPERSUB"}
                                isAlt
                                onClick={() => {
                                    navigate(path("/reservations/locations/" + location.id + "/supersubRating"))
                                }}
                            />
                        </div>
                    )}
                    <div className="player-list">
                        { noPlayer ? (
                            <div className="noPlayers">
                                <img className="noPlayerImg" src={mediaPath([ "/assets/images/picto-effectif.svg","/assets/images/padel/icons/icons-red-coequipiers.svg" ])}/>
                                <p>Invite tes joueurs à rejoindre ton match.</p>
                                <ButtonCta
                                    text="Inviter mes joueurs"
                                    onClick={show}
                                    isAlt
                                    icon={mediaPath([ "/assets/images/picto-add-user--orange.svg", "/assets/images/padel/icons/icons-player-plus.svg" ])}
                                    hoverChangeIcon={mediaPath([ "/assets/images/picto-add-user--white.svg", "/assets/images/padel/icons/icons-player-plus-green.svg" ])}
                                />
                                <ShareLink icon="" label="partager le lien du match" shareLink={linkToShare} />
                            </div>
                        ) : (
                            <>
                                <div className={location.isOwner && !isMatchPlayed ? "present owner" : "present"}>
                                    {presents.length > 0 && (
                                        <>
                                            <h2>
                                                <span>
                                                        Présent{presents.length > 1 ? "s" : ""} ({presents.length})
                                                </span>
                                            </h2>
                                            { location && [ 0,1,8,10,12,14,15 ].includes(location.resourceType) && presentPlayers && isMatchPlayed ? (
                                                <>
                                                    {presentsTeam1.length > 0 && (
                                                        <>
                                                            <h3>{location.team1Name}</h3>
                                                            {presentsTeam1.map(
                                                                (guest) => (
                                                                    <PlayerLine
                                                                        customCls={customClsSupersub(guest)}
                                                                        isPlayerMVP={location.mvp && guest.playerId === location.mvp.id}
                                                                        userMVPVote={(MVP?.selectedPlayer) ? guest.playerId === MVP.selectedPlayer.id : false}
                                                                        isMVPVoteOpened={location.isMVPVoteOpened}
                                                                        isMatchPlayed={isMatchPlayed}
                                                                        playerScored={playerScored(guest)}
                                                                        isUserPlayer={isUserPlayer(guest)}
                                                                        isSelected={guest.playerId === selectedPlayerId}
                                                                        selectPlayer={setSelectedPlayerId}
                                                                        isOwner={location.isOwner}
                                                                        invite={guest}
                                                                        bottomPart={bottomPart(0, guest)}
                                                                        key={location.id + "-" + guest.id}
                                                                        onClick={openVoteConfirmation}
                                                                        clickable={theme === "theme-padel"}
                                                                    />
                                                                ),
                                                            )}
                                                        </>
                                                    )}
                                                    {presentsTeam2.length > 0 && (
                                                        <>
                                                            <h3>{location.team2Name}</h3>
                                                            {presentsTeam2.map(
                                                                (guest) => (
                                                                    <PlayerLine
                                                                        customCls={customClsSupersub(guest)}
                                                                        isPlayerMVP={location.mvp && guest.playerId === location.mvp.id}
                                                                        userMVPVote={(MVP?.selectedPlayer) ? guest.playerId === MVP.selectedPlayer.id : false}
                                                                        isMVPVoteOpened={location.isMVPVoteOpened}
                                                                        isMatchPlayed={isMatchPlayed}
                                                                        playerScored={playerScored(guest)}
                                                                        isUserPlayer={isUserPlayer(guest)}
                                                                        isSelected={guest.playerId === selectedPlayerId}
                                                                        selectPlayer={setSelectedPlayerId}
                                                                        isOwner={location.isOwner}
                                                                        invite={guest}
                                                                        bottomPart={bottomPart(0, guest)}
                                                                        key={location.id + "-" + guest.id}
                                                                        onClick={openVoteConfirmation}
                                                                        clickable={theme === "theme-padel"}
                                                                    />
                                                                ),
                                                            )}
                                                        </>
                                                    )}
                                                    {presentsNoTeam.length > 0 && (
                                                        <>
                                                            <h3>Sans équipe</h3>
                                                            {presentsNoTeam.map(
                                                                (guest) => (
                                                                    <PlayerLine
                                                                        customCls={customClsSupersub(guest)}
                                                                        isPlayerMVP={location.mvp && guest.playerId === location.mvp.id}
                                                                        userMVPVote={(MVP?.selectedPlayer) ? guest.playerId === MVP.selectedPlayer.id : false}
                                                                        isMVPVoteOpened={location.isMVPVoteOpened}
                                                                        isMatchPlayed={isMatchPlayed}
                                                                        playerScored={playerScored(guest)}
                                                                        isUserPlayer={isUserPlayer(guest)}
                                                                        isSelected={guest.playerId === selectedPlayerId}
                                                                        selectPlayer={setSelectedPlayerId}
                                                                        isOwner={location.isOwner}
                                                                        invite={guest}
                                                                        bottomPart={bottomPart(0, guest)}
                                                                        key={location.id + "-" + guest.id}
                                                                        onClick={openVoteConfirmation}
                                                                        clickable={theme === "theme-padel"}
                                                                    />
                                                                ),
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ) : presents.map(
                                                (guest) => {
                                                    return (
                                                        <PlayerLine
                                                            customCls={customClsSupersub(guest)}
                                                            isPlayerMVP={location.mvp && guest.playerId === location.mvp.id}
                                                            userMVPVote={(MVP?.selectedPlayer) ? guest.playerId === MVP.selectedPlayer.id : false}
                                                            isMVPVoteOpened={location.isMVPVoteOpened}
                                                            isMatchPlayed={isMatchPlayed}
                                                            playerScored={playerScored(guest)}
                                                            isUserPlayer={isUserPlayer(guest)}
                                                            isSelected={guest.playerId === selectedPlayerId}
                                                            selectPlayer={setSelectedPlayerId}
                                                            isOwner={location.isOwner}
                                                            invite={guest}
                                                            bottomPart={bottomPart(0, guest)}
                                                            key={location.id + "-" + guest.id}
                                                            onClick={openVoteConfirmation}
                                                            clickable={theme === "theme-padel"}
                                                            displayTeam={theme === "theme-padel" && ((guest.teamId === 1 && location?.team1Name) || (guest.teamId === 2 && location?.team2Name))}
                                                        />
                                                    )
                                                },
                                            )
                                            }
                                        </>
                                    )}
                                </div>
                                <div className={location.isOwner && !isMatchPlayed ? "standby owner" : "standby"}>
                                    {attente.length > 0 && (
                                        <>
                                            <h2>
                                                <span>
                                                        En attente ({attente.length})
                                                </span>
                                                {(
                                                    attente.length > 0 && !isMatchPlayed &&
                                                            (
                                                                location.canRemindAttendees
                                                                || (!location.canRemindAttendees && location.lastAttendeesReminderDate)
                                                            )
                                                ) && (
                                                    <span
                                                        className="clickable"
                                                        onClick={openRemindConfirmation}
                                                    >
                                                        <img
                                                            src={
                                                                isRemindPossible ?
                                                                    mediaPath([ "/assets/icons/notification--orange.svg", "/assets/images/padel/icons/icons-bell.svg" ])
                                                                    :
                                                                    mediaPath([ "/assets/icons/notification--grey.svg", "/assets/images/padel/icons/icons-bell-disable.svg" ])
                                                            }
                                                        />
                                                    </span>
                                                )}
                                            </h2>

                                            {attente.map(
                                                (guest) => (
                                                    <PlayerLine
                                                        customCls={location.isOwner ? "isClickable" : false}
                                                        isMatchPlayed={isMatchPlayed}
                                                        isOwner={location.isOwner}
                                                        invite={guest}
                                                        bottomPart={bottomPart(1, guest)}
                                                        key={location.id + "-" + guest.id}
                                                        isSelected={guest.playerId === selectedPlayerId}
                                                        selectPlayer={setSelectedPlayerId}
                                                    />
                                                ),
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className={location.isOwner && ! isMatchPlayed ? "absent owner" : "absent"}>
                                    {absents.length > 0 && (
                                        <>
                                            <h2>Absent{absents.length > 1 ? "s" : ""} ({absents.length})</h2>
                                            {absents.map(
                                                (guest) => (
                                                    <PlayerLine
                                                        customCls={location.isOwner ? "isClickable" : false}
                                                        isMatchPlayed={isMatchPlayed}
                                                        isOwner={location.isOwner}
                                                        invite={guest}
                                                        bottomPart={bottomPart(2, guest)}
                                                        key={location.id + "-" + guest.id}
                                                        isSelected={guest.playerId === selectedPlayerId}
                                                        selectPlayer={setSelectedPlayerId}
                                                    />
                                                ),
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
            { !isLoading && location && (
                <div className="c-col c-col--8 c-col--sm--12">
                    <div className="l-vertical center">
                        {!noPlayer && (
                            <div className="button-holder">
                                <ShareLink icon="" label="partager le lien du match" shareLink={linkToShare} customCls="inLine minWidth" />
                                <ButtonCta
                                    className="manage-player-button minWidth"
                                    text="Inviter mes joueurs"
                                    isAlt
                                    longer
                                    onClick={show}
                                    icon={mediaPath([ "/assets/images/picto-add-user--orange.svg", "/assets/images/padel/icons/icons-player-plus.svg" ])}
                                    hoverChangeIcon={mediaPath([ "/assets/images/picto-add-user--white.svg", "/assets/images/padel/icons/icons-player-plus-green.svg" ])}
                                />
                            </div>
                        )}
                        {location.isOwner && (<SuperSubLocationRecap
                            location={location}
                        />)}
                    </div>
                </div>
            )}
        </div>
    )
}

export const VoteConfirmationPopIn = ModalHandler.create(({ visible, hide }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className="voteConfirmation"
        >
            <header> ÉLIRE LE JOUEUR DU MATCH </header>
            <section className="vote-confirmation">
                <p>Ton vote a bien été pris en compte.</p>
                <ButtonCta
                    text="continuer"
                    onClick={hide}
                />
            </section>
        </Modal>
    )
})

export default LocationPlayersManagement
