import { motion } from "framer-motion"
import { useEffect, useState } from "react"

import LastMatch from "../../../components/league/LastMatch/LastMatch.jsx"
import { NewsFeed } from "../../../components/league/NewsFeed/NewsFeed.jsx"
import NextMatch from "../../../components/league/NextMatch/NextMatch.jsx"
import RankingSummary from "../../../components/league/RankingSummary/RankingSummary.jsx"
import { VideoSlider } from "../../../components/league/Videos/VideoSlider.jsx"
import Preloader from "../../../components/loaders/preloader/preloader.jsx"
import { getLeagueTeamById } from "../api/myLeague.js"
import { hasTeamChanged, prepareVideoData } from "../service/service.js"
import { AD_MY_LEAGUE, useLeagueStore } from "../store/useLeagueStore.js"

import "./LeagueGeneral.scss"

export const LeagueGeneral = () => {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ videos, setVideos ] = useState([])

    const {
        selectedTeamData,
        setSelectedTeamData,
        selectedLeagueTeam,
        ads,
        setAds,
    } = useLeagueStore()

    const ad = ads?.find(ad => ad.title === AD_MY_LEAGUE)

    useEffect(() => {
        if (
            selectedLeagueTeam?.id
        ) {
            fetchLeagueTeam()
        }
    }, [ selectedLeagueTeam?.id ])

    useEffect(() => {
        if (selectedTeamData?.id && !!selectedTeamData?.gameSummary?.lastGame?.videos) {
            setVideos((prepareVideoData(selectedTeamData?.gameSummary?.lastGame, 5, (teamName, score) => (
                <>
                    <h3>VS</h3>
                    <p>{teamName}</p>
                    <h3 className={"score"}>{score}</h3>
                </>
            ))))
        }
    }, [ selectedTeamData ])

    const fetchLeagueTeam = async () => {
        if (
            selectedLeagueTeam?.id
            && hasTeamChanged(selectedLeagueTeam.leagueId, selectedLeagueTeam.id, selectedTeamData)
            || !selectedTeamData?.gameSummary
        ) {
            setIsLoading(true)
            const team = await getLeagueTeamById(selectedLeagueTeam?.id)
            setSelectedTeamData(team)
            setIsLoading(false)
        }
    }

    const { nextGame, lastGame } = selectedTeamData?.gameSummary || {}

    const noCalendar = !nextGame && !lastGame
    const isFirstGame = nextGame && !lastGame

    return isLoading || !selectedTeamData?.gameSummary ? <Preloader fixed/> : (
        <div className={"league-general"}>
            <motion.div
                custom={0} variants={containerVariants} initial="hidden" className="block1" animate="visible"
            >
                <NextMatch match={nextGame} noCalendar={noCalendar} firstGame={isFirstGame}/>
            </motion.div>

            {(lastGame || noCalendar) && (
                <motion.div
                    custom={1} variants={containerVariants} initial="hidden" animate="visible"
                    className="block2"
                >
                    <LastMatch match={lastGame} noCalendar={noCalendar} team={selectedLeagueTeam}/>
                </motion.div>
            )}

            <motion.div
                custom={2} variants={containerVariants} initial="hidden" className="block3" animate="visible"
            >
                <RankingSummary data={selectedTeamData}/>
            </motion.div>

            {ad?.image && (
                <motion.div custom={3} variants={containerVariants} initial="hidden" animate="visible"
                    className="block4"
                >
                    <a href={ad.url} target={ad.newTab ? "_blank" : "_self"} rel="noreferrer">
                        <img src={ad.image} alt={ad?.alt ?? "pub"} onError={() => {
                            setAds(ads?.filter(a => a.title !== AD_MY_LEAGUE))
                        }}/>
                    </a>
                </motion.div>
            )}

            {!!selectedTeamData?.gameSummary?.lastGame?.videos?.length && (
                <motion.div custom={5} variants={containerVariants} initial="hidden" animate="visible"
                    className="block5"
                >
                    <VideoSlider gameVideos={videos} seeMoreMatch={{
                        gameId: selectedTeamData?.gameSummary?.lastGame?.id,
                        id: selectedTeamData?.id,
                    }}/>
                </motion.div>
            )}

            <motion.div custom={4} variants={containerVariants} initial="hidden" animate="visible"
                className="block6"
            >
                <NewsFeed/>
            </motion.div>
        </div>
    )
}

const containerVariants = {
    hidden: {
        opacity: 0,
        scale: 0.8,
    },
    visible: i => ({
        opacity: 1,
        scale: 1,
        transition: {
            delay: i * 0.12,
        },
    }),
}
