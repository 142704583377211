import classNames from "classnames"
import PropTypes from "prop-types"

import usePlural from "../../../hooks/usePlural.js"
import ProgressCircle from "../ProgressCircle/ProgressCircle"

import "./StatsSummary.scss"
import "../RankingSummary/RankingSummary.scss"

export default function StatsSummary({ data, layout }) {
    const gamesSeries = [ ...data?.gamesSeries || [] ]

    while (gamesSeries.length < 5) {
        gamesSeries.push(null)
    }

    return (
        <div className="ranking-summary stats-summary">
            <div className={"stats-heading"}>
                <h2 className="ranking">{data.played}</h2>
                <span>{usePlural(data.played, "match joué", "matchs joués")}</span>
            </div>
            <div className="stats">
                <ProgressCircle color="main" fill={data.wins / data.played} label={{ number: data.wins, text: usePlural(data.wins, "victoire", "victoires") }}/>
                <ProgressCircle color="secondary" fill={data.draws / data.played} label={{ number: data.draws, text: usePlural(data.draws, "nul", "nuls") }}/>
                <ProgressCircle color="tertiary" fill={data.losses / data.played} label={{ number: data.losses, text: usePlural(data.losses, "défaite", "défaites") }}/>
            </div>
            <div className={"goals-stats"}>
                <div>
                    <h2 className="ranking">{data?.goals?.scored}</h2>
                    <span>{usePlural(data?.goals?.scored, "but marqué", "buts marqués")}</span>
                </div>
                {layout === "team" && (
                    <div>
                        <h2 className="ranking">{data?.goals?.conceded}</h2>
                        <span>{usePlural(data?.goals?.conceded, "but encaissé", "buts encaissés")}</span>
                    </div>
                )}
            </div>
            <div className={"goals-repartition"}>
                <div
                    className="progress"
                    style={{ width: `${(data?.goals.scored / (data?.goals.scored + data?.goals.conceded)) * 100}%` }}
                >
                    <div className="progress-bar">
                        <span className={classNames("has-bg", { [layout]: layout })}>{data?.goals.scored}</span>
                    </div>
                    {layout === "team" ? (
                        <img src={"/assets/images/league/ornaments/goal-repartition-bar.svg"} alt={"bar"}/>
                    ) : (
                        <img src={"/assets/images/league/ornaments/goal-repartition-arrow.svg"} alt={"arrow"}/>
                    )}
                </div>
                {layout === "team" && (
                    <span>{data?.goals.conceded}</span>
                )}
            </div>
            {layout === "player" && (
                <span className={"teamGoalsCount"}>
                Total équipe = {data?.goals.team} {usePlural(data?.goals?.team, "but", "buts")}
                </span>
            )}

            {!!data?.gamesSeries && !!data?.gamesSeries?.[0] && (
                <div className={"games-serie"}>

                    <h3>Série en cours</h3>
                    <div>
                        {gamesSeries?.map((game, index) => {
                            if (game === GAME_RESULT.LOSE) {
                                return <span key={index} className={"lose"}>D</span>
                            } else if (game === GAME_RESULT.DRAW) {
                                return <span key={index} className={"draw"}>N</span>
                            } else if (game === GAME_RESULT.WIN) {
                                return <span key={index} className={"win"}>V</span>
                            } else {
                                return <span key={index} className={"empty"}>&nbsp;</span>
                            }
                        })}
                    </div>
                    <div className="helper">
                        <span>DERNIER MATCH</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export const GAME_RESULT = {
    DRAW: "N",
    LOSE: "P",
    WIN: "G",
}

StatsSummary.defaultProps = {
    layout: "team",
}

StatsSummary.propTypes = {
    data: PropTypes.object.isRequired,
    layout: PropTypes.oneOf([ "team", "player" ]),
}
