import { useEffect, useState } from "react"

import { useTheme } from "../components/ThemeContext/ThemeContext"

function useThemeHeader() {
    const theme = useTheme()
    const [ themeHeader, setThemeHeader ] = useState(theme === "theme-soccer" ? 1 : 2)

    useEffect(() => {
        setThemeHeader(theme === "theme-soccer" ? 1 : 2)
    }, [ theme ])

    return themeHeader
}

export default useThemeHeader
