import classNames from "classnames"
import { object } from "prop-types"

import usePlural from "../../../hooks/usePlural"
import { TooltipLight } from "../../tooltip/tooltipLight/tooltipLight.jsx"

import "./PlayerCard.scss"

export default function PlayerCard({ player }) {
    return (
        <div className="leaguePlayerCard">
            <div className="playerInfo">
                <div className="avatar">
                    <img src={player?.pictureUrl ?? "/assets/images/user-placeholder-b.svg"} className={classNames("picture", {
                        "no-picture": !player?.pictureUrl,
                    })} alt="player avatar"/>
                </div>
                <div className="playerStats">
                    <div className={"playerStats--heading"}>
                        <div>
                            {player?.firstname && <div className="firstname">{player.firstname}</div>}
                            {player?.lastname && <div className="lastname">{player.lastname}</div>}
                        </div>
                        {(player?.isCaptain || player?.isViceCaptain) && (
                            <TooltipLight text={player?.isCaptain ? "Capitaine" : "Vice-capitaine"} dark>
                                <div className={"teamPlayerList__status"}>
                                    <img src={
                                        player?.isCaptain ? "/assets/icons/ICON-capitaine.svg" : "/assets/icons/ICON-vice-capitaine.svg"
                                    } alt={"capitaine"}/>
                                </div>
                            </TooltipLight>
                        )}
                    </div>
                    {(player?.nbGamePlayed > 0 || player?.nbGoals > 0 || player?.nbMvp > 0) && (
                        <div className="stats">
                            {player?.gamesPlayed > 0 && (
                                <div className="line">
                                    <div className="wrapper">
                                        <img src="/assets/icons/fieldOrange.svg" />
                                    </div>
                                    <span>{player.nbGamePlayed}&nbsp;</span>
                                    {usePlural(player.nbGamePlayed, "Match joué", "Matchs joués")}
                                </div>
                            )}
                            {player?.nbGoals > 0 && (
                                <div className="line">
                                    <div className="wrapper">
                                        <img src="/assets/icons/ballOrange.svg" />
                                    </div>
                                    <span>{player.nbGoals}&nbsp;</span>
                                    {usePlural(player.nbGoals, "But marqué", "Buts marqués")}
                                </div>
                            )}
                            {player?.nbMvp > 0 && (
                                <div className="line">
                                    <div className="wrapper">
                                        <img src="/assets/icons/star--orange.svg" />
                                    </div>
                                    <span>{player.nbMvp}X&nbsp;</span>
                                    Homme du match
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <img className="separator" src="/assets/images/league/ornaments/thunderSeparator.svg" alt="separator" />
            <div className="teamLeague">
                <div>League <span>{player?.leagueName}</span></div>
                <div>Team <span className="teamName">{player?.teamName}</span></div>
            </div>
        </div>
    )
}

PlayerCard.propTypes = {
    player: object,
}
