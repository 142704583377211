import dayjs from "dayjs"
import { bool, func, object, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import Button from "../../components/Buttons/button/button"
import ButtonCta from "../../components/Buttons/button/button-cta"
import Preloader from "../../components/loaders/preloader/preloader"
import Modal from "../../components/Modal/Modal"
import {
    acceptLocationPrivateInvit,
    createFriendshipInvitationFromEmail,
    handleFriendshipToken,
    handleLocationInvitation,
} from "../../globalAPI/api"
import useMediaPath from "../../hooks/useMediaPath"
import useThemeFeatures from "../../hooks/useThemeFeatures"
import useThemeHeader from "../../hooks/useThemeHeader"
import ModalHandler from "../../providers/Modal/ModalHandler.jsx"
import usePath from "../../routes/services/usePath"
import truncateToTwoDecimals from "../../services/truncateToTwoDecimals"
import { loadUrlQueryParameters, toggleGlobalLoader } from "../../store/actions/scaffold"

import "./ConfirmationPopinContent.scss"

const ConfirmationPopinContent = ModalHandler.create(({ type, withLeagueInvitation, locationDetails, afterClose, customAccept, publicInvit, visible, hide })=> {
    const [ message, setMessage ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const [ content, setContent ] = useState("")
    const [ formattedDate, setFormattedDate ] = useState("")
    const [ formattedHour, setFormattedHour ] = useState("")
    const [ formattedDuration, setFormattedDuration ] = useState("")

    const themeHeader = useThemeHeader()
    const mediaPath = useMediaPath()
    const themeFeatures = useThemeFeatures()
    const navigate = useNavigate()
    const path = usePath()

    const [ queryParameters ] = useSearchParams()

    const params = {
        PJT: queryParameters?.get("PJT"),
        email: queryParameters?.get("email"),
        fromUser: queryParameters?.get("fromUser"),
        linkKey: queryParameters?.get("linkKey"),
        token: queryParameters?.get("token"),

    }

    const dispatch = useDispatch()
    const correspondingPlayers = locationDetails?.maxAttendees
    const attendeesCountCorrection = locationDetails?.attendeesCount === 0 ? 1 : locationDetails?.attendeesCount
    const places = locationDetails?.maxAttendees - attendeesCountCorrection >= 0 ?
        locationDetails?.maxAttendees - attendeesCountCorrection : 0

    const confirmAction = () => {
        setLoading(true)
        let token
        switch (type) {
        case "privateInvit":
            if (params.token) {
                token = params.token
            }
            setLoading(true)
            acceptLocationPrivateInvit(token).then(res =>{
                setLoading(false)
                if (res?.Message) {
                    setMessage(res.Message)
                } else {
                    setMessage("Tu as accepté l'invitation")
                }
            })
            break
        case "friendship":
            if (params.linkKey) {
                token = params.linkKey // for cup & league, to avoid sanitized errors from facebook invitation
            }

            handleFriendshipToken(token, withLeagueInvitation).then((result) => {
                setMessage(result.data.data[0])
            }).finally(() => {
                hide()
                setLoading(false)
            })
            break
        case "location":
            if (params.email) {
                createFriendshipInvitationFromEmail(decodeURIComponent(params.email), import.meta.env.VITE_URL_BASE, null, themeHeader)
            }
            handleLocationInvitation(decodeURIComponent(params.PJT)).then((result) => {
                if (result.data.data.Message) {
                    setMessage(result.data.data.Message ?? "Impossible de rejoindre cette réservation.")
                } else {
                    navigate(path("/confirmcommand"), {
                        state: {
                            locationDetails: locationDetails,
                            message: "acceptBookingInvitation",
                            subtitle: "",
                            success: true,
                            title: "Tu as rejoint le match !",
                            token: params.PJT,
                            tunnelType: "BOOKING",
                        },
                    })
                    hide()
                }
                setLoading(false)
            })
            break
        }
    }

    useEffect(
        () => {
            switch (type) {
            case "privateInvit":
                setContent(
                    <>
                        <header>Invitation privée</header>
                        <section>
                            <p>
                                    Accepter l'invitation ?
                            </p>
                            <div className="message">{message}</div>
                            <div className="buttonsWrapper">
                                <Button
                                    color="grey"
                                    text={loading ? <Preloader small alt/> : "Oui"}
                                    onClick={customAccept ?? confirmAction}
                                    disabled={message !== ""}
                                />
                                <Button
                                    color="grey"
                                    text={message ? "fermer" : "non"}
                                    onClick={hide}
                                />
                            </div>
                        </section>
                    </>,
                )
                break
            case "friendship":
                setContent(
                    <>
                        {loading && <Preloader fixed/>}
                        <header> Invitation de {decodeURI(params.fromUser)}</header>
                        <section>
                            <p>
                                    Accepter l'invitation à devenir coéquipier?
                            </p>
                            <div className="message">{message}</div>
                            <div className="buttonsWrapper">
                                <Button
                                    color="grey"
                                    text="Oui"
                                    onClick={customAccept ?? confirmAction}
                                    disabled={message !== ""}
                                />
                                <Button
                                    color="grey"
                                    text={message ? "fermer" : "non"}
                                    onClick={hide}
                                />
                            </div>
                        </section>
                    </>,
                )
                break
            case "location":
                setContent(
                    <>
                        {loading && <Preloader fixed />}
                        <header>
                            <img src={mediaPath([ "/assets/images/soccer.svg", "/assets/images/padel/logo/padel-white.svg" ])}/>
                            <p>{locationDetails.organizer} t'invite pour un {themeFeatures([ "Foot", "Padel" ])}</p>
                            <p className={"remainingPlaces"}>{places === 0 ? "Match complet" : `${places} ${places > 1 ? "places restantes" : "place restante"}`}</p>
                        </header>
                        <section>
                            <div className="locationDetails">
                                <div className="info">
                                    <img src={mediaPath([ "/assets/icons/calendar-orange.svg", "/assets/images/padel/icons/calendar-red.svg" ])} />
                                    <span>{formattedDate}</span>
                                </div>
                                <div className="info">
                                    <img src={mediaPath([ "/assets/icons/heure-orange.svg", "/assets/images/padel/icons/heure-red.svg" ])} />
                                    <span>{formattedHour}</span>
                                </div>
                                <div className="info">
                                    <img src={mediaPath([ "  /assets/icons/center-orange.svg", "/assets/images/padel/icons/center-red.svg" ])} />
                                    <span>{locationDetails.center}</span>
                                </div>
                                <div className="info">
                                    <img src={mediaPath([ "/assets/icons/chrono-orange.svg", "/assets/images/padel/icons/chrono-red.svg" ])} />
                                    <span>{formattedDuration}</span>
                                </div>
                                <div className="info">
                                    <img src={mediaPath([ "/assets/icons/euroSymbol.svg", "/assets/images/padel/icons/euroSymbol-red.svg" ])} />
                                    <span>
                                        {locationDetails.pricePerPart ? locationDetails.pricePerPart : correspondingPlayers ?
                                            locationDetails.price % correspondingPlayers === 0 ?
                                                locationDetails.price / correspondingPlayers
                                                :
                                                truncateToTwoDecimals(locationDetails.price / correspondingPlayers)
                                            :
                                            locationDetails.price
                                        }&euro; {locationDetails.pricePerPart || correspondingPlayers ? "par joueur" : ""}
                                    </span>

                                </div>
                                <div className="info">
                                    <img src={mediaPath([ "/assets/icons/terrain-orange.svg", "/assets/images/padel/icons/terrain-red.svg" ])} />
                                    <span>{locationDetails.field}</span>
                                </div>
                            </div>
                            {places > 0 && (
                                <>
                                    <p className={"invitationMessage"}>Souhaites-tu participer à ce match ? {publicInvit && "Tu devras te connecter pour confirmer ta participation."}</p>
                                    {message && <div className="message"><p>{message}</p></div>}
                                    <div className="buttonsWrapper">
                                        <ButtonCta
                                            isCta2Alt
                                            text={message ? "fermer" : "non"}
                                            onClick={hide}
                                        />
                                        <ButtonCta
                                            text={publicInvit ? "Oui (se connecter)" : "Oui"}
                                            onClick={customAccept ?? confirmAction}
                                            disabled={message !== ""}
                                            className={publicInvit ? "autoWidth" : ""}
                                        />
                                    </div>
                                </>
                            )}
                        </section>
                    </>,
                )
                break
            }
        }, [ type, message, loading, locationDetails, formattedDate ],
    )

    useEffect(() => {
        if (locationDetails?.date) {
            const dateElements = locationDetails.date.split("/")
            setFormattedDate(dayjs(`${dateElements[2]}-${dateElements[1]}-${dateElements[0]}`).format("dddd D MMMM YYYY"))
        }
        if (locationDetails?.hour) {
            setFormattedHour(locationDetails.hour.replace(":", "h"))
        }
        if (locationDetails?.duration) {
            setFormattedDuration(locationDetails.duration.replace(/\s*heures?\s*/g, "h"))
        }
        return () => {
            dispatch(toggleGlobalLoader(false))
            dispatch(loadUrlQueryParameters(null))
        }
    }, [ locationDetails ])

    return (
        <Modal
            beforeClose={
                () => {
                    dispatch(toggleGlobalLoader(false))
                    dispatch(loadUrlQueryParameters(null))
                }
            }
            className="big confirmationPopin"
            isOpen={visible}
            onClose={() => {
                hide()
                afterClose()
            }}
        >
            {content}
        </Modal>
    )
})

ConfirmationPopinContent.propTypes = {
    afterClose: func,
    customAccept: oneOfType([ bool, func ]),
    isPopInOpen: bool,
    locationDetails: object,
    publicInvit: bool,
    setIsPopInOpen: func,
    type: string,
    withLeagueInvitation: bool,
}
ConfirmationPopinContent.displayName = "ConfirmationPopinContent"

export default ConfirmationPopinContent
