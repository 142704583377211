import classNames from "classnames"
import { useLocation, useNavigate } from "react-router-dom"

import { useLeagueStore } from "../../../../pages/league/store/useLeagueStore.js"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import { LeagueModalBase } from "../../LeagueModalBase/LeagueModalBase.jsx"
import { TeamPicture } from "../../TeamPicture/TeamPicture.jsx"

import "./TeamSwitcherModal.scss"

export const TeamSwitcherModal = ModalHandler.create(({ visible, hide }) => {
    const {
        leagueTeams,
        setSelectedLeagueTeam,
        selectedLeagueTeam,
        setSelectedTeamData,
    } = useLeagueStore()

    const navigate = useNavigate()
    const location = useLocation()

    const handleTeamChange = (selectedTeam) => {
        if (selectedTeam.id === selectedLeagueTeam?.id) {
            hide()
            return
        }
        setSelectedLeagueTeam(selectedTeam)
        setSelectedTeamData({
            id: selectedTeam.id,
            leagueId: selectedTeam.leagueId,
        })
        changeLeagueId(selectedTeam.id)
        hide()
    }

    const changeLeagueId = (teamId) => {
        const pathSegments = location.pathname.split("/")

        if (pathSegments.length > 3) {
            pathSegments[3] = teamId
        }
        // Join the segments back and navigate
        const newPath = pathSegments.join("/")
        navigate(newPath, { replace: true })
    }

    return (
        <LeagueModalBase
            onClose={hide}
            id={"team-switcher-modal"}
            visible={visible}
            className={"team-switcher-modal"}
            icon={"/assets/icons/league/team-swap-hover.svg"}
            title={<>Sélectionne <br/><strong>— ton équipe ?</strong></>}
            identifier={"team-switcher-modal"}
        >
            {leagueTeams?.map((team) => (
                <div
                    key={team.id}
                    data-testid="leagueTeam"
                    className={classNames("content-team", { "selected": team.id === selectedLeagueTeam?.id })}
                    onClick={() => handleTeamChange(team)}
                >
                    <TeamPicture image={team?.blazon} size={56}/>
                    <div className={"team-switcher-modal-team-content"}>
                        <p>{team.name}</p>
                        <span>{team.leagueName}</span>
                    </div>
                </div>
            ))}
        </LeagueModalBase>
    )
})
