import { array, arrayOf, bool, func, node, oneOfType, string } from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import useCookies from "../../../hooks/useCookies"
import resetScroll from "../../../services/resetScroll"
import { toggleShowFooter } from "../../../store/actions/scaffold"
import { goPrevAuthStep } from "../../../store/auth/actions/actions"
import AuthBackgroundContent from "../authBackgroundContent/authBackgroundContent"

import "./authStepper.scss"

function AuthStepper({ title, children, prevAction, contentCls, rootClasses, help, hideGoBackStepIdArray }) {

    const [ updatedSteps, setUpdatedSteps ] = useState("")
    const [ blockTouchEnd, setBlockTouchEnd ] = useState(false)

    const currentAuthStepId = useSelector(state => state.auth.currentAuthStepId)
    const showFooter = useSelector(state => state.showFooter)

    const dispatch = useDispatch()
    const { isAppV2 } = useCookies()
    
    useEffect(
        () => {
            const arr = []
            if (children.length) {
                children.forEach(
                    (element, index) => {
                        arr.push(
                            React.cloneElement(
                                element,
                                {
                                    key: "authStepper" + index,
                                    stepNumber: index + 1,
                                },
                            ),
                        )
                    },
                )
            } else {
                arr.push(
                    React.cloneElement(
                        children,
                        {
                            key: "authStepper-1",
                            stepNumber: 1,
                        },
                    ),
                )
            }
            setUpdatedSteps(arr)
        }, [ children ],
    )

    useEffect(() => {
        resetScroll()
    }, [ currentAuthStepId ])
    
    return (
        <div
            className="auth-stepper"
            onTouchStart={(e) => {
                const footer = document.getElementById("footer")
                if (footer?.contains(e.target)) {return}
                if (showFooter) {dispatch(toggleShowFooter(false))}
                else {setBlockTouchEnd(true)}
            }}
            onTouchEnd={() => {
                if (blockTouchEnd) {return setBlockTouchEnd(false)}
                if (document.activeElement instanceof HTMLInputElement) {return}
                dispatch(toggleShowFooter(true))
            }}
        >
            <AuthBackgroundContent
                cls={rootClasses}
                title={title}
                contentCls={contentCls}
                goBack={
                    () => { 
                        currentAuthStepId === 1 ?
                            prevAction()
                            :
                            dispatch(goPrevAuthStep()) 

                    }
                }
                help={help}
                hideGoBack={
                    (hideGoBackStepIdArray && currentAuthStepId && hideGoBackStepIdArray.find((id) => id === currentAuthStepId)) || isAppV2
                }
            >
                {updatedSteps}
            </AuthBackgroundContent>
        </div>
    )

}

AuthStepper.propTypes = {
    children: oneOfType([ string, node, arrayOf(node) ]),
    contentCls: string,
    help: oneOfType([ string, node, arrayOf(node) ]),
    hideGoBackStepIdArray: oneOfType([ bool, array ]),
    prevAction: func,
    rootClasses: string,
    title: string,
}

export default AuthStepper
