import PropTypes from "prop-types"
import { useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import { VideoCard } from "./VideoCard.jsx"
import { leagueVideoGroupType } from "../../../model/types/league.types.js"
import { LeaguePath } from "../../../routes/ApplicationPaths/LeaguePaths.js"
import ButtonCta from "../../Buttons/button/button-cta.jsx"
import "swiper/css"
import "swiper/css/navigation"

import "./VideoSlider.scss"

export const VideoSlider = ({ gameVideos, seeMoreMatch }) => {
    const navigate = useNavigate()
    const prevRef = useRef(null)
    const nextRef = useRef(null)

    return (
        <div className="video-slider">
            <h2>les vidéos</h2>
            <div className={"videos"}>

                <Swiper
                    updateOnWindowResize={true}
                    modules={[ Navigation ]}
                    slidesPerView={"auto"}
                    spaceBetween={20}
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current
                        swiper.params.navigation.nextEl = nextRef.current
                    }}
                    navigation={
                        {
                            nextEl: nextRef.current,
                            prevEl: prevRef.current,
                        }
                    }
                    draggable
                >
                    <div className={"swiper-button-prev"} ref={prevRef}>
                        <img src="/assets/icons/arrow-left-white.svg" alt="Précédent"/>
                    </div>
                    {gameVideos?.map((video, index) => (
                        <SwiperSlide key={index}>
                            <VideoCard group={video}/>
                        </SwiperSlide>
                    ))}
                    <div className={"swiper-button-next"} ref={nextRef}>
                        <img src="/assets/icons/arrow-right-white.svg" alt="Suivant"/>
                    </div>
                </Swiper>
            </div>
            {seeMoreMatch && (
                <ButtonCta text="VOIR TOUTES LES VIDÉOS" onClick={() => {
                    return navigate(LeaguePath.toLeagueMatchVideos(seeMoreMatch?.id, seeMoreMatch.gameId))
                }}/>
            )}
        </div>
    )
}

VideoSlider.propTypes = {
    gameVideos: PropTypes.arrayOf(leagueVideoGroupType).isRequired,
    seeMoreMatch: PropTypes.object,
}
