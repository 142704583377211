import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock-upgrade"
import { bool, func, object, oneOfType } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import ConfirmPopin from "../../../../../components/ConfirmPopin/ConfirmPopin"
import Preloader from "../../../../../components/loaders/preloader/preloader"
import Modal from "../../../../../components/Modal/Modal"
import ShareLink from "../../../../../components/ShareLink/ShareLink"
import { useTheme } from "../../../../../components/ThemeContext/ThemeContext"
import SelectUserFriends from "../../../../../components/user/selectUserFriends/selectUserFriends"
import { reservationInvitationsSend } from "../../../../../globalAPI/api"
import useMediaPath from "../../../../../hooks/useMediaPath"
import ModalHandler from "../../../../../providers/Modal/ModalHandler"
import usePath from "../../../../../routes/services/usePath"
import generateShareLink from "../../../../../services/generateShareLink"
import { useLocationStore } from "../../../store/store.js"
import { isCompositionTabAvailable } from "../../functions/functions"

import "./BookingInvitation.scss"

export const BookingInvitation = ModalHandler.create(({ location, addCallback, visible, hide, beforeClose  }) => {
    const [ linkToShare, setLinkToShare ] = useState("")
    const [ selectedFriends, setSelectedFriends ] = useState([])
    const [ allFriendsInLocation, setAllFriendsInLocation ] = useState(false)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ friendsToInvite, setFriendsToInvite ] = useState([])
    const [ publicDetailsGenerated, setPublicDetailsGenerated ] = useState(false)
    const { setCurrentLocation } = useLocationStore()

    const userFriends = useSelector(state => state.userFriends)

    const mediaPath = useMediaPath()
    const theme = useTheme()
    const navigate = useNavigate()
    const path = usePath()

    const description = `Es-tu sûr d’envoyer ${friendsToInvite?.length} invitation${friendsToInvite?.length > 1 ? "s" : ""} ?`

    const showBookingInvitationModal = () => {
        ModalHandler.show(ConfirmPopin, {
            actions: {
                confirm: () => {
                    addFriendsToLocation(friendsToInvite)
                },
            },
            description: description,
        }, {
            removeOnHide: false,
        })
    }

    useEffect(() => {
        ModalHandler.update(ConfirmPopin, {
            actions: {
                confirm: () => {
                    addFriendsToLocation(friendsToInvite)
                },
            },
            description: description,
        })
    }, [ friendsToInvite ])

    const userFriendsRef = useRef()

    const hideModal = () => {
        ModalHandler.hide(ConfirmPopin)
    }

    const addFriendsToLocation = (friendsIds) => {
        hideModal()
        setIsLoading(true)
        const data = {
            friendsIds: friendsIds.concat(location.invites.map(i => i.playerId)),
            locationId: location?.id,
            typeId: location.resourceType,
        }
        reservationInvitationsSend(data).then(
            (res) => {
                hide()
                setCurrentLocation(res.data.data)
                if (addCallback) {
                    addCallback(res.data.data)
                }
                setIsLoading(false)
                const tabToRedirect = isCompositionTabAvailable(location) ? "compo" : "infos"
                navigate(path("/reservations/locations/" + location?.id + `/${tabToRedirect}`))
            },
        )
    }

    useEffect(() => {
        if (location && Object.keys(location).length && !location.Message && location.publicJoinToken) {
            setLinkToShare(generateShareLink(location, theme, publicDetailsGenerated, setPublicDetailsGenerated))
        } else {
            setLinkToShare("")
        }
        if (location?.invites?.length) {
            setSelectedFriends(location.invites.map(i => i.playerId))
        } else {
            setSelectedFriends([])
        }
        return () => clearAllBodyScrollLocks()
    }, [ location ])

    return (
        <Modal
            className="bookingInvitation"
            hideOnOverlayClicked
            isOpen={visible}
            onClose={hide}
            beforeClose={() =>{
                clearAllBodyScrollLocks()
                beforeClose?.()
            }}
            afterOpen={() => {
                const target = document.querySelector(".modal.bookingInvitation .modal-content .locationFriendsInvite")
                disableBodyScroll(target)
            }}
        >
            {isLoading && <Preloader fixed />}
            <SelectUserFriends
                emptyInListing
                customHeader={(
                    <div className="header">
                        <div className="leftPart">
                            <img className="invitationLogo" src={mediaPath([ "/assets/images/bookingInvitation.svg", "/assets/images/padel/icons/icons-red-coequipiers.svg" ])} />
                            <div className="label">Inviter mes joueurs</div>
                        </div>
                        <div className="rightPart">
                            <ShareLink label="partager le lien du match" shareLink={linkToShare} isAlt/>
                            {selectedFriends?.length <= userFriends?.length && (<ButtonCta
                                isAlt
                                onClick={allFriendsInLocation ? userFriendsRef?.current?.empty : userFriendsRef?.current?.selectAll}
                                text={allFriendsInLocation ? "Tout déselectionner" : "Tout sélectionner"}
                                className="select"
                            />)}
                        </div>
                    </div>
                )}
                setAllFriendsInLocation={setAllFriendsInLocation}
                initialSelectedFriends={selectedFriends}
                emptyText="Tu n'as pas encore de coéquipiers ou ils sont tous dans cette réservation"
                callback={
                    (friendsId) => {
                        setFriendsToInvite(friendsId)
                        showBookingInvitationModal()
                    }
                }
                customAddLabel="envoyer une invitation"
                ref={userFriendsRef}
            />
        </Modal>
    )
})

BookingInvitation.displayName = "BookingInvitation"

BookingInvitation.propTypes = {
    addCallback: oneOfType([ bool, func ]),
    beforeClose: oneOfType([ bool, func ]),
    location: object,
}

export default BookingInvitation
