import classNames from "classnames"
import { motion } from "framer-motion"
import { arrayOf, bool, func, instanceOf, number, oneOf, oneOfType, shape, string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import { HorizontalScrollbar } from "../../HorizontalScrollBar/HorizontalScrollBar.jsx"

import "./Table.scss"

export default function Table({ columns, dataList, columnDisplayNames, title, customClass, animateRows, afterRowContent, decorator, withVirtualScrollbar, highlightIds }) {
    const [ hiddenOverflow, setHiddenOverflow ] = useState(true)
    const hasIdColumn = columns.length > 1 && columns?.some((column) => column.name === "id")
    const containerRef = useRef(null)
    const [ scrollSize, setScrollSize ] = useState({ thumbLeft: 0, thumbWidth: 0 })

    useEffect(() => {
        if (withVirtualScrollbar) {
            const updateScrollSize = () => {
                const container = containerRef.current
                const visibleWidth = container.clientWidth
                const totalWidth = container.scrollWidth
                const scrollLeft = container.scrollLeft

                const thumbWidth = Math.max((visibleWidth / totalWidth) * 100, 5)

                const thumbLeft = (scrollLeft / totalWidth) * 100

                setScrollSize({ thumbLeft, thumbWidth })
            }

            updateScrollSize() // Initial update
            const container = containerRef.current
            container.addEventListener("scroll", updateScrollSize)
            window.addEventListener("resize", updateScrollSize)

            return () => {
                container.removeEventListener("scroll", updateScrollSize)
                window.removeEventListener("resize", updateScrollSize)
            }
        }
    }, [])
    
    const formattedColumns = columns.length && hasIdColumn ? columns.slice(1) : columns

    return (
        <div className={classNames("table", { [customClass]: customClass, ["withVirtualScrollbar"]: withVirtualScrollbar })}>
            <h2 className="title"><img alt="decorator" className="decorator" src={decorator} />{title}</h2>
            <div className="tableWrapper" ref={containerRef}>
                <table className={classNames({ hiddenOverflow: hiddenOverflow })}>
                    {formattedColumns?.length > 0 && (
                        <thead>
                            <tr>
                                <th key={"tableHeadKey-" + "0"} className="numbers">{hasIdColumn ? columnDisplayNames?.get("id") : "#"}</th>
                                {formattedColumns?.map((column, i) => (
                                    <th
                                        key={"tableHeadKey-" + i}
                                        className={classNames({ first: i === 0 })}
                                        style={{ textAlign: column.align || "left" }}
                                    >
                                        {columnDisplayNames?.get(column.name) === "" ? ""
                                            : (columnDisplayNames?.get(column.name)) || column.name}
                                        {i === 0 && <img alt="decorator" src="/assets/icons/tableHeadDeco.svg"/>}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                    )}

                    {dataList?.length > 0 && (
                        <motion.tbody
                            onAnimationComplete={() => setHiddenOverflow(false)}
                            variants={animateRows ? containerVariants : {}}
                            initial="hidden"
                            animate="visible"
                        >
                            {dataList.map((row, i) => (
                                <React.Fragment key={`table-motion-body-${i}`}>
                                    <motion.tr
                                        key={"tableBodyKey-" + i}
                                        // apply highlighted class if row id is in highlightIds
                                        className={classNames({ highlighted: row.id && highlightIds?.includes(row.id) })}
                                        variants={animateRows ? itemVariants : {}}
                                    >
                                        <td
                                            className="numbers"
                                            key={"tableCell-row" + i + "-" + "0"}
                                        >{hasIdColumn ? columns[0]?.render?.(row) : i + 1}</td>
                                        {formattedColumns?.map((column, j) => (
                                            <td
                                                key={"tableCell-row" + i + "-" + j}
                                                className={classNames({
                                                    first: j === 0,
                                                    [column.cls]: column.cls,
                                                })}
                                                style={{ textAlign: column.align }}
                                            >
                                                {column.render && column.render(row) || row.values.get(column.name)}
                                            </td>
                                        ))}
                                    </motion.tr>
                                    {afterRowContent && afterRowContent.render && afterRowContent.rowId === row.id && (
                                        <motion.tr
                                            className="afterRowContent"
                                            key={"tableBodyKey-" + i + "-afterRowContent"}
                                            variants={animateRows ? afterRowVariants : {}}
                                        >
                                            <td colSpan={columns.length}>
                                                {afterRowContent.render(row)}
                                            </td>
                                        </motion.tr>
                                    )}
                                </React.Fragment>
                            ),
                            )}
                        </motion.tbody>
                    )}
                </table>
            </div>
            {withVirtualScrollbar && (
                <HorizontalScrollbar scrollSize={scrollSize} />
            )}
        </div>
    )
}

HorizontalScrollbar.propTypes = {
    scrollSize: shape({
        thumbLeft: number,
        thumbWidth: number,
    }),
}

Table.propTypes = {
    afterRowContent: shape({
        render : func,
        rowId: oneOfType([ number, string ]),
    }),
    animateRows: bool,
    columnDisplayNames: instanceOf(Map),
    columns: arrayOf(shape({
        align: oneOf([ "left", "center", "right" ]),
        cls: string,
        name: string.isRequired,
        render: func,
    })),
    customClass: string,
    dataList: arrayOf(shape({
        afterRowContent: func,
        id: oneOfType([ string.isRequired, number.isRequired ]),
        values: instanceOf(Map),
    })),
    decorator: string,
    highlightIds: arrayOf(oneOfType([ number, string ])),
    title: string,
    withVirtualScrollbar: bool,
}

const containerVariants = {
    hidden: { height: 0, opacity: 0 },
    visible: {
        height: "auto",
        opacity: 1,
        transition: {
            staggerChildren: 0.07,
        },
    },
}

const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
}

const afterRowVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
}

Table.defaultProps = {
    decorator: "/assets/icons/tableTitleDeco.svg",
}
