import { array, bool, func, object, oneOfType } from "prop-types"

import useMediaPath from "../../../hooks/useMediaPath"
import { useLeagueStore } from "../../../pages/league/store/useLeagueStore.js"
import ButtonCta from "../../Buttons/button/button-cta"
import ShareLink from "../../ShareLink/ShareLink"
import CompositionPlayer from "../CompositionPlayer/CompositionPlayer"

import "./CompositionBench.scss"

export default function CompositionBench({ players, autoComposition, handleSelection, openInvitationPopin, selected, canEdit }) {
    const mediaPath = useMediaPath()
    const { selectedLeagueTeam } = useLeagueStore()

    return (
        <div className="compositionBench">
            <div className="fadingWrapper">
                <div className="benchWrapper">
                    {canEdit && (
                        <>
                            {autoComposition && (
                                <ButtonCta
                                    icon={mediaPath([ "/assets/icons/random-compo.svg", "/assets/images/padel/icons/random-compo-red.svg" ])}
                                    isAlt
                                    text="compo auto"
                                    onClick={autoComposition}
                                    hoverChangeIcon={mediaPath([ "/assets/icons/random-compo-white.svg", "/assets/images/padel/icons/random-compo-green.svg" ])}
                                    className="compo-button"
                                />
                            )}
                            {(!players || players?.length === 0) && (
                                <CompositionPlayer
                                    action={handleSelection ?
                                        () => handleSelection(null, null)
                                        :
                                        undefined
                                    }
                                    selected={selected?.slotPosition === null}
                                    bench
                                />
                            )}
                            {players?.length > 0 && players.map(player => (
                                <CompositionPlayer
                                    player={player}
                                    selected={selected?.id === player.id}
                                    bench
                                    key={"bench-" + player.id}
                                    action={handleSelection ? () => handleSelection(player, null) : undefined}
                                />
                            ))}
                            <ButtonCta
                                icon={mediaPath([ "/assets/images/picto-add-user--white.svg", "/assets/images/padel/icons/icon-check-black.svg" ])}
                                onlyIcon
                                noSkew
                                className="inviteFriends"
                                onClick={openInvitationPopin}
                            />
                        </>
                    )}
                </div>
            </div>
            {!canEdit && (
                <ButtonCta
                    icon={mediaPath([ "/assets/images/picto-add-user--orange.svg", "/assets/images/padel/icons/icon-check-black.svg" ])}
                    noSkew
                    hoverChangeIcon={mediaPath([ "/assets/images/picto-add-user--white.svg", "/assets/images/padel/icons/icon-check-black.svg" ])}
                    text={"Inviter des joueurs"}
                    className={"shareLink"}
                    isAlt
                    onClick={openInvitationPopin}
                />
            )}
            {selectedLeagueTeam?.shareLink && (
                <ShareLink icon="" label="partager le lien du match" shareLink={selectedLeagueTeam?.shareLink}/>
            )}
        </div>
    )
}

CompositionBench.propTypes = {
    autoComposition: oneOfType([ bool, func ]),
    canEdit: bool,
    handleSelection: oneOfType([ bool, func ]),
    openInvitationPopin: oneOfType([ bool, func ]),
    players: array,
    selected: oneOfType([ bool, object ]),
}
