import LeagueGameLayout from "../../components/league/HOC/LeagueGameLayout.jsx"
import { LeagueLayout } from "../../components/league/HOC/LeagueLayout.jsx"
import { LeagueCalendar } from "../../pages/league/LeagueCalendar/LeagueCalendar.jsx"
import LeagueComposition from "../../pages/league/LeagueGameDetails/LeagueComposition/LeagueComposition.jsx"
import LeagueMatchRoster from "../../pages/league/LeagueGameDetails/LeagueMatchRoster/LeagueMatchRoster.jsx"
import LeagueMatchSummary from "../../pages/league/LeagueGameDetails/LeagueMatchSummary/LeagueMatchSummary.jsx"
import LeagueMatchVideos from "../../pages/league/LeagueGameDetails/LeagueMatchVideos/LeagueMatchVideos.jsx"
import { LeagueGeneral } from "../../pages/league/LeagueGeneral/LeagueGeneral.jsx"
import { LeaguePalmares } from "../../pages/league/LeaguePalmares/LeaguePalmares.jsx"
import { PalmaresDetails } from "../../pages/league/LeaguePalmares/PalmaresDetails/PalmaresDetails.jsx"
import { LeaguePlayerDetails } from "../../pages/league/LeaguePlayerDetails/LeaguePlayerDetails.jsx"
import { LeagueRanking } from "../../pages/league/LeagueRanking/LeagueRanking.jsx"
import { LeagueTeamDetails } from "../../pages/league/LeagueTeamDetails/LeagueTeamDetails.jsx"
import LeagueTunnel from "../../pages/league/LeagueTunnel"
import { ListingLeague } from "../../pages/league/ListingLeague.jsx"
import { OtherLeagues } from "../../pages/league/OtherLeagues/OtherLeagues.jsx"
import { LeaguePath } from "../ApplicationPaths/LeaguePaths.js"

const RoutesLeagues = [
    {
        element: (<ListingLeague/>),
        path: LeaguePath.listingLeague,
    },
    {
        children: [
            {
                element: (<LeagueTunnel />),
                path: LeaguePath.newLeagueWithId,
            },
        ],
        element: (<LeagueTunnel />),
        path: LeaguePath.newLeague,
    },
    {
        element: (<LeagueTeamDetails/>),
        path: LeaguePath.leagueTeamDetails,
    },
    {
        element: (<LeaguePlayerDetails />),
        path: LeaguePath.leaguePlayerDetails,
    },
    {
        children: [
            {
                children: [
                    {
                        element: (<LeagueComposition />),
                        path: LeaguePath.leagueMatchCompo,
                    },
                    {
                        element: (<LeagueMatchRoster />),
                        path: LeaguePath.leagueMatchRoster,
                    },
                    {
                        element: (<LeagueMatchSummary />),
                        path: LeaguePath.leagueMatchSummary,
                    },
                    {
                        element: (<LeagueMatchVideos />),
                        path: LeaguePath.leagueMatchVideo,
                    },
                ],
                element: (<LeagueGameLayout />),
                path: LeaguePath.leagueMatchDetails,
            },
        ],
        element: (<LeagueLayout title={"Mon match"} withMainSwitcher={false} withHeading={false}/>),
        path: LeaguePath.leagueMatchBase,
    },
    {
        children: [
            {
                element: (<LeagueGeneral />),
                path: LeaguePath.leagueInfosHome,
            },
            {
                element: (<LeagueCalendar />),
                path: LeaguePath.leagueInfosCalendar,
            },
            {
                element: (<LeagueRanking />),
                path: LeaguePath.leagueInfosRanking,
            },
        ],
        element: (<LeagueLayout  title={"Ma league"} withMainSwitcher/>),
        path: LeaguePath.leagueInfos,
    },
    {
        element: (<LeagueLayout  title={"Ma league"} withMainSwitcher/>),
        path: LeaguePath.leagueInfosBase,
    },
    {
        element: (<OtherLeagues />),
        path: LeaguePath.leagueRanking,
    },
    {
        element: (<PalmaresDetails />),
        path: LeaguePath.leaguePalmaresDetails,
    },
    {
        element: (<LeaguePalmares />),
        path: LeaguePath.leaguePalmares,
    },
]

export default RoutesLeagues
