import { bool, func, node , oneOfType , string } from "prop-types"

import ModalHandler from "../../../providers/Modal/ModalHandler"
import Modal from "../../Modal/Modal"

import "./MessagePopin.scss"

const MessagePopin = ModalHandler.create(({ title, message, visible, hide }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className='messagePopin'
        >
            <header>{title}</header>
            <section>
                {message}
            </section>
        </Modal>
    )
})

MessagePopin.displayName = "MessagePopin"

MessagePopin.propTypes = {
    isOpen: bool,
    message: oneOfType([ node, string ]),
    setIsOpen: func,
    title: string,
}

export default MessagePopin
