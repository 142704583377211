import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RouterProvider, createBrowserRouter, useLocation, useSearchParams } from "react-router-dom"

import RoutesPadel from "./RoutesPadel"
import RoutesSoccer from "./RoutesSoccer"
import App from "../App"
import Preloader from "../components/loaders/preloader/preloader"
import NotFound from "../components/notFound/notFound"
import { useIsWebview, useThemeInitialized, useThemeUpdate } from "../components/ThemeContext/ThemeContext"
import ConfirmationCmd from "../entries/main/contents/main/helpers/ConfirmationCmd"
import NotificationsSettings from "../entries/main/contents/main/notifications/notificationsSettings"
import useFavoriteUniverse from "../hooks/universe/useFavoriteUniverse"
import Authentication from "../pages/authentication/authentication"
import ChooseNewPassword from "../pages/authentication/chooseNewPassword/ChooseNewPassword"
import PasswordForgotten from "../pages/authentication/passwordForgotten/passwordForgotten"
import SignUpPage from "../pages/authentication/signUpPage/signUpPage"
import { ErrorBoundary } from "../pages/error/ErrorBoundary.jsx"
import Home from "../pages/home/home"
import OrderSummarySchool from "../pages/kids/steps/OrderSummary"
import OrderSummary from "../pages/payments/OrderSummary/OrderSummary"
import { modifyFavoriteUniverse } from "../pages/profil/api"
import ModalHandler from "../providers/Modal/ModalHandler.jsx"

const routesConfig =  [
    // PUBLIC ROUTES
    {
        element: (<Authentication/>),
        path: "/login",
    },
    {
        element: (<Authentication />),
        path: "/padel/login",
    },
    {
        element: (<PasswordForgotten/>),
        path: "/forgotPassword",
    },
    {
        element: (<PasswordForgotten />),
        path: "/padel/forgotPassword",
    },
    {
        element: (<SignUpPage/>),
        path: "/signup",
    },
    {
        element: (<SignUpPage />),
        path: "/padel/signup",
    },
    {
        element: (<ChooseNewPassword/>),
        path: "/reload/passwordReset",
    },
    {
        element: (<ChooseNewPassword />),
        path: "/padel/reload/passwordReset",
    },

    // AUTH ROUTES
    {
        children: [
            {
                element: (<Home />),
                path: "/",
            },
            {
                element: (<Home />),
                path: "/user",
            },
            {
                element: (<NotificationsSettings />),
                path: "/notificationsSettings",
            },
            {
                element: (<Home />),
                path: "/setUniverse/:universeId",
            },
            {
                element: (<Home />),
                path: "/resetUniverse",
            },
            {
                element: (<ConfirmationCmd />),
                path: "/confirmcommand",
            },
            {
                element: (<ConfirmationCmd />),
                path: "/error",
            },
            {
                element: (<OrderSummary />),
                path: "/ordersummary",
            },
            {
                element: (<OrderSummarySchool />),
                path: "/schoolordersummary",
            },
            // SOCCER
            ...RoutesSoccer,
            // PADEL
            ...RoutesPadel,
            // 404
            {
                element: (<Preloader fixed />),
                path: "/reload/*",
            },
            {
                element: (<NotFound/>),
                path: "/*",
            },
        ],
        element: (<App />),
        errorElement: (<ErrorBoundary />),
        path: "/",
    },
]

// eslint-disable-next-line react/prop-types
export default function RouterManager({ children }) {
    const location = useLocation()
    const setTheme = useThemeUpdate()
    const isWebview = useIsWebview()
    const themeInitialized = useThemeInitialized()
    const user = useSelector(state => state.userLoaded)
    const regexPadel = /^(?:\/padel)/g
    const getUniverse = useFavoriteUniverse()
    const [ fromApp, setFromApp ] = useState(false)
    const [ searchParams ] = useSearchParams()

    const handleUniverReload = (isReload, lastUniverse) => {
        if (isReload && lastUniverse) {
            setFromApp(true)
            setTheme(lastUniverse)
        } else {
            setTheme(getUniverse(user.favoriteUniverse))
            setFromApp(true)
        }
    }

    useEffect(() => {
        const themeParams = searchParams.get("theme")
        if (Object.keys(user).length && location.pathname !== "/login" && location.pathname !== "/padel/login" && location.pathname !== "/" && location.pathname !== "/resetUniverse") {
            const isPadel = location.pathname.match(regexPadel) ?? false
            let universe = ""
            // update local theme value
            if (isPadel /*&& !isWebview*/) {
                universe = "theme-padel"
            } else {
                universe = "theme-soccer"
            }

            // update user preferences
            if (user?.favoriteUniverse === null && !isWebview) {
                modifyFavoriteUniverse(user, universe).then(() => {
                    setTheme(universe)
                    setFromApp(true)
                })
            } else {
                setTheme(universe)
                setFromApp(true)
            }
            // navigate to soccer route in webview
            // if (isPadel && isWebview) navigate(location.pathname.replace(regexPadel, ""));
        } else if (Object.keys(user).length && location.pathname === "/" && user.favoriteUniverse !== null && !fromApp && !themeParams) {
            const lastUniverse = localStorage.getItem("lastUniverse")
            const isReload = performance.getEntriesByType("navigation")[0]?.type === "reload"
            handleUniverReload(isReload, lastUniverse)
        } else if ((location.pathname === "/login" && localStorage.getItem("isUserDisconnect")) || location.pathname === "/padel/login") {
            setFromApp(true)
            localStorage.removeItem("isUserDisconnect")
        }
        if (Object.keys(user).length && location.pathname === "/" && user.favoriteUniverse !== null && !themeInitialized) {
            setTheme("theme-soccer")
        }
    }, [ user, location ])

    return <ModalHandler.Provider>{ children }</ModalHandler.Provider>
}

export function Router() {
    return (
        <RouterProvider router={createBrowserRouter(routesConfig)} fallbackElement={<Preloader fixed />}/>
    )
}
