import { bool, func, object, oneOfType, string } from "prop-types"
import React from "react"

import "./TextArea.scss"

const TextArea = React.forwardRef(
    function textArea(
        {   
            isEmpty,
            defaultValue,
            labelText,
            value,
            onChange,
            placeholder = "",
            maxLength,
            isRequired = false,
            onClick,
        },
        ref,
    ) {
        return (
            <div className={isEmpty ? "c-inputHolder c-inputHolder--textarea empty" : "c-inputHolder c-inputHolder--textarea"}>
                <div className="text-area">
                    {labelText && (
                        <label className="text-area-label">
                            {labelText}
                            {isRequired &&
                                <span className="label-star">*</span>
                            }
                        </label>
                    )}
                    <textarea
                        defaultValue={defaultValue}
                        ref={ref}
                        className="textArea"
                        maxLength={maxLength}
                        onChange={
                            (e) => {
                                if (onChange) {
                                    onChange(e.target.value)
                                }
                            }
                        }
                        onClick={onClick ?? onClick}
                        value={value}
                        placeholder={placeholder}
                    />
                </div>
            </div>
        )
    },
)

TextArea.propTypes = {
    defaultValue: object,
    isEmpty: bool,
    isRequired: bool,
    labelText: string,
    maxLength: string,
    onChange: func,
    onClick: oneOfType([ bool, func ]),
    placeholder: string,
    ref: string,
    value: string,
}

export default TextArea
