import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { LeagueHeader } from "../../../components/league/LeagueHeader/LeagueHeader.jsx"
import { PrizeSlider } from "../../../components/league/PrizeSlider/PrizeSlider.jsx"
import StatsSummary from "../../../components/league/StatsSummary/StatsSummary.jsx"
import { TeamHeading } from "../../../components/league/TeamHeading/TeamHeading.jsx"
import { TeamPlayersList } from "../../../components/league/TeamPlayersList/TeamPlayersList.jsx"
import { TeamStoreAd } from "../../../components/league/TeamStoreAd/TeamStoreAd.jsx"
import { TeamVisuals } from "../../../components/league/TeamVisuals/TeamVisuals.jsx"
import Preloader from "../../../components/loaders/preloader/preloader.jsx"
import { useTheme } from "../../../components/ThemeContext/ThemeContext.jsx"
import useThemeHeader from "../../../hooks/useThemeHeader.js"
import { AnimationPresets } from "../../../utilities/animations.js"
import { getLeagueAds, getLeagueTeamById } from "../api/myLeague.js"
import { resolveTeamPJT } from "../service/service.js"
import { useLeagueStore } from "../store/useLeagueStore.js"

import "./LeagueTeamDetails.scss"

export const LeagueTeamDetails = () => {
    const { teamId  } = useParams()
    const { leagueTeamCache, setLeagueTeamCache, setAds, shouldRevalidate, setShouldRevalidate } = useLeagueStore()
    const [ isLoading, setIsLoading ] = useState(false)
    const theme = useTheme()
    const themeHeader = useThemeHeader()
    const userLoaded = useSelector(state => state.userLoaded)

    const [ currentTeam, setCurrentTeam ] = useState(null)

    useEffect(() => {
        if ((leagueTeamCache?.length > 0 && !leagueTeamCache?.find(team => team.id === parseInt(teamId)) || !leagueTeamCache?.length) || shouldRevalidate) {
            setIsLoading(true)
            getLeagueTeamById(teamId).then(team => {
                setLeagueTeamCache({
                    ...team,
                    shareLink: resolveTeamPJT(team, theme, userLoaded),
                })

                setCurrentTeam({
                    ...team,
                    shareLink: resolveTeamPJT(team, theme, userLoaded),
                })
            }).finally(() => {
                setShouldRevalidate(false)
                setIsLoading(false)
            })
        } else if (leagueTeamCache?.length > 0) {
            setCurrentTeam(leagueTeamCache.find(team => team.id === parseInt(teamId)))
        }
        getLeagueAds(themeHeader).then(setAds)
    }, [ teamId, shouldRevalidate ])

    const teamStatsSummary = currentTeam ? {
        draws: currentTeam.draws,
        gamesSeries: currentTeam.fivePastGamesResult?.split(","),
        goals: {
            conceded: currentTeam.goalsAgainst,
            scored: currentTeam.goalsFor,
        },
        losses: currentTeam.losses,
        played: currentTeam.played,
        wins: currentTeam.wins,
    } : null

    if (isLoading) {
        return (<Preloader fixed/>)
    }

    // grid doesn't allow to create a proper layout to display these content, so we need to render different blocks depending on the screen size
    return (
        <>
            <LeagueHeader title={"Détails de l'équipe"}>
                <TeamHeading team={currentTeam}/>
            </LeagueHeader>
            <div className={"league-team-details c-row justify-center league-layout"}>
                <div className="c-col c-col--12 no-padding content">
                    <div>
                        {currentTeam?.cover && (
                            <motion.div
                                custom={0} variants={AnimationPresets.gridRevealBuilder} initial="hidden"
                                className="block1"
                                animate="visible"
                            >
                                <TeamVisuals team={currentTeam}/>
                            </motion.div>
                        )}
                        <motion.div
                            custom={1} variants={AnimationPresets.gridRevealBuilder} initial="hidden"
                            className="block2"
                            animate="visible"
                        >
                            <TeamPlayersList team={currentTeam} extendedPadding={!currentTeam?.cover}/>
                        </motion.div>
                        {teamStatsSummary && (
                            <motion.div
                                custom={2} variants={AnimationPresets.gridRevealBuilder} initial="hidden"
                                className="block3"
                                animate="visible"
                            >
                                <StatsSummary data={teamStatsSummary} layout="team"/>
                            </motion.div>
                        )}
                        <motion.div
                            custom={3} variants={AnimationPresets.gridRevealBuilder} initial="hidden" className="block4"
                            animate="visible"
                        >
                            <PrizeSlider team={currentTeam}/>
                        </motion.div>
                        <motion.div
                            custom={4} variants={AnimationPresets.gridRevealBuilder} initial="hidden" className="block5"
                            animate="visible"
                        >
                            <TeamStoreAd/>
                        </motion.div>
                    </div>
                    <div>
                        <motion.div
                            custom={1} variants={AnimationPresets.gridRevealBuilder} initial="hidden" className="block2"
                            animate="visible"
                        >
                            <TeamPlayersList team={currentTeam}/>
                        </motion.div>
                        <motion.div
                            custom={3} variants={AnimationPresets.gridRevealBuilder} initial="hidden" className="block4"
                            animate="visible"
                        >
                            <PrizeSlider team={currentTeam}/>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    )
}
