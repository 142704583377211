import classNames from "classnames"
import { bool, func, number, string } from "prop-types"
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"

import Preloader from "../../entries/main/../../components/loaders/preloader/preloader"
import useCookies from "../../hooks/useCookies"
import useElementOnScreen from "../../hooks/useElementOnScreen"
import useMediaPath from "../../hooks/useMediaPath"
import ButtonCta from "../Buttons/button/button-cta"
import PageHeader from "../layout/pageHeader/PageHeader"
import PhoneCallHelp from "../popin/help/phoneCall"
import Help from "../tooltip/Help/Help"

import "./InfiniteScroller.scss"

const InfiniteScroller = forwardRef(
    ({
        id,
        gatherTopData,
        gatherBotData,
        emptyDataText,
        emptyDataButtonText,
        emptyDataButtonCallback,
        topTitle,
        botTitle,
        topDisplayMapper,
        botDisplayMapper,
        getItemBlockById,
        PAGE_SIZE = 50,
        noGoBack,
    }, ref) => {
        const initialTopElementCount = 3
        const distanceFromTopBeforeLoad = 0
        const minSwipeDistance = 10

        const [ hasMoreDataTop, setHasMoreDataTop ] = useState(true)
        const [ hasMoreDataBot, setHasMoreDataBot ] = useState(true)

        const [ topData, setTopData ] = useState(null)
        const [ botData, setBotData ] = useState(null)

        const [ topOffset, setTopOffset ] = useState(0)
        const [ botOffset, setBotOffset ] = useState(0)

        const [ isLoading, setIsLoading ] = useState(false)
        const [ ajaxLoading, setAjaxLoading ] = useState(false)

        const [ topDataLength, setTopDataLength ] = useState(null)
        const [ touchStartY, setTouchStartY ] = useState(null)
        const [ mostRecentPlayedMatchAnchor, setMostRecentPlayedMatchAnchor ] = useState(null)

        const playedSection = useRef(null)
        const incomingSection = useRef(null)
        const botwrapper = useRef(null)
        const playedAnchor = useRef(null)
        const incomingAnchor = useRef(null)

        const mediaPath = useMediaPath()
        const { isWebview } = useCookies("webview")

        const [ ref1IsVisible, ref2IsVisible ] = useElementOnScreen(
            {
                ajaxLoading: ajaxLoading,
                botData: botData,
                isLoading: isLoading,
                ref1: playedSection,
                ref2: incomingSection,
                topData: topData,
            },
        )

        const comingMatchSort = (a, b) => {
            const aStart = a.start ?
                a.start
                :
                a.reservation ?
                    a.reservation.start
                    :
                    a.request.reservation.start

            const bStart = b.start ?
                b.start
                :
                b.reservation ?
                    b.reservation.start
                    :
                    b.request.reservation.start
            return aStart === bStart ?
                0
                :
                aStart < bStart ?
                    -1
                    :
                    1
        }

        useImperativeHandle(
            ref,
            () => ({
                removingBotItem: (functionCondition) => {
                    const tempData = botData
                    if (tempData) {
                        tempData.splice(
                            tempData.findIndex(
                                match => functionCondition(match),
                            ),
                            1,
                        )
                        setBotData([ ...tempData ])
                    }
                },
            }),
        )

        useEffect(
            () => {
                setAjaxLoading(true)

                // Matchs passés
                Promise.all([
                    getNextTopData(0, initialTopElementCount),
                    getNextBotData(),
                ]).then(
                    () => {
                        setAjaxLoading(false)
                    },
                )
                document.body.style.overscrollBehaviorY = "contain"
            }, [],
        )

        useEffect(
            () => {
                if (mostRecentPlayedMatchAnchor) {
                    let lastItemDOM = getItemBlockById(mostRecentPlayedMatchAnchor)
                    if (lastItemDOM) {
                        if (topData?.length > 3) {
                            const playedBlocks = document.querySelectorAll("#played .locationBlock")
                            playedBlocks?.[topDataLength]?.scrollIntoView()
                            botwrapper.current.scrollTo(0, lastItemDOM.offsetTop - 200)
                        }
                        setMostRecentPlayedMatchAnchor(topData[topData.length - 1].id)
                    }
                } else if (topData?.length) {
                    setMostRecentPlayedMatchAnchor(topData[topData.length - 1].id)
                }
            }, [ topData ],
        )
        function getNextTopData(offset = topOffset, overridePageOffset = false) {
            return new Promise(
                (resolve) => {
                    const countToGather = overridePageOffset ?
                        overridePageOffset
                        :
                        PAGE_SIZE

                    gatherTopData(countToGather, offset).then(
                        (res) => {
                            let data = res.data.data.items ? res.data.data.items : res.data.data
                            let dataLength =  topDataLength ? topDataLength + data.length : data.length
                            //to delete after xelya send locations without status -2 and -1
                            if (data && data.length > 0) {
                                data = data.filter(match => match.status !== -2 && match.status !== -1)
                            }

                            if (dataLength === res.data.data.hitCount || data.length === 0) {
                                setHasMoreDataTop(false)
                            } else {
                                setTopDataLength(dataLength)
                            }

                            setTopData(
                                topData === null ?
                                    data
                                    :
                                    topData.concat(data),
                            )
                            resolve(data)
                        },
                    )
                },
            )
        }

        function getNextBotData(offset = botOffset) {
            return new Promise(
                (resolve) => {
                    gatherBotData(PAGE_SIZE, offset).then(
                        (res) => {
                            let data = res.data ?
                                res.data.data ?
                                    res.data.data.items ?
                                        res.data.data.items
                                        : res.data.data
                                    : res.data
                                : res

                            //to delete after xelya send locations without status -2 and -1
                            if (data && data.length > 0) {
                                data = data.filter(match => match.status !== -2 && match.status !== -1)
                            }
                            if (data.length <= PAGE_SIZE) {
                                setHasMoreDataBot(false)
                            }
                            setBotData(
                                botData === null ?
                                    data.sort(comingMatchSort)
                                    :
                                    botData.concat(data).sort(comingMatchSort),
                            )
                            resolve(data)
                        },
                    )
                },
            )
        }

        function fetchTopData(loader = false) {
            if (topData) {
                const newOffset = topData.length === initialTopElementCount ?
                    initialTopElementCount
                    :
                    topOffset + PAGE_SIZE

                if (loader) {
                    setAjaxLoading(true)
                }
                setTopOffset(newOffset)
                setIsLoading(true)
                getNextTopData(newOffset).then(
                    () => {
                        setIsLoading(false)
                        if (loader) {
                            setAjaxLoading(false)
                        }
                    },
                )
            }
        }

        function fetchBotData() {
            const newOffset = botOffset + PAGE_SIZE
            setBotOffset(newOffset)
            getNextBotData(newOffset)
        }
        function swipeEnd(e) {
            const touchEndY = Math.floor(e.changedTouches[0].clientY)
            const topDistance = botwrapper.current.scrollTop
            const htlmScrollTop = document.querySelector("html").scrollTop
            if ((touchEndY - touchStartY ?? touchEndY) > minSwipeDistance) {
                if (topDistance && hasMoreDataTop && topDistance <= distanceFromTopBeforeLoad) {
                    fetchTopData(true)
                } else if (htlmScrollTop && hasMoreDataTop && htlmScrollTop <= distanceFromTopBeforeLoad) {
                    fetchTopData(true)
                } else if (hasMoreDataTop && !topDistance && !htlmScrollTop) {
                    fetchTopData(true)
                }
            }
        }

        function scrollToSection(sectionName) {
            switch (sectionName) {
            case "played":
                playedSection.current.scrollIntoView({ behavior: "instant",block: "start" })
                break
            case "incoming":
                incomingSection.current.scrollIntoView({ behavior: "instant",block: "start" })
                break
            }
        }

        useEffect(
            () => {
                if (incomingSection?.current) {
                    incomingSection.current.scrollIntoView({ behavior: "instant",block: "start" })
                }
            }, [ incomingSection.current ],
        )

        return (
            <>
                <PageHeader
                    goBack={!noGoBack}
                    title="Mes matchs"
                    button={(
                        <Help
                            id="MatchHelp"
                        >
                            <PhoneCallHelp tunnelType="SUPERSUB"/>
                        </Help>
                    )}
                />
                <div
                    id={id}
                    className="c-row justify-center botwrapper"
                    onWheel={
                        (ev) => {
                            if (ev.deltaY < 0 && !isLoading) {
                                const topDistance = botwrapper.current.scrollTop
                                const htlmScrollTop = document.querySelector("html").scrollTop
                                if (topDistance) {
                                    if (hasMoreDataTop && topDistance <= distanceFromTopBeforeLoad) {
                                        fetchTopData(true)
                                    }
                                } else if (htlmScrollTop) {
                                    if (hasMoreDataTop && htlmScrollTop <= distanceFromTopBeforeLoad) {
                                        fetchTopData(true)
                                    }
                                } else if (hasMoreDataTop && !topDistance && !htlmScrollTop) {
                                    fetchTopData(true)
                                }
                            }
                        }
                    }
                    onTouchStart={(e) => setTouchStartY(Math.floor(e.targetTouches[0].clientY))}
                    onTouchEnd={(e) => swipeEnd(e)}
                    ref={botwrapper}
                >
                    { ajaxLoading &&
                        <Preloader fixed={true}/>
                    }
                    {(topData !== null && botData !== null) && (
                        (topData.length === 0 && botData.length === 0) ? (
                            <div className="c-col c-col--10 c-col--sm--11 flex justify-center">
                                <section className='o-section--empty'>
                                    <img alt="" src={mediaPath([ "/assets/images/noMatch.svg", "/assets/images/padel/icons/icons-terrain-barre.svg" ])} />
                                    <p>{emptyDataText}</p>
                                    <ButtonCta
                                        text= {emptyDataButtonText}
                                        isAlt= {true}
                                        onClick={emptyDataButtonCallback}
                                    />
                                </section>
                            </div>
                        ) : (
                            <>
                                {topData && topData.length > 0 && (
                                    <div className={classNames("c-col c-col--10 c-col--sm--11", {
                                        paddingTop: botData?.length,
                                    })}>
                                        <div
                                            onClick={
                                                (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    scrollToSection("played")
                                                }
                                            }
                                            className={classNames("anchor", {
                                                active: ref2IsVisible,
                                                webview: isWebview,
                                            })}
                                            ref={playedAnchor}
                                        >
                                            {topTitle}
                                        </div>
                                        <section
                                            id="played"
                                            className={classNames("o-section--played", {
                                                noPadding: botData?.length > 0,
                                            })}
                                            ref={playedSection}
                                        >
                                            <h2>{topTitle}</h2>
                                            <InfiniteScroll
                                                dataLength={topData.length}
                                                hasMore={hasMoreDataTop}
                                                className="reverse"
                                                inverse={true}
                                            >
                                                { topData.map(topDisplayMapper)}
                                            </InfiniteScroll>
                                        </section>
                                    </div>
                                )}
                                {botData && botData.length > 0 && (
                                    <div className="c-col c-col--10 c-col--sm--11">
                                        <div
                                            onClick={
                                                (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    scrollToSection("incoming")
                                                }
                                            }
                                            className={classNames("anchor bottom", {
                                                active: ref1IsVisible && !ref2IsVisible ,
                                                webview: isWebview,
                                            })}
                                            ref={incomingAnchor}
                                        >
                                            {botTitle}
                                        </div>
                                        <section
                                            id="incoming"
                                            className="o-section--incoming"
                                            ref={incomingSection}
                                        >
                                            <h2>{botTitle}</h2>
                                            <InfiniteScroll
                                                dataLength={botData.length}
                                                next={fetchBotData}
                                                hasMore={hasMoreDataBot}
                                                loader={<Preloader/>}
                                            >
                                                {
                                                    botData.map(botDisplayMapper)
                                                }
                                            </InfiniteScroll>
                                        </section>
                                    </div>
                                )}
                            </>
                        )
                    )}
                </div>
            </>
        )
    },
)

InfiniteScroller.propTypes = {
    PAGE_SIZE: number,
    botDisplayMapper: func,
    botTitle: string,
    emptyDataButtonCallback: func,
    emptyDataButtonText: string,
    emptyDataText: string,
    gatherBotData: func,
    gatherTopData: func,
    getItemBlockById: func,
    id: string,
    noGoBack: bool,
    topDisplayMapper: func,
    topTitle: string,
}

InfiniteScroller.displayName = "Form"

export default InfiniteScroller
