import classNames from "classnames"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import { getLeagueTeamPrizes } from "../../../pages/league/api/myLeague.js"
import { LeaguePath } from "../../../routes/ApplicationPaths/LeaguePaths.js"

import "./PrizeSlider.scss"

export const PrizeSlider = ({ team }) => {
    const navigate = useNavigate()
    const [ prizes, setPrizes ] = useState([])
    const [ activeIndex, setActiveIndex ] = useState(0)

    useEffect(() => {
        if (team?.id) {
            getLeagueTeamPrizes(team.teamId).then((data) => {
                setPrizes(data)
            })
        }
    }, [ team?.id ])

    if (!prizes?.length) {
        return null
    }

    const redirectToPalmaresDetails = (prize) => {
        navigate(LeaguePath.toLeaguePalmaresDetails(prize?.leagueId, prize?.championshipTeamId))
    }

    return (
        <section className="prize-slider">
            <header>
                <h2>Palmarès</h2>
            </header>
            <nav className="prizes">
                <button className={classNames("swiper-button-prev vslider", {
                    "hidden": prizes?.length <= 1 || activeIndex === 0,
                })} aria-label="Previous slide">
                    <img src="/assets/icons/arrow-left-white.svg" alt="Previous"/>
                </button>
                <Swiper
                    key="vslider"
                    updateOnWindowResize={true}
                    modules={[ Navigation ]}
                    slidesPerView={1}
                    spaceBetween={20}
                    onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                    navigation={{
                        nextEl: ".swiper-button-next.vslider",
                        prevEl: ".swiper-button-prev.vslider",
                    }}
                >
                    {prizes?.map((prize, index) => (
                        <SwiperSlide key={index}>
                            <article className="prize-card" onClick={() => redirectToPalmaresDetails(prize)}>
                                <h3>
                                    {prize.rank === 1 ? "1er" : `${prize.rank}ème`}
                                </h3>
                                <p>
                                    {prize.leagueName}
                                </p>
                                <div className="prize-icon">
                                    <img src="/assets/icons/league/cup-white.svg" alt="Trophy"/>
                                </div>
                            </article>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <button className={classNames("swiper-button-next vslider", {
                    "hidden": prizes?.length <= 1,
                })} aria-label="Next slide">
                    <img src="/assets/icons/arrow-right-white.svg" alt="Next"/>
                </button>
            </nav>
        </section>
    )
}

PrizeSlider.propTypes = {
    team: PropTypes.object,
}
