import dayjs from "dayjs"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"

import ButtonCta from "../../../../components/Buttons/button/button-cta.jsx"
import ConfirmPopin from "../../../../components/ConfirmPopin/ConfirmPopin.jsx"
import { ForfeitModal } from "../../../../components/league/ForfeitModal/ForfeitModal.jsx"
import PlayersStatusList from "../../../../components/league/PlayersStatusList/PlayersStatusList.jsx"
import Preloader from "../../../../components/loaders/preloader/preloader.jsx"
import MessagePopin from "../../../../components/popin/MessagePopin/MessagePopin.jsx"
import { TooltipLight } from "../../../../components/tooltip/tooltipLight/tooltipLight.jsx"
import useMediaPath from "../../../../hooks/useMediaPath.js"
import usePlural from "../../../../hooks/usePlural.js"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import { gameForfeit, sendGameReminder } from "../../api/leagueGame.js"
import { changeGamePlayerStatus } from "../../api/myLeague.js"
import { attachMvpVotesToPlayers, isGameForfeit } from "../../service/service.js"
import { useLeagueStore } from "../../store/useLeagueStore.js"

import "./LeagueMatchRoster.scss"

const LeagueMatchRoster = () => {
    const { currentGame, selectedLeagueTeam, setCurrentGame, setSelectedTeamData, setShouldRevalidate, setGameComposition } = useLeagueStore()
    const [ isLoading, setIsLoading ] = useState(false)
    const mediaPath = useMediaPath()
    const [ selected, setSelected ] = useState(null)
    const userLoaded = useSelector(state => state.userLoaded)

    const players = useMemo(() => {
        const mvpVotePlayers = attachMvpVotesToPlayers(currentGame)?.players ?? []
        return {
            absent: mvpVotePlayers?.filter(player => player.status === 2) || [],
            present: mvpVotePlayers?.filter(player => player.status === 1) || [],
            waiting: mvpVotePlayers?.filter(player => player.status === 0) || [],
        }
    }, [ currentGame ])

    const changePlayerStatus = async ({ status, playerId }) => {
        const players = currentGame?.players?.map(player => player.id === playerId ? { ...player, slotPosition: null, status } : player)
        const newGame = {
            ...currentGame,
            players: players,
        }

        setCurrentGame(newGame)
        setGameComposition(players?.filter(player => player?.status === 1) ?? [])
        setSelectedTeamData({ shouldRevalidate: true })

        setSelected(null)
        await changeGamePlayerStatus(currentGame?.id, playerId, status, selectedLeagueTeam.id).then(res => {
            setCurrentGame({
                ...newGame,
                mvpInfos: res?.data?.mvpInfos,
            })
        }).catch(() => {
            setCurrentGame(currentGame)
        })
    }

    const currentGameStarted = dayjs().isAfter(dayjs(currentGame?.date)) || isGameForfeit(currentGame)
    const canEdit = currentGame?.canEdit && !isGameForfeit(currentGame)

    const openForfeitModal = () => {
        ModalHandler.show(ForfeitModal, {
            callback: (comment) => {
                setIsLoading(true)
                gameForfeit(currentGame?.id, selectedLeagueTeam.id, comment).then(() => {
                    setShouldRevalidate(true)
                }).catch((e) => {
                    ModalHandler.show(MessagePopin, {
                        message: e.response?.data?.error ?? "Impossible de déclarer forfait",
                        title: "Une erreur est survenue",
                    })
                }).finally(() => {
                    setIsLoading(false)
                })
            },
        })
    }

    const sendReminder = () => {
        ModalHandler.show(ConfirmPopin, {
            actions: {
                confirm: () => {
                    setIsLoading(true)
                    ModalHandler.hide(ConfirmPopin)
                    sendGameReminder(currentGame?.id, selectedLeagueTeam.id).then((d) => {
                        if (d?.Message) {
                            ModalHandler.show(MessagePopin, {
                                message: d.Message,
                                title: "Une erreur est survenue",
                            })
                            setIsLoading(false)
                        } else {
                            setIsLoading(false)
                            setShouldRevalidate(true)
                        }

                    }).catch(() => {
                        setIsLoading(false)
                    })
                },
            },
            description: "Êtes-vous sûr de vouloir envoyer un rappel aux joueurs en attente ?",
            title: "Envoyer un rappel",
        })
    }

    const nextReminderDate = currentGame?.lastReminderDate ? dayjs(currentGame?.lastReminderDate).add(1, "day") : null
    const isMatchPlayed = dayjs().isAfter(dayjs(currentGame?.date))

    return (
        <div className="leagueMatchRoster">
            {isLoading && <Preloader fixed/>}
            {players?.present?.length > 0 && (
                <PlayersStatusList
                    players={players?.present.sort((a, b) => b.currentNbVotes - a.currentNbVotes)}
                    title={`${usePlural(players?.present?.length, "Présent", "Présents")} (${players?.present?.length})`}
                    selected={selected}
                    setSelected={setSelected}
                    changePlayerStatus={changePlayerStatus}
                    allowEdit={canEdit}
                    disableMvp={isGameForfeit(currentGame)}
                    currentUserId={!isGameForfeit(currentGame) ? userLoaded?.contactId : null}
                />
            )}
            {players?.waiting?.length > 0 && (
                <>
                    <PlayersStatusList
                        players={players?.waiting}
                        title={`En attente (${players?.waiting?.length})`}
                        selected={selected}
                        setSelected={setSelected}
                        changePlayerStatus={changePlayerStatus}
                        allowEdit={canEdit}
                        currentUserId={!isGameForfeit(currentGame) ? userLoaded?.contactId : null}
                    />
                    {(canEdit && !isMatchPlayed) ? currentGame?.canRemind ? (
                        <div className={"reminderWrapper"}>
                            <ButtonCta
                                isAlt
                                className={"remindButton"}
                                text={"envoyer un rappel"}
                                onClick={sendReminder}
                                icon={mediaPath([ "/assets/icons/notification--orange.svg", "/assets/images/padel/icons/icons-bell.svg" ])}
                                hoverChangeIcon={mediaPath([ "/assets/icons/notification--grey.svg", "/assets/images/padel/icons/icons-bell-white.svg" ])}
                            />
                        </div>
                    ) : (
                        <TooltipLight text={nextReminderDate ? `Prochaine relance disponible le : ${nextReminderDate.format("DD/MM/YYYY à HH:mm")}` : null} centered dark position={"top"}>
                            <div className={"reminderWrapper"}>
                                <ButtonCta
                                    isAlt
                                    disabled
                                    onClick={(e) => {
                                        e.preventDefault()
                                    }}
                                    className={"remindButton"}
                                    text={"envoyer un rappel"}
                                    icon={mediaPath([ "/assets/icons/notification--grey.svg", "/assets/images/padel/icons/icons-bell.svg" ])}
                                    hoverChangeIcon={mediaPath([ "/assets/icons/notification--grey.svg", "/assets/images/padel/icons/icons-bell-white.svg" ])}
                                />
                            </div>
                        </TooltipLight>
                    ) : null}
                </>
            )}
            {players?.absent?.length > 0 && (
                <PlayersStatusList
                    players={players?.absent}
                    title={`${usePlural(players?.absent?.length, "Absent", "Absents")} (${players?.absent?.length})`}
                    selected={selected}
                    setSelected={setSelected}
                    changePlayerStatus={changePlayerStatus}
                    allowEdit={canEdit}
                    currentUserId={!isGameForfeit(currentGame) ? userLoaded?.contactId : null}
                />
            )}
            {canEdit && !currentGameStarted && (
                <ButtonCta isCta2Alt text={"Déclarer forfait"} onClick={openForfeitModal}/>
            )}
        </div>
    )
}

export default LeagueMatchRoster
