import { combineReducers } from "redux"

import AjaxCallingReducer from "./reducer-ajax-calling"
import AuthenticationOverlayUpdatedReducer from "./reducer-authentication-overlay-updated"
import CheckSkipEDF from "./reducer-check-skip-edf"
import ConfirmationOverlayUpdatedReducer from "./reducer-confirmation-overlay-updated"
import DateValue from "./reducer-date-value"
import DomDetailsReducer from "./reducer-dom-details"
import GlobalLoader from "./reducer-globals-loader"
import HasArdoisesReducer from "./reducer-has-ardoises"
import HasUnpaidBills from "./reducer-has-unpaid-bills"
import appConfigReducer from "./reducer-init-config"
import Level3OverlayToggledReducer from "./reducer-level-3-overlay-toggled"
import QueryParametersLoadedReducer from "./reducer-load-query-parameters"
import UserLoadedReducer from "./reducer-load-user"
import UserFriendsReducer from "./reducer-load-user-friends"
import UserInvitationsReducer from "./reducer-load-user-invitations"
import MainContentLevel3SelectedReducer from "./reducer-main-content-level-3-selected"
import MainContentPopulateReducer from "./reducer-main-content-populate"
import MainScrollReducer from "./reducer-main-scroll"
import MainContentVarsUpdatedReducer from "./reducer-maincontent-vars-updated"
import MenuSelectReducer from "./reducer-menu-selected"
import NextBookingReducer from "./reducer-next-booking"
import OverlayPopulateReducer from "./reducer-overlay-populate"
import PictureUploadReducer from "./reducer-picture-upload"
import ProfilPictureUploadReducer from "./reducer-profil-picture-upload"
import ShowFooter from "./reducer-show-footer"
import ToggleDesktopPreselectionLayerReducer from "./reducer-toggle-desktop-preselection-layer"
import UserSelectReducer from "./reducer-user-selected"
import UserSessionMeta from "./reducer-user-session-meta"
import UserReducer from "./reducer-users"
import Auth from "../auth/reducers/reducer"
import Cart from "../cart/reducer"
import sharedForm from "../forms/reducers"
import StepManagement from "../stepsManagement/reducers"

const allReducers = combineReducers({
    ajaxCalling: AjaxCallingReducer,
    appConfig: appConfigReducer,
    auth: Auth,
    authenticationOverlay: AuthenticationOverlayUpdatedReducer,
    cart: Cart,
    checkSkipStepEDF: CheckSkipEDF,
    confirmationOverlay: ConfirmationOverlayUpdatedReducer,
    dateValue: DateValue,
    domDetails: DomDetailsReducer,
    forms: sharedForm,
    globalLoader: GlobalLoader,
    hasArdoises: HasArdoisesReducer,
    hasUnpaidBills: HasUnpaidBills,
    level3Overlay: Level3OverlayToggledReducer,
    mainContent: MainContentPopulateReducer,
    mainContentLevel3: MainContentLevel3SelectedReducer,
    mainContentVars: MainContentVarsUpdatedReducer,
    mainScroll: MainScrollReducer,
    menuSelect: MenuSelectReducer,
    nextBooking: NextBookingReducer,
    overlayPopulate: OverlayPopulateReducer,
    pictureUpload: PictureUploadReducer,
    preselectionLayer: ToggleDesktopPreselectionLayerReducer,
    profilPictureUpload: ProfilPictureUploadReducer,
    queryParameters: QueryParametersLoadedReducer,
    showFooter: ShowFooter,
    stepManagement: StepManagement,
    userFriends: UserFriendsReducer,
    userInvitations: UserInvitationsReducer,
    userLoaded: UserLoadedReducer,
    userSelect: UserSelectReducer,
    userSessionMeta: UserSessionMeta,
    users: UserReducer,
})

export default allReducers
