import dayjs from "dayjs"
import { object } from "prop-types"
import { useNavigate } from "react-router-dom"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import Modal from "../../../../../components/Modal/Modal"
import useMediaPath from "../../../../../hooks/useMediaPath"
import useThemeHeader from "../../../../../hooks/useThemeHeader"
import ModalHandler from "../../../../../providers/Modal/ModalHandler.jsx"
import usePath from "../../../../../routes/services/usePath"
import { getTypeName } from "../../../tunnelReservation/function"
import { reportLocation } from "../api"

import "./ReportPopin.scss"

const ReportPopin = ModalHandler.create(({ location, visible, hide }) => {
    const mediaPath = useMediaPath()
    const navigate = useNavigate()
    const path = usePath()
    const themeHeader = useThemeHeader()

    const data = {
        centerId: location?.centerId,
        comments: `
            Report de de session d'abonnement sur facture\r
            Un client souhaite reporter cette réservation à la fin de son abonnement : https://app.ozmo.xelya.io/Ubfb/Details/Reservation/${location?.id}\r
            Pour reporter cette session :\r
            - Ouvrez le lien de la réservation d'abonnement ci-dessus\r
            - Dans un nouvel onglet, ouvrez le lien de l'abonnement\r
            - Agrandir l'objet lié "Sessions d'abonnement"\r
            - Trier la date de "Début" pour avoir la session la plus lointaine\r
            - S'il n'y a plus de réservation de type "Indisponible" non annulée, alors déplacer la session du client une semaine après la dernière session\r
            - S'il reste au moins une réservation de type "Indisponible", alors annuler la plus proche de la dernière session puis la remplacer par la session à reporter en cochant la case "Reporter le match"
        `,
        date: dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        serviceType: 2,
    }

    return (
        <Modal
            className="reportPopin"
            isOpen={visible}
            onClose={hide}
        >
            <header>Demande de report</header>
            <section className="content">
                <div className="locationDetails">
                    <div className="info">
                        <img src={mediaPath([ "/assets/icons/center-orange.svg", "/assets/images/padel/icons/center-red.svg" ])} />
                        <span>{location.centerName}</span>
                    </div>
                    <div className="info">
                        <img src={mediaPath([ "/assets/icons/calendar-orange.svg", "/assets/images/padel/icons/calendar-red.svg" ])} />
                        <span>{dayjs(location.start).format("DD/MM/YYYY")}</span>
                    </div>
                    <div className="info">
                        <img src={mediaPath([ "/assets/icons/heure-orange.svg", "/assets/images/padel/icons/heure-red.svg" ])} />
                        <span>{dayjs(location.start).format("HH:mm")}</span>
                    </div>
                    <div className="info">
                        <img src={mediaPath([ "/assets/icons/chrono-orange.svg", "/assets/images/padel/icons/chrono-red.svg" ])} />
                        <span>{location.duration}</span>
                    </div>
                    <div className="info">
                        <img src={mediaPath([ "/assets/icons/terrain-orange.svg", "/assets/images/padel/icons/terrain-red.svg" ])} />
                        <span>{getTypeName(location.resourceType, location.resourceTypeDisplay)}</span>
                    </div>
                </div>
                <div className="desc">
                    Souhaites-tu demander un report pour cette session d'abonnement ? <br />
                    La session sera déplacée à la fin de ton abonnement
                </div>
                <div className="buttonsWrapper">
                    <ButtonCta
                        text="Oui"
                        color="grey"
                        onClick={() =>
                            reportLocation(data, themeHeader)
                                .then(() => navigate(path("/confirmcommand"), {
                                    state: {
                                        bookingId: true,
                                        customLink: "",
                                        message: "Ta demande de report a été envoyée, ta session sera déplacée à la fin de ton abonnement",
                                        noInvit: true,
                                        noThanks: true,
                                        success: true,
                                        title: "Demande de report envoyée !",
                                        tunnelType: "BOOKING",
                                        underThanks: true,
                                    },
                                })).finally(hide)
                        }
                        isCta2Alt
                    />
                    <ButtonCta
                        text="Non"
                        color="grey"
                        onClick={hide}
                        isCta2Alt
                    />
                </div>
            </section>
        </Modal>
    )
})

ReportPopin.displayName = "ReportPopin"
ReportPopin.propTypes = {
    location: object,
}

export default ReportPopin
