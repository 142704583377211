import classNames from "classnames"
import { bool, string } from "prop-types"

import useMediaPath from "../../hooks/useMediaPath"

import "./ProgressItem.scss"

export default function ProgressItem({ label, disabled, simple }) {
    const mediaPath = useMediaPath()
    return (
        <div className={classNames("progressItem", { disabled, simple })}>
            <div className="label">
                <img src={disabled ? mediaPath([ "/assets/icons/checked-grey.svg", "/assets/images/padel/icons/checked-grey.svg" ]) : mediaPath([ "/assets/icons/checked-green.svg", "/assets/images/padel/icons/checked-red.svg" ])}/>
                <div className="text">{label}</div>
            </div>
            {!simple && <div className="bar"></div>}
        </div>
    )
}

ProgressItem.propTypes = {
    disabled: bool,
    label: string,
    simple: bool,
}
