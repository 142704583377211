import PropTypes from "prop-types"

import "./TeamVisuals.scss"

export const TeamVisuals = ({ team }) => {
    return (
        <div className={"team-visuals"}>
            <img src={team.cover} alt={team?.name} />
        </div>
    )
}

TeamVisuals.propTypes = {
    team: PropTypes.object,
}
