import { string } from "prop-types"
import { useSelector } from "react-redux"

import FooterHamburger from "./footerHamburger/footerHamburger"
import useNotLogFooter from "../../../hooks/useNotLogFooter"
import { useIsWebview, useTheme } from "../../ThemeContext/ThemeContext"

import "./footer.scss"

function Footer({
    customFooterClass,
}) {
    const theme = useTheme()
    const userOpen = useSelector(state => state.userSelect.open)
    const menuOpen = useSelector(state => state.menuSelect.open)
    const userLoaded = useSelector(state => state.userLoaded)
    const auth = userLoaded?.id
    const isNotLogFooter = useNotLogFooter()
    const isWebview = useIsWebview()

    function getFooterOpenedClass() {
        if (userOpen) {
            return "overlayProfilOpened"
        } else if (menuOpen) {
            return "overlayMenuOpened"
        }
    }

    function customMobileFooterWhenNotLoged() {
        return (
            <div className="authMobileFooter show-st">
                {
                    theme === "theme-soccer" && (
                        <>
                            <div>
                                <a href="https://www.urbansoccer.fr" target="_blank" rel="noreferrer">
                                    <img className="urbansoccer-logo" src="/assets/images/Logo-US-white.svg"/>
                                </a>
                            </div>
                            <div className="footer-side-div">
                                <a
                                    href="https://www.urbansoccer.fr/contact/"
                                    target="_blank"
                                    className="contact-link"
                                    rel="noreferrer"
                                >
                                    <span>Contact</span>
                                </a>
                                <div>
                                    <a href="https://www.urbansoccer.fr/mentions-legales/" target="_blank" rel="noreferrer">
                                        <span>Mentions légales</span>
                                    </a>

                                    <span className="separator">-</span>

                                    <a href="https://www.urbansoccer.fr/politique-de-confidentialite/" target="_blank" rel="noreferrer">
                                        <span>Politique de données personnelles</span>
                                    </a>
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    theme === "theme-padel" && (
                        <>
                            <div>
                                <a href="https://www.urbanpadel.fr/" target="_blank" rel="noreferrer">
                                    <img className="urbansoccer-logo" src="/assets/images/padel/logo/logo-padel-footer.svg" />
                                </a>
                            </div>
                            <div className="footer-side-div">
                                <a href="https://www.urbanpadel.fr/contact/" target="_blank"
                                    className="contact-link" rel="noreferrer">
                                    <span>Contact</span>
                                </a>
                                <div>
                                    <a href="https://www.urbanpadel.fr/mentions-legales/" target="_blank" rel="noreferrer">
                                        <span>Mentions légales</span>
                                    </a>

                                    <span className="separator">-</span>

                                    <a href="https://www.urbanpadel.fr/politique-de-confidentialite/" target="_blank" rel="noreferrer">
                                        <span>Politique de données personnelles</span>
                                    </a>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        )
    }

    return (
        <div
            id="footer" 
            className={
                customFooterClass ? 
                    customFooterClass + " " + getFooterOpenedClass()
                    :
                    getFooterOpenedClass()
            }
        >
            {isNotLogFooter ?
                customMobileFooterWhenNotLoged()
                :
                (
                    auth && (
                        <>
                            <div className="show-st footer-mobile-container">
                                <FooterHamburger footerType="reserver" />
                                <FooterHamburger footerType="switch" />
                                <FooterHamburger footerType="menu" />
                            </div>
                        </>
                    )
                )
            }
            <div className="hide-st desktopFooter">
                {
                    theme === "theme-soccer" && (
                        <>
                            <div>
                                <a href="https://www.urbansoccer.fr" target="_blank" rel="noreferrer">
                                    <img className="urbansoccer-logo" src="/assets/images/Logo-US-white.svg"/>
                                </a>
                            </div>
                            <div className="footer-side-div">
                                <a href="https://www.urbansoccer.fr/mentions-legales/" target="_blank" rel="noreferrer">
                                    <span>Mentions légales</span>
                                </a>

                                <a href="https://www.urbansoccer.fr/politique-de-confidentialite/" target="_blank" rel="noreferrer">
                                    <span>Politique de données personnelles</span>
                                </a>

                                <a href="https://www.urbansoccer.fr/contact/" target="_blank" rel="noreferrer">
                                    <span>Contact</span>
                                </a>
                                {!isWebview && (<a
                                    href=""
                                    data-testid="cookie"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        window.openAxeptioCookies()
                                    }}
                                >
                                    Cookies
                                </a>)}
                            </div>
                        </>
                    )
                }
                {
                    theme === "theme-padel" && (
                        <>
                            <div>
                                <a href="https://www.urbanpadel.fr/" target="_blank" rel="noreferrer">
                                    <img className="urbansoccer-logo" src="/assets/images/padel/logo/logo-padel-footer.svg"/>
                                </a>
                            </div>
                            <div className="footer-side-div">
                                <a href="https://www.urbanpadel.fr/mentions-legales/" target="_blank" rel="noreferrer">
                                    <span>Mentions légales</span>
                                </a>

                                <a href="https://www.urbanpadel.fr/politique-de-confidentialite/" target="_blank" rel="noreferrer">
                                    <span>Politique de données personnelles</span>
                                </a>

                                <a href="https://www.urbanpadel.fr/contact/" target="_blank" rel="noreferrer">
                                    <span>Contact</span>
                                </a>
                                {!isWebview && (<a
                                    href=""
                                    data-testid="cookie"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        window.openAxeptioCookies()
                                    }}
                                >
                                    Cookies
                                </a>)}
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}

Footer.propTypes = {
    customFooterClass: string,
}

export default Footer
