import classNames from "classnames"
import PropTypes from "prop-types"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Outlet, matchPath, useLocation, useNavigate, useParams } from "react-router-dom"

import useThemeHeader from "../../../hooks/useThemeHeader.js"
import { getLeagueAds, getUserLeagueTeams } from "../../../pages/league/api/myLeague.js"
import { resolveTeamPJT } from "../../../pages/league/service/service.js"
import { SELECTED_LEAGUE_TEAM_KEY, useLeagueStore } from "../../../pages/league/store/useLeagueStore.js"
import { LeaguePath } from "../../../routes/ApplicationPaths/LeaguePaths.js"
import Preloader from "../../loaders/preloader/preloader.jsx"
import TabSwitcher from "../../TabSwitcher/TabSwitcher.jsx"
import { useTheme } from "../../ThemeContext/ThemeContext.jsx"
import { LeagueHeader } from "../LeagueHeader/LeagueHeader.jsx"
import { TeamSwitcher } from "../TeamSwitcher/TeamSwitcher.jsx"

import "./LeagueLayout.scss"

export const LeagueLayout = ({ title, withMainSwitcher, withHeading = true }) => {
    const [ isLoading, setIsLoading ] = useState(false)
    const userLoaded = useSelector(state => state.userLoaded)
    const theme = useTheme()
    const themeHeader = useThemeHeader()

    const {
        selectedLeagueTeam,
        setSelectedLeagueTeam,
        setLeagueTeams,
        setSelectedTeamData,
        leagueTeams,
        setAds,
        ads,
    } = useLeagueStore()

    const { teamId  } = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (selectedLeagueTeam?.id && location.pathname === LeaguePath.leagueInfosBase) {
            selectTeam(selectedLeagueTeam)
        } else  /* istanbul ignore next */if (!teamId && !selectedLeagueTeam?.id && leagueTeams.length) {
            selectTeam(leagueTeams[0])
        }
    }, [ location ])

    useEffect(() => {
        if (leagueTeams.length === 0 || (!selectedLeagueTeam?.id && !!teamId)) {
            setIsLoading(true)
            getUserLeagueTeams().then((teams) => {
                if (teams.length) {
                    setLeagueTeams(teams)
                    if (teamId) {
                        const foundTeam = teams.find((team) => team.id === parseInt(teamId))
                        if (foundTeam) {
                            selectTeam(foundTeam)
                        } else {
                            backToLeagueHome(teams[0])
                        }
                    } else {
                        const localStorageTeamId = localStorage.getItem(SELECTED_LEAGUE_TEAM_KEY)
                        selectTeam(localStorageTeamId ? teams?.find(
                            team => team?.id === parseInt(localStorageTeamId),
                        ) ?? teams[0] : teams[0])
                    }
                } else {
                    navigate(LeaguePath.listingLeague)
                }
            }).finally(() => setIsLoading(false))
        }
        if (!ads?.length) {
            getLeagueAds(themeHeader - 1).then(setAds)
        }
    }, [ userLoaded.contactId, selectedLeagueTeam, location ])

    const selectTeam = (team) => {
        if (team?.id) {
            setSelectedLeagueTeam({
                ...team,
                shareLink: resolveTeamPJT(selectedLeagueTeam, theme, userLoaded),
            })

            setSelectedTeamData({
                id: team.id,
                leagueId: team.leagueId,
            })
            setIsLoading(false)
        }
    }

    const backToLeagueHome = (fallbackTeam) => {
        navigate(LeaguePath.toLeagueInfosHome(fallbackTeam?.id))
    }

    useEffect(() => {
        if (selectedLeagueTeam?.id
                && selectedLeagueTeam?.id !== parseInt(teamId)
        ) {
            selectTeam(selectedLeagueTeam)
            if (!teamId && withMainSwitcher) {
                navigate(LeaguePath.toLeagueInfosHome(selectedLeagueTeam?.id))
            }
        }
    }, [ selectedLeagueTeam?.id, location ])

    const teamSwitcher = useMemo(() => {
        return <TeamSwitcher/>
    }, [ selectedLeagueTeam?.id ])

    const tabs = useMemo(() => {
        return [
            {
                name: "Général",
                route: LeaguePath.toLeagueInfosHome(selectedLeagueTeam?.id),
            },
            {
                name: "Classement",
                route: LeaguePath.toLeagueInfosRanking(selectedLeagueTeam?.id),
            },
            {
                name: "Calendrier",
                route: LeaguePath.toLeagueInfosCalendar(selectedLeagueTeam?.id),
            },
        ]
    }, [ selectedLeagueTeam?.id ])

    const isExtendedLayout = location.pathname.includes(LeaguePath.leagueInfosHome)
    || matchPath(LeaguePath.leagueTeamDetails, location.pathname)
    
    return (
        <>
            {withHeading ? (
                <>
                    <LeagueHeader
                        title={title}
                    >
                        {teamSwitcher}
                    </LeagueHeader>
                    {isLoading ? (
                        <Preloader fixed/>
                    ) : (
                        <div className={"c-row justify-center league-layout"}>
                            <div className={classNames("c-col c-col--10 c-col--sm--12 no-padding", {
                                "c-col--10": !isExtendedLayout,
                                "c-col--12": isExtendedLayout,
                            })}>
                                {withMainSwitcher && (
                                    <TabSwitcher tabs={tabs} />
                                )}
                                <Outlet/>
                            </div>
                        </div>
                    )}
                </>
            ) : isLoading ? (
                <Preloader fixed/>
            ) : (
                <Outlet/>
            )}
        </>
    )
}

LeagueLayout.propTypes = {
    title: PropTypes.string.isRequired,
    withHeading: PropTypes.bool,
    withMainSwitcher: PropTypes.bool,
}
