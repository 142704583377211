import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import useUrbanLanguage from "../../../../hooks/urbanLanguage/useUrbanLanguage"
import useMediaPath from "../../../../hooks/useMediaPath"
import useThemeFeatures from "../../../../hooks/useThemeFeatures"
import { useBookingStore } from "../../../../pages/reservation/tunnelReservation/NewBookingTunnel/services/store.js"
import usePath from "../../../../routes/services/usePath"
import {
    loadUrlQueryParameters,
    selectHamburgerMenu,
    selectUserProfilMenu,
} from "../../../../store/actions/scaffold"
import { useTheme, useThemeUpdate } from "../../../ThemeContext/ThemeContext"

import "./footerHamburger.scss"

function FooterHamburger({ footerType }) {
    const { clearStore: clearBookingStore } = useBookingStore()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const mediaPath = useMediaPath()
    const themeFeatures = useThemeFeatures()
    const theme = useTheme()
    const setTheme = useThemeUpdate()
    const { tu } = useUrbanLanguage()
    const path = usePath()

    const menuOpen = useSelector((state) => state.menuSelect.open)
    const userOpen = useSelector((state) => state.userSelect.open)
    const queryParameters = useSelector((state) => state.queryParameters)

    const [ openedMenu, setOpenedMenu ] = useState(null)

    useEffect(() => {
        if (footerType === "menu") {
            setOpenedMenu(menuOpen)
        }
    }, [ menuOpen, footerType, userOpen ])

    const getCloseIconClass = () => {
        const classes = {
            menu: "footerHamburgerMenuIconClose",
            reserver: "footerHamburgerReserverIconClose",
            switch: "footerHamburgerSwitchIconClose",
        }
        return classes[footerType] || ""
    }

    const updateTheme = (newTheme) => {
        if (queryParameters && Object.keys(queryParameters).length) {
            dispatch(loadUrlQueryParameters(null))
        }
        setTheme(newTheme)
        navigate("/")
    }

    const resetBooking = () => {
        if (![ "/reserver", "/padel/reserver" ].includes(location.pathname)) {
            clearBookingStore()
        }
    }

    const selectButtonImage = () => {
        if (openedMenu) {
            return mediaPath([
                "/assets/icons/icons-graphics-cross.svg",
                "/assets/images/padel/icons/icon-close.svg",
            ])
        }
        const images = {
            menu: "/assets/icons/menu-closed.svg",
            reserver: mediaPath([
                "/assets/icons/reserverTerrainFooter.svg",
                "/assets/images/padel/icons/icon-calendar.svg",
            ]),
            switch: mediaPath([
                "/assets/images/padel/icons/padel-footer-switch-green.svg",
                "/assets/icons/soccer-footer-switch.svg",
            ]),
        }
        return images[footerType] || ""
    }

    const getLabelName = () => {
        const labels = {
            menu: <p>MENU</p>,
            reserver: <p>{tu("mobile.menu.reserver")}</p>,
            switch: themeFeatures([
                <p key="padel">URBAN<strong>PADEL</strong></p>,
                <p key="soccer">URBAN<strong>SOCCER</strong></p>,
            ]),
        }
        return labels[footerType] || ""
    }

    const handleClick = () => {
        const actions = {
            menu: () => {
                dispatch(selectUserProfilMenu(false))
                dispatch(selectHamburgerMenu(!menuOpen))
                document.getElementById("root").classList.remove("appOverlayProfilOpened")
            },
            reserver: () => {
                resetBooking()
                dispatch(selectUserProfilMenu(false))
                dispatch(selectHamburgerMenu(false))
                navigate(path("/reserver"))
                document.getElementById("root").classList.remove("appOverlayProfilOpened")
            },
            switch: () => {
                const newTheme = theme === "theme-soccer" ? "theme-padel" : "theme-soccer"
                updateTheme(newTheme)
                dispatch(selectHamburgerMenu(false))
            },
        }

        actions[footerType]?.()
    }

    return (
        <div
            id={`footer-hamburger-${footerType}`}
            className="footer-hamburger"
            onClick={handleClick}
        >
            <div className={"footer-hamburger-image"}>
                <img
                    src={selectButtonImage()}
                    className={openedMenu ? "footerHamburgerIconOpen" : getCloseIconClass()}
                />
            </div>
            <div className={openedMenu ? "label-name label-name-open" : "label-name"}>
                {getLabelName()}
            </div>
        </div>
    )
}

FooterHamburger.propTypes = {
    footerType: PropTypes.string.isRequired,
}

export default FooterHamburger
