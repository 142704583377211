import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock-upgrade"
import classNames from "classnames"
import { detect } from "detect-browser"
import ProgressBar from "progressbar.js"
import { arrayOf, bool, func, node, number, oneOfType, string } from "prop-types"
import { forwardRef, useEffect, useState } from "react"
import Resizer from "react-image-file-resizer"

import ImageCrop from "./ImageCrop/ImageCrop"
import useThemeFeatures from "../../../hooks/useThemeFeatures"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import Preloader from "../../loaders/preloader/preloader"
import Modal from "../../Modal/Modal"

import "./FileInput.scss"

const FileInput = forwardRef(
    (props, ref) => {
        const {
            id,
            onFileUploadPromiseCallback,
            label,
            initButtonUploadText,
            initFileName,
            deleteAttachment,
            acceptType,
            imageCropWidth,
            imageCropHeight,
            outputWidth,
            outputHeight,
            subLabel,
            hiddenInput,
            children,
            maxSize,
            customCls,
            customModalTitle,
        } = props
        
        const [ isUploading, setIsUploading ] = useState(false)
        const [ buttonUploadText, setButtonUploadText ] = useState(initButtonUploadText)
        const [ fileName, setFileName ] = useState(initFileName)
        const [ errorMsg, setErrorMsg ] = useState(null)
        const [ bar, setBar ] = useState(null)
        const [ miniature, setMiniature ] = useState(null)

        const browser = detect()
        const themeFeatures = useThemeFeatures()

        useEffect(
            () => {
                const progressBarID = "#progress-bar--" + id
                /* istanbul ignore else */
                if (bar === null && document.querySelector(progressBarID)) {
                    setBar(new ProgressBar.Line(progressBarID, { color: themeFeatures([ "#ff7832", "#B4FFAA" ]), easing: "easeInOut" }))
                }
            }, [],
        )

        useEffect(
            () => {
                setFileName(initFileName)
                if (initFileName && initFileName.includes("http")) {
                    setMiniature(initFileName)
                }
            }, [ initFileName ],
        )

        function readFile(file) {
            return new Promise(
                resolve => {
                    const reader = new FileReader()
                    reader.addEventListener("load", () => resolve(reader.result), false)
                    reader.readAsDataURL(file)
                },
            )
        }

        const resizeFile = (file) => new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1000,
                1000,
                "png",
                80,
                0,
                (uri) => {
                    resolve(uri)
                },
                "blob",
            )
        })

        async function onFileUpload(event) {
            let target = event.target
            event.persist()
            /* istanbul ignore else */
            if (target.files && target.files.length > 0) {
                const resizedFile = await resizeFile(target.files[0])
                let fileUrl = await readFile(resizedFile)
                let sizeOK = checkFileSize(resizedFile.size / 1024 / 1024) // in MiB)
                checkImageSize(fileUrl)
                setButtonUploadText(target.files[0].name)
                
                if (
                    (
                        imageCropWidth && imageCropHeight &&
                        outputWidth && outputHeight
                    )
                    || !sizeOK
                ) {
                    const modalProps = {
                        cancelUpload: cancelUpload,
                        customCls,
                        customModalTitle,
                        emptyFileInput: emptyFileInput,
                        errorMsg,
                        file: fileUrl,
                        handleCroppedImage : handleCroppedImage,
                        imageCropHeight,
                        imageCropWidth,
                        label,
                        outputHeight,
                        outputWidth,
                    }

                    await ModalHandler.show(FileInputModal, modalProps)
                } else {
                    handleCallback(fileUrl)
                }
            }
        }

        /* istanbul ignore next */
        function handleCallback(outputImage) {
            if (onFileUploadPromiseCallback) {
                if (
                    maxSize && !checkFileSize(
                        outputImage.size / 1024 / 1024,
                    )
                ) {
                    setErrorMsg(
                        "L'image rognée fait " + (outputImage.size / 1024 / 1024).toFixed(2) + "Mo, ce qui est toujours supérieur à " + 
                        maxSize + "Mo",
                    )
                } else {
                    ModalHandler.hide(FileInputModal)
                    clearAllBodyScrollLocks()
                    setIsUploading(true)
                    onFileUploadPromiseCallback(outputImage, bar).then(
                        () => {
                            setIsUploading(false)
                        },
                    )
                }
            }
        }

        function checkImageSize(url) {
            var img = new Image()
            const maxSize = 1000
            img.src = url
            img.onload = async (e) => {
                let that = e.target
                if (browser && (
                    browser.name === "firefox"
                    || browser.name === "fxios"
                    || browser.name === "ios"
                    || browser.name === "ios-webview")) {
                    if (
                        that.naturalWidth < outputWidth ||
                        that.naturalHeight < outputHeight ||
                        that.naturalWidth > maxSize ||
                        that.naturalHeight > maxSize
                    ) {
                        setErrorMsg(
                            "L'image fournie doit être comprise entre " + 
                            outputWidth + " et " + maxSize + " px.",
                        )
                        setButtonUploadText(initButtonUploadText)
                    } else {
                        setErrorMsg(null)
                    }
                } else {
                    setErrorMsg(null)
                }
                    
            }
        }

        /**
         * Check the file size
         * @param {*} size Taille en MiB
         * @returns true if ok, else false
         */
        function checkFileSize(size) {
            if (
                maxSize &&
                size > maxSize
            ) {
                setErrorMsg(
                    "L'image fournie doit faire moins de " + 
                    maxSize + "Mo",
                )
                return false
            } else {
                setErrorMsg(null)
                return true
            }
        }

        function cancelUpload(e) {
            e.preventDefault()
            clearAllBodyScrollLocks()
            e.stopPropagation()
            
            ref.current.value = ""
            
            // Reset default
            setErrorMsg(null)
            setFileName(null)
            setMiniature(null)
            setButtonUploadText(initButtonUploadText)
            bar?.set?.(0)
            /* istanbul ignore next */
            if (deleteAttachment) {
                deleteAttachment()
            }
            ModalHandler.hide(FileInputModal)
        }

        /* istanbul ignore next */
        function handleCroppedImage(image) {
            setMiniature(URL.createObjectURL(image))
            setErrorMsg(null)

            handleCallback(image)
        }

        /* istanbul ignore next */
        function emptyFileInput() {
            if (
                !(miniature && miniature.includes("blob"))
            ) {
                ref.current.value = ""
                setErrorMsg(null)
                setFileName(null)
                setMiniature(null)
                setButtonUploadText(initButtonUploadText)
            }
        }

        return (
            <>
                { hiddenInput ?
                    (<div className="input-file--hidden">
                        <label
                            htmlFor={"fileUploadInput--" + id}
                        >
                            {children} {label}
                        </label>
                        <div className="bottom" id={"progress-bar--" + id}></div>
                        <input
                            data-testid="file-input"
                            type="file" 
                            accept={acceptType ?
                                acceptType + "/*"
                                :
                                undefined
                            }
                            id={"fileUploadInput--" + id}
                            placeholder=""
                            disabled={isUploading}
                            onClick={
                                (initFileName &&
                                        fileName === initFileName
                                ) ||
                                    (fileName) ||
                                    (!isUploading && initButtonUploadText !== buttonUploadText) ? 
                                    cancelUpload 
                                    : 
                                    undefined
                            }
                            onChange={onFileUpload}
                            ref={ ref }
                        />
                        { isUploading &&
                                /* istanbul ignore next */
                                <Preloader fixed={true}/>
                        }
                    </div>)
                    :
                    (<> 
                        <div className="input-file">
                            <div className="top">
                                <div className="left-part">
                                    <label
                                        htmlFor={"fileUploadInput--" + id}
                                    >
                                        {label}
                                    </label>
                                    <button disabled={isUploading} className="btn">
                                        {
                                            isUploading ? 
                                                /* istanbul ignore next */
                                                "Envoi de l'image..."
                                                :
                                                fileName ?
                                                    fileName
                                                    :
                                                    buttonUploadText
                                        }
                                    </button>
                                </div>
                                
                                <div className="right-part">
                                    {miniature &&
                                            <img className="miniature" src={miniature}/>
                                    }
                                    { isUploading ?
                                        /* istanbul ignore next */
                                        <Preloader />
                                        : (
                                            (initFileName && fileName === initFileName)
                                            || (fileName && /* istanbul ignore next */fileName !== "")
                                            || (!isUploading && initButtonUploadText !== buttonUploadText)
                                        ) ? (
                                                <img
                                                    src="/assets/icons/cross-orange.svg"
                                                    onClick={(e) => cancelUpload(e)}
                                                    data-testid="cancel-upload"
                                                />
                                            ) :
                                            <img src="/assets/images/upload.svg" className="defaultCursor" />
                                    }
                                </div>
                                
                                <input 
                                    data-testid="file-input"
                                    type="file" 
                                    accept={acceptType ?
                                        acceptType + "/*"
                                        :
                                        undefined
                                    }
                                    id={"fileUploadInput--" + id}
                                    placeholder=""
                                    disabled={isUploading}
                                    onClick={
                                        (initFileName &&
                                                fileName === initFileName
                                        ) ||
                                            (fileName !== null &&
                                            fileName !== "") ||
                                            /* istanbul ignore next */
                                            (!isUploading && initButtonUploadText !== buttonUploadText) ? 
                                            cancelUpload 
                                            : 
                                            /* istanbul ignore next */ undefined
                                    }
                                    onChange={onFileUpload}
                                    ref={ ref }
                                />
                            </div>
                            <div className="bottom" id={"progress-bar--" + id}></div>
                        </div>
                        <div className="subLabel-file-input">
                            {subLabel}
                        </div>
                    </>)
                }
            </>
        )
    },
)
/* istanbul ignore next */
const FileInputModal = ModalHandler.create((
    {
        visible,
        hide,
        label,
        errorMsg,
        file,
        imageCropWidth,
        imageCropHeight,
        outputWidth,
        outputHeight,
        customCls,
        emptyFileInput,
        cancelUpload,
        handleCroppedImage,
        customModalTitle,
    }) => {
    return (
        <Modal
            beforeClose={emptyFileInput}
            afterOpen={() => disableBodyScroll(document.querySelector(".input-file-modal"))}
            isOpen={visible}
            onClose={/* istanbul ignore next */() => {
                clearAllBodyScrollLocks()
                hide()
            }}
            afterClose={clearAllBodyScrollLocks}
            className={classNames("input-file-modal",{
                customCls,
            })}
        >
            <header>{errorMsg ? "ERREUR !" : customModalTitle || label}</header>
            { errorMsg ?
                <span className="errorMsg">{errorMsg}</span>
                : /* istanbul ignore next */(
                    file &&
                imageCropWidth && imageCropHeight &&
                outputWidth && outputHeight
                ) && (
                    <ImageCrop
                        src={file}
                        cropSizeWidth={imageCropWidth}
                        cropSizeHeight={imageCropHeight}
                        outputWidth={outputWidth}
                        outputHeight={outputHeight}
                        fixed={true}
                        onCloseCallback={cancelUpload}
                        onValidationCallback={handleCroppedImage}
                    />
                )}
        </Modal>
    )
})

FileInput.propTypes = {
    acceptType: string,
    children: oneOfType([ string, node, arrayOf(node) ]),
    customCls: oneOfType([ bool, string ]),
    customModalTitle: string,
    deleteAttachment: func,
    hiddenInput: bool,
    id: oneOfType([ number, string ]),
    imageCropHeight: number,
    imageCropWidth: number,
    initButtonUploadText: string,
    initFileName: string,
    label: oneOfType([ string, node, arrayOf(node) ]),
    maxSize: number,
    onFileUploadPromiseCallback: func,
    outputHeight: number,
    outputWidth: number,
    subLabel: oneOfType([ string, node, arrayOf(node) ]),
}

// https://github.com/facebook/react/issues/16653#issuecomment-564423981
FileInput.displayName = "FileInput"

export default FileInput
