import { bool, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import IconPadel from "../../../components/Icons/Notifications/assets/IconPadel"
import IconSoccer from "../../../components/Icons/Notifications/assets/IconSoccer"
import useMediaPath from "../../../hooks/useMediaPath"
import useThemeFeatures from "../../../hooks/useThemeFeatures"
import { selectHamburgerMenu, selectUserProfilMenu } from "../../../store/actions/scaffold"
import { clearAllForms } from "../../../store/forms/actions"

import "./header.scss"

function Header({ customId /*noSwitchEnv*/ }) {
    const [ openedMenu, setOpenedMenu ] = useState(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userOpen = useSelector(state => state.userSelect.open)

    const mediaPath = useMediaPath()

    const themeFeatures = useThemeFeatures()
    const hasArdoises = useSelector(state => state.hasArdoises)
    const hasUnpaidBills = useSelector(state => state.hasUnpaidBills)

    useEffect(() => {
        setOpenedMenu(userOpen)
    }, [ userOpen ])

    function handleClick() {
        dispatch(selectUserProfilMenu(!userOpen))
        if (!userOpen) {
            document.getElementById("root").classList.add("appOverlayProfilOpened")
        } else {
            document.getElementById("root").classList.remove("appOverlayProfilOpened")
        }

        dispatch(selectHamburgerMenu(false))
    }

    return (
        <div id={customId ? customId : "header"} className="sub-container">
            <div id="header-logo" className="sub-container">
                <a
                    id='cy-home-btn--mobile'
                    onClick={
                        () => {
                            dispatch(clearAllForms())
                            navigate("/")
                        }
                    }
                >
                    <img src={mediaPath([ "/assets/images/logo-urbansoccer-full.svg", "/assets/images/padel/logo/padel-white.svg" ])}/>
                </a>
            </div>
            <div>
                <a onClick={handleClick} className={"flex profile-button"}>
                    <img src={openedMenu ?
                        mediaPath([
                            "/assets/icons/icons-graphics-cross.svg",
                            "/assets/images/padel/icons/icon-close.svg",
                        ])
                        :
                        mediaPath([ "/assets/icons/user_profil_soccer.svg", "/assets/icons/user_profil_padel.svg" ])}/>
                    {(hasArdoises || hasUnpaidBills) && !openedMenu && themeFeatures([
                        <IconSoccer
                            key="mobile-ardoise-soccer"
                            backgroundColor={"#ff7832"}
                            textColor={"#ffffff"}
                        />,
                        <IconPadel
                            key="mobile-ardoise-padel"
                            backgroundColor={"#f5233c"}
                            textColor={"#ffffff"}
                        />,
                    ])}
                </a>
            </div>
        </div>
    )
}
Header.propTypes = {
    customId: oneOfType([ bool, string ]),
    noSwitchEnv: bool,
}

export default Header
