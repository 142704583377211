import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import ToggleSetting from "./components/toggleSetting"
import Preloader from "../../../../../components/loaders/preloader/preloader"
import { getNotificationSettings, updateNotificationSettings } from "../../../../../globalAPI/api"

import "./notificationsSettings.scss"

function NotificationsSettings() {
    const [ notifSettings, setNotifSettings ] = useState([])
    const [ ajaxLoading, setAjaxLoading ] = useState(true)
    const userLoaded = useSelector(state => state.userLoaded)
    useEffect(() => {
        getNotificationSettings(userLoaded.id).then(
            (notifs) => {
                setNotifSettings(notifs.data.data)
                setAjaxLoading(false)
            },
        )
    }, [])

    function updateNotifSettings(newId) {
        setAjaxLoading(true)
        let notifSettingsIds = notifSettings.map(
            (setting) => {
                if (setting.id === newId) {
                    return setting.enabled ? "" : setting.id
                }
                if (setting.enabled) {
                    return setting.id
                }
                return ""
            },
        )
        notifSettingsIds = notifSettingsIds.filter(x => x !== "")
        updateNotificationSettings(userLoaded.id, notifSettingsIds).then(
            (notifs) => {
                setNotifSettings(notifs.data.data)
                setAjaxLoading(false)
            },
        )
    }

    function alphabeticSort(a, b) {
        return a.label === b.label ?
            0
            :
            a.label < b.label ?
                -1
                :
                1
    }

    return (
        <div className="notifications">
            { ajaxLoading &&
                <Preloader fixed={true}></Preloader>
            }
            <div className="page-content-label">Mes notifications</div>
            { 
                notifSettings.sort(alphabeticSort).map(
                    (setting) => (
                        <ToggleSetting 
                            { ...setting }
                            key={ setting.id } 
                            callback={ 
                                () => {
                                    updateNotifSettings(setting.id)
                                }
                            } 
                        />
                    ),
                )
            }
        </div>
    )
}

export default NotificationsSettings
