import classNames from "classnames"
import { useEffect , useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import ButtonCta from "../../components/Buttons/button/button-cta"
import PageHeader from "../../components/layout/pageHeader/PageHeader"
import Preloader from "../../components/loaders/preloader/preloader"
import Modal from "../../components/Modal/Modal"
import MainInvitationsPopIn from "../../components/popin/invitations/MainInvitationsPopIn"
import PlayerLine from "../../components/reservation/PlayerLine/PlayerLine"
import { useTheme } from "../../components/ThemeContext/ThemeContext"
import { createFriendshipInvitationFromUsername, deleteFriendshipRelation, generateFriendshipRawInvitationTokenUrl, handleFriendshipToken, listUserFriendsAndInvitations, refuseFriendshipInvitationToken } from "../../globalAPI/api"
import useCookies from "../../hooks/useCookies"
import useIsMobile from "../../hooks/useIsMobile"
import useMediaPath from "../../hooks/useMediaPath"
import useResetScroll from "../../hooks/useResetScroll"
import useThemeHeader from "../../hooks/useThemeHeader"
import ModalHandler from "../../providers/Modal/ModalHandler"
import alphabeticalPlayerSort from "../../services/Sorting/alphabeticalPlayerSort"
import UserAgentHandler from "../../services/UserAgentHandler"

import "./FriendsInvitation.scss"

const FriendsInvitation = () => {
    //reset scroll
    useResetScroll()
    const mediaPath = useMediaPath()
    const themeHeader = useThemeHeader()
    const isMobile = useIsMobile()
    const { isAppV2 } = useCookies()

    //hook declaration
    const [ friends, setFriends ] = useState([])
    const [ isLoading, setIsLoading ] = useState(true)
    const [ shareLink, setShareLink ] = useState(null)
    const [ receivedInvitations, setReceivedInvitations ] = useState([])
    const [ selectedFriend, setSelectedFriend ] = useState(null)
    const [ refresh, setRefresh ] = useState(false)
    const [ searchParams, setSearchParams ] = useSearchParams()
    const theme = useTheme()

    //store import
    const appConfig = useSelector(state => state.appConfig)
    const userLoaded = useSelector(state => state.userLoaded)

    const removeFriend = () => {
        setIsLoading(true)
        ModalHandler.hide(RemoveFriendCOnfirmationPopIn)
        deleteFriendshipRelation(selectedFriend.ozmoSystemId).then(
            () => {
                setIsLoading(false)
                setRefresh(!refresh)
            },
        )
    }

    const acceptFriendship = (invitation) => {
        setIsLoading(true)
        let token = invitation.token
        handleFriendshipToken(token).then(() => {
            setIsLoading(false)
            setRefresh(!refresh)
        })
    }

    const refuseFriendship = (invitation) => {
        setIsLoading(true)
        let token = invitation.token
        refuseFriendshipInvitationToken(token).then(() => {
            setIsLoading(false)
            setRefresh(!refresh)
        })
    }

    const bottomPart = () => (
        <div className="friendButtonWrapper">
            <ButtonCta
                icon={mediaPath([ "/assets/icons/removePlayer.svg", "" ])}
                text="Retirer de mes joueurs"
                onClick={(e) => {
                    e.stopPropagation()
                    showRemoveFriendModal(selectedFriend)
                }}
            />
        </div>
    )

    const generateInvitMessage = (link) => {
        if (link) {
            return encodeURI(shareLink)
        } else {
            return encodeURIComponent(`Rejoins moi sur MyUrban ! ${shareLink.replace("@", "%40")}`)
        }
    }

    const getFriendsId = () => {
        const friendsId = []
        friends.forEach(friend => friendsId.push(friend.ozmoSystemId))
        return friendsId
    }

    const showAddPlayerModal = (link = null) => {
        ModalHandler.show(MainInvitationsPopIn, {
            linkToSend: link ? () => {
                return link
            } : () => {
                return generateInvitMessage(true)
            },
            messageToSend: generateInvitMessage,
            myUrbanCallback: (networkUser) => {
                networkUser.forEach(user => {
                    const haveUserName = user.username
                    if (haveUserName) {
                        createFriendshipInvitationFromUsername(haveUserName, null, themeHeader)
                    }
                })
            },
            selectedMates: getFriendsId(),
            tunnelType: "PROFIL",
        }, { keepPreviousProps: true })
    }

    const showRemoveFriendModal = (selectedFriend) => {
        ModalHandler.show(RemoveFriendCOnfirmationPopIn, {
            callback: removeFriend,
            selectedFriend,
        })
    }

    //variable declaration
    const rightPart = (invitation) => (
        <div className="invitation-response__btn-holder">
            <ButtonCta
                noSkew
                noShadow
                className={classNames("refuse", { rotateIcon45: theme === "theme-soccer" })}
                icon={mediaPath([ "/assets/icons/cross-white.svg", "/assets/images/padel/icons/cross.svg" ])}
                onlyIcon
                onClick={() => refuseFriendship(invitation)}
                hoverChangeIcon={mediaPath([ "/assets/icons/cross-white.svg", "/assets/images/padel/icons/crossHover.svg" ])}
            />
            <ButtonCta
                noSkew
                noShadow
                className="accept"
                icon={mediaPath([ "/assets/images/check--orange.svg", "/assets/images/padel/icons/check.svg" ])}
                onlyIcon
                onClick={() => acceptFriendship(invitation)}
                hoverChangeIcon={mediaPath([ "/assets/images/check--white.svg", "/assets/images/padel/icons/checkHover.svg" ])}
            />
        </div>
    )

    const headerButton = (
        <ButtonCta
            icon={mediaPath([
                "/assets/images/picto-add-user--white.svg",
                "/assets/images/padel/icons/icons-player-plus.svg",
            ])}
            hoverChangeIcon={mediaPath([
                "/assets/images/picto-add-user--white.svg",
                "/assets/images/padel/icons/icons-player-plus-green.svg",
            ])}
            text="Inviter des joueurs"
            onClick={() => showAddPlayerModal()}
            title={isMobile ? "Inviter mes joueurs" : false}
            onlyIcon={isMobile}
            disabled={!shareLink}
        />
    )

    //useEffect
    useEffect(
        () => {
            //generate share link
            generateFriendshipRawInvitationTokenUrl(import.meta.env.VITE_URL_BASE + "/").then(
                (response) => {
                    if (response.status === 200) {
                        setShareLink(response.data.data[0])
                        if (searchParams.get("add")) {
                            setSearchParams({})
                            showAddPlayerModal(response.data.data[0])
                        }
                    }
                },
            )
            //facebook init
            let fbAppId = encodeURIComponent(appConfig.fbAppId)
            window.fbAsyncInit = function () {
                window.FB.init({
                    // appId      : '184264925511637',
                    appId: fbAppId,
                    cookie: true,
                    status: true,
                    version: "v12.0",
                    xfbml: true,
                })
            };

            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0]
                if (d.getElementById(id)) { return }
                js = d.createElement(s); js.id = id
                js.src = "//connect.facebook.net/en_US/sdk.js"
                fjs.parentNode.insertBefore(js, fjs)
            }(document, "script", "facebook-jssdk"))
        },[],
    )

    useEffect(
        () => {
            //get user friendsList and invitations
            setIsLoading(true)
            listUserFriendsAndInvitations().then(
                res => {
                    setIsLoading(false)
                    const data = res.data.data
                    if (res && res.data && data) {
                        setFriends(data.friends.sort(alphabeticalPlayerSort))
                        setReceivedInvitations(data.invitations.received.sort(alphabeticalPlayerSort))
                    }
                },
            )
        },[ refresh ],
    )

    return (
        <div className={ classNames("friendsInvitation", {
            "padding-bottom": (UserAgentHandler.isAndroid() || UserAgentHandler.isIOS()) && !isAppV2,
        })
            
        }>
            <PageHeader
                title={"JOUEURS"}
                goBack={false}
                button={headerButton}
            />
            <div className="c-row">
                <div className="c-col c-col--10 c-col--sm--12 no-padding ">
                    {isLoading && <Preloader />}
                    {!isLoading && friends.length === 0 && receivedInvitations.length === 0 && (
                        <div className="nofriendsYet">
                            <img alt="" src={mediaPath([ "/assets/images/picto-effectif.svg", "/assets/images/padel/picto-effectif.svg" ])} />
                            <p>Tu n’as pas encore de joueurs.</p>
                            <ButtonCta
                                text="Inviter mes joueurs"
                                onClick={() => showAddPlayerModal()}
                            />
                        </div>
                    )}
                    {!isLoading && receivedInvitations.length > 0 && (
                        <div className="standbyList">
                            <h2>
                                <span>
                                    EN ATTENTE ({receivedInvitations.length})
                                </span>
                            </h2>
                            {receivedInvitations.map(
                                invitation => (
                                    <PlayerLine
                                        key={invitation.inviteId + invitation.username}
                                        invite={invitation}
                                        rightPart={rightPart(invitation)}
                                        tunnelType="PROFIL"
                                    />
                                ),
                            )}
                        </div>
                    )}
                    {!isLoading && friends.length > 0 && (
                        <div className={classNames("friendsList", {
                            "padding-bottom": (UserAgentHandler.isAndroid() || UserAgentHandler.isIOS()) && !isAppV2,
                        })}>
                            <h2>
                                <span>
                                    JOUEUR{friends.length > 1 ? "S" : ""} ({friends.length})
                                </span>
                            </h2>
                            {friends.map(
                                friend => {
                                    if (userLoaded.contactId !== friend.ozmoSystemId) {
                                        return (
                                            <PlayerLine
                                                key={friend.ozmoSystemId + friend.username}
                                                invite={friend}
                                                isProfil={true}
                                                customCls="isClickable"
                                                isSelected={selectedFriend && friend.ozmoSystemId === selectedFriend.ozmoSystemId}
                                                selectPlayer={setSelectedFriend}
                                                bottomPart={bottomPart()}
                                                tunnelType="PROFIL"
                                            />
                                        )
                                    }
                                },
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export const RemoveFriendCOnfirmationPopIn = ModalHandler.create(({ hide, visible, selectedFriend, callback }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className="removeConfirmation"
        >
            <header>Confirmer suppression ?</header>
            <section className="popin--content">
                <div className="message">
                    {"Retirer " + (selectedFriend ? selectedFriend.firstname : "") + " de ta liste de joueurs ?"}
                </div>
                <div className="btn-holder">
                    <ButtonCta
                        onClick={callback}
                        text="Oui"
                    />

                    <ButtonCta
                        isCta2
                        onClick={hide}
                        text="Annuler"
                    />
                </div>
            </section>
        </Modal>
    )
})

export default FriendsInvitation
