import { array, bool, func, object, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"

import AskEmail from "./askEmail"
import AskMyUrbanNetworkUser from "./askMyUrbanNetworkUser"
import AskTeammates from "./askTeammates"
import useMediaPath from "../../../hooks/useMediaPath"
import InvitationOption from "../../../pages/teams/urbanCup/components/InvitationOption/InvitationOption"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import checkMobileBrowser from "../../../services/isMobileBrowser"
import { editUserSessionMeta } from "../../../store/actions/content"
import Modal from "../../Modal/Modal"
import ShareLink from "../../ShareLink/ShareLink.jsx"
import { useTheme } from "../../ThemeContext/ThemeContext.jsx"

import "./MainInvitationsPopIn.scss"

const MainInvitationsPopIn = ModalHandler.create(
    (props) => {
        const {
            mateCallback,
            myUrbanCallback,
            mailCallback,
            messageToSend,
            selectedMates,
            linkToSend,
            tunnelType,
            closeCallback,
            visible,
            hide,
        } = props

        const mediaPath = useMediaPath()
        const theme = useTheme()
        const [ shareLink, setShareLink ] = useState("")
        const isMobileBrowser = checkMobileBrowser()

        const showParentCallback = () => {
            if (closeCallback) {
                closeCallback()
            }
        }

        async function invitWhatsApp(e) {
            e.preventDefault()
            editUserSessionMeta("beforeSocialNetworksInvitation", true)
            const retFunct = messageToSend()

            let whatsappWindow
            const base = "https://wa.me/?text="

            if (tunnelType === "BOOKING" || tunnelType === "PROFIL") {
                whatsappWindow = window.open("", "_blank")

                if (isMobileBrowser) {
                    window.open(base + retFunct, "_system")

                } else {
                    window.open(base + retFunct, "_blank")
                }
            } else {
                whatsappWindow = window.open("", isMobileBrowser ? "_system" : "_blank")

                retFunct.then(async (resPromise) => {
                    if (isMobileBrowser) {
                        whatsappWindow.location.href = "whatsapp://send?text=" + resPromise
                        whatsappWindow.location.href = base + resPromise
                    } else {
                        whatsappWindow.location.href = base + resPromise
                    }
                }).catch(() => {
                    whatsappWindow.close()
                })
            }
        }

        useEffect(() => {
            ModalHandler.update(AskTeammates, {
                alreadySelectedMatesIds: selectedMates,
                callback: mateCallback,
            })
            ModalHandler.update(AskMyUrbanNetworkUser, {
                alreadySelectedMatesIds: selectedMates,
                callback: myUrbanCallback,
            })
        }, [ selectedMates ])

        useEffect(() => {
            const fetchLinkToSend = async () => {
                try {
                    return await linkToSend()
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error("Failed to fetch link:", error)
                    return ""
                }
            }

            fetchLinkToSend().then((link) => {
                setShareLink(link)
            })
        }, [])

        return (
            <div className="effectifPopIns">
                <div className="big">
                    <Modal
                        isOpen={visible}
                        onClose={() => {
                            showParentCallback()
                            hide()
                        }}
                    >
                        <header> Ajouter un joueur</header>
                        <section className="invitationOptionHolder">
                            {!tunnelType && (
                                <InvitationOption
                                    text='Coéquipiers'
                                    icon={mediaPath([ "/assets/images/ICON-avatar-coequipier.svg", "/assets/images/ICON-avatar-coequipier.svg" ])}
                                    invitationCallback={
                                        () => {
                                            ModalHandler.show(AskTeammates, {
                                                alreadySelectedMatesIds: selectedMates,
                                                callback: mateCallback,
                                            }, { keepPreviousProps: true, removeOnHide: false }).then(() => {
                                                showParentCallback()
                                            })
                                        }
                                    }
                                />
                            )}

                            {myUrbanCallback && (
                                <InvitationOption
                                    text="Réseau MyUrban"
                                    icon={mediaPath([ "/assets/images/Player-US-O--white-nobkg.svg", "/assets/images/padel/icons/urban-green.svg" ])}
                                    invitationCallback={
                                        () => {
                                            ModalHandler.show(AskMyUrbanNetworkUser, {
                                                alreadySelectedMatesIds: selectedMates,
                                                callback: myUrbanCallback,
                                            }, { keepPreviousProps: true, removeOnHide: false }).then(() => {
                                                showParentCallback()
                                            })
                                        }
                                    }
                                    customCls="networkInvitation"
                                />
                            )}

                            {messageToSend && (
                                <InvitationOption
                                    text='WhatsApp'
                                    icon={mediaPath([ "/assets/icons/whatsAppIcon.svg", "/assets/images/padel/icons/whatsAppIcon.svg" ])}
                                    invitationCallback={invitWhatsApp}
                                />
                            )}

                            {mailCallback && (
                                <InvitationOption
                                    text='E-Mail'
                                    icon={mediaPath([ "/assets/icons/emailIcon.svg", "/assets/images/padel/icons/emailIcon.svg" ])}
                                    invitationCallback={
                                        () => {
                                            ModalHandler.show(AskEmail, {
                                                callback: mailCallback,
                                            }, { keepPreviousProps: true, removeOnHide: false }).then(() => {
                                                showParentCallback()
                                            })
                                        }
                                    }
                                    customCls="mailInvitation"
                                />
                            )}

                        </section>
                        {linkToSend && (
                            <section className="button-holder">
                                <ShareLink
                                    isAlt={theme === "theme-soccer"}
                                    isCta2={theme === "theme-padel"}
                                    shareLink={shareLink}
                                />
                            </section>
                        )}
                    </Modal>
                </div>
            </div>
        )
    },
)

MainInvitationsPopIn.propTypes = {
    closeCallback: func,
    linkToSend: func,
    mailCallback: func,
    mateCallback: func,
    messageToSend: func,
    myUrbanCallback: func,
    parentPopIn: object,
    selectedMates: array,
    setIsSelfOpen: oneOfType([ bool, func ]),
    tunnelType: oneOfType([ bool, string ]),
}

MainInvitationsPopIn.displayName = "MainInvitationPopin"

export default MainInvitationsPopIn
