
import { ArcElement, Chart as ChartJS } from "chart.js"
import classNames from "classnames"
import { array } from "prop-types"
import { useEffect, useState } from "react"
import { Pie } from "react-chartjs-2"

import useIsMobile from "../../hooks/useIsMobile"
import useThemeFeatures from "../../hooks/useThemeFeatures"
import ModalHandler from "../../providers/Modal/ModalHandler"
import Modal from "../Modal/Modal"
import ProgressItem from "../ProgressItem/ProgressItem"

import "./CustomProgressBar.scss"

ChartJS.register(ArcElement)

const predefinedColors = {
    "theme-padel": [
        "#81CB77",
        "#8EE482",
        "#99F88C",
        "#B4FFAA",
        "#D7FFD1",
    ],
    "theme-soccer": [
        "#ff7832",
        "#FF884A",
        "#FF9A65",
        "#FFB28A",
        "#FFC6A9",
    ],
}

const disabledColor = "#F4F5F7"

const options = {
    cutout: "50%",
    hover: {
        mode: null,
    },
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
        },
    },
    responsive: true,
}

export default function CustomProgressBar({ items }) {
    const [ isTooltipOpen, setIsTooltipOpen ] = useState(false)
    const [ isComplete, setIsComplete ] = useState(false)
    const isMobile = useIsMobile()
    const theme = localStorage.getItem("lastUniverse")
    const themeFeatures = useThemeFeatures()

    const showModal = () => {
        ModalHandler.show(ProgressBarModal, {
            items,
        }).then(() => {
            setIsTooltipOpen(false)
        })
    }

    const handleInfoBubbleClick = () => {
        if (isTooltipOpen) {
            setIsTooltipOpen(false)
        } else {
            showModal()
            setIsTooltipOpen(true)
        }
    }

    const data = {
        datasets: [
            {
                backgroundColor: generateColors(items, theme),
                borderWidth: 0,
                data: [ ...new Array(items.length).fill(100 / items.length), (100 - (100 / items.length * items.length)) ],
            },
        ],
        labels: [ ...items.map(item => item.label), "Empty" ],
    }

    useEffect(() => {
        setIsComplete(!items?.some(item => item.disabled))
    }, [ items ])

    return (
        <div className="customProgressBarWrapper">
            {isMobile ? (
                <div className={"customProgressPie"}>
                    <div className="infoBubble__icon" onClick={handleInfoBubbleClick}>
                        {themeFeatures([ "i", <img key="padelDiscountInfo" src="/assets/images/padel/icons/icons-info.svg" /> ])}
                    </div>
                    <p className={"pieCompletionPercentage"}>
                        {items.filter(item => !item.disabled).length / items.length * 100}%
                    </p>
                    <Pie data={data} options={options}/>
                </div>
            ) : (
                <div className={classNames("customProgressBar", { ["grid-cols-" + items.length]: items?.length })}>
                    {items?.length > 0 && items.map(item => (
                        <ProgressItem
                            label={item.label}
                            disabled={item.disabled}
                            key={item.key}
                        />
                    ))}
                </div>
            )}
            {isComplete && <div className="ready"><img src={themeFeatures([ "/assets/icons/checked-green.svg", "/assets/images/padel/icons/checked-red.svg" ])}/>TON MATCH EST PRÊT !</div>}
        </div>
    )

    function generateColors(items, theme) {
        return items.map((item, index) => {
            if (item.disabled) {return disabledColor}
            return predefinedColors[theme][items.length - 1 - index]
        })
    }
}

const ProgressBarModal = ModalHandler.create(({ items, visible, hide }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
        >
            <div className="completionPopIn">
                <div className="completionPopIn__header">
                    gestion de ma réservation
                </div>
                <div className="completionPopIn__content">
                    {items?.length > 0 && items.map(item => (
                        <ProgressItem
                            simple
                            label={item.label}
                            disabled={item.disabled}
                            key={item.key}
                        />
                    ))}
                </div>
            </div>
        </Modal>
    )
})

CustomProgressBar.propTypes = {
    items: array,
}
