import { bool, number, object, string } from "prop-types"
import React from "react"

import Tooltip from "../../../entries/main/../../components/tooltip/Tooltip"
import useMediaPath from "../../../hooks/useMediaPath"
import ActivationTrigger from "../../Buttons/Triggers/ActivationTrigger"

function OptionCheckboxContent({ category, option, idx, isMobile }) {
    const mediaPath = useMediaPath()

    return (
        <React.Fragment>
            <div className="radioList__item__subTitle">
                { option.name }
            </div>
    
            { option.description &&
                (<div className="infoBubble">
                    { !isMobile && 
                        (<Tooltip id={"infoBubble-supp-category-" + category.replace(/ /g, "") + "-" + idx}>
                            <p dangerouslySetInnerHTML={{ __html: option.description }}></p>
                        </Tooltip>)
                    }
                    <ActivationTrigger id="buttonInfoBullePrestaSup" target={"#infoBubble-supp-category-"  + category.replace(/ /g, "") + "-" + idx}>
                        <div className="infoBubble__icon">
                            <img src={mediaPath([ "/assets/icons/info-small.svg", "/assets/images/padel/icons/icons-info.svg" ])} alt="info"/>
                        </div>
                    </ActivationTrigger>
                </div>)
            }
    
            <div className="radioList__item__price">
                {option.price > 0 && !option.isFull && 
                    <strong>{ option.price + " €"}</strong>
                }
                {
                    option.isFull && 
                        <strong>Complet</strong>
                }
            </div>
        </React.Fragment>
    )
}

OptionCheckboxContent.propTypes = {
    category: string,
    idx: number,
    isMobile: bool,
    option: object,
}

export default OptionCheckboxContent
