import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

import { navigateToTeamPage } from "../../../pages/league/service/service.js"
import Table from "../Table/Table"

import "./GeneralRanking.scss"

export default function GeneralRanking({ isLoading, data, highlightedTeamId }) {
    const navigate = useNavigate()

    if (isLoading || !data?.length) {return}

    const columns = [
        { align: "left", name: "name", render: (row) => {
            const values = row?.values
            return (
                <a onClick={() => {
                    navigateToTeamPage(navigate, row.id)
                }}>
                    {values.get("name")?.trim()}
                </a>
            )
        } },
        { align: "center", name: "totalPoints" },
        { align: "center", name: "played" },
        { align: "center", name: "fairplayPoints" },
        { align: "center", name: "goalsDiff" },
        { align: "center", name: "goalsFor" },
        { align: "center", name: "goalsAgainst" },
        { align: "center", name: "wins" },
        { align: "center", name: "draws" },
        { align: "center", name: "losses" },
        { align: "center", name: "" },
    ]

    return (
        <Table
            customClass="generalRanking"
            title="général"
            columns={columns}
            columnDisplayNames={columnsDisplayNames}
            dataList={data}
            animateRows
            highlightIds={[ highlightedTeamId ]}
            withVirtualScrollbar
        />
    )
}

GeneralRanking.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    highlightedTeamId: PropTypes.number,
    isLoading: PropTypes.object,
}

const columnsDisplayNames = new Map([
    [ "name", "équipe" ],
    [ "totalPoints", "PTS" ],
    [ "played", "J" ],
    [ "fairplayPoints", "FP" ],
    [ "goalsDiff", "+/-" ],
    [ "goalsFor", "BP" ],
    [ "goalsAgainst", "BC" ],
    [ "wins", "V" ],
    [ "draws", "N" ],
    [ "losses", "D" ],
])
