import padelLanguage from "./languages/PadelLanguage"
import soccerLanguage from "./languages/SoccerLanguage"
import { useTheme } from "../../components/ThemeContext/ThemeContext"

function useUrbanLanguage() {
    const theme = useTheme()

    let translation

    switch (theme) {
    case "theme-soccer":
        translation = soccerLanguage
        break
    case "theme-padel":
        translation = padelLanguage
        break
    }

    return {
        // Cette fonction JavaScript retourne un objet qui contient une propriété "tu" qui est une fonction. Cette fonction prend un paramètre appelé "keys"
        // qui est utilisé pour accéder à une propriété d'un objet en utilisant la notation de point (par exemple, "obj.prop1.prop2").
        // La fonction utilise la méthode "reduce" pour parcourir les propriétés de l'objet en utilisant les clés fournies en utilisant la notation de point.
        // La fonction retourne la valeur de la propriété trouvée ou null si la propriété n'est pas trouvée.
        tu: keys => keys.split(".").reduce((obj, index) => (obj && index ? obj[index] : null), translation),
    }
}

export default useUrbanLanguage
