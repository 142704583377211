import classNames from "classnames"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { FavoriteSlot } from "./FavoriteSlot/FavoriteSlot"
import ButtonCta from "../../../../components/Buttons/button/button-cta"
import Preloader from "../../../../components/loaders/preloader/preloader"
import { getUsCentersBookable } from "../../../../globalAPI/api"
import useUrbanLanguage from "../../../../hooks/urbanLanguage/useUrbanLanguage.js"
import useDeepCompareEffect from "../../../../hooks/useDeepCompareEffect.js"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import usePath from "../../../../routes/services/usePath"
import { getSlotSearchUrl, hydrateSlots } from "../../../profil/FavoriteSlots/functions"
import sortCenters from "../../../reservation/tunnelReservation/services/sortCenters"

import "./FavoriteSlots.scss"

export const FavoriteSlots = () => {
    const userLoaded = useSelector(state => state.userLoaded) ?? []
    const activity = useThemeHeader()
    const slots = filterSlotsByActivity(userLoaded?.preferredSlots, activity)  ?? []
    const hasFavoriteSlots = slots?.length > 0
    const navigate = useNavigate()
    const [ bookables, setBookables ] = useState([])
    const path = usePath()
    const [ hydratedSlots, setHydratedSlots ] = useState(slots)
    const { tu } = useUrbanLanguage()
    const goToFavoriteSlots = (openModal) => {
        navigate(path("/profil/creneauxFavoris"), {
            state: {
                openModal: openModal,
            },
        })
    }

    useDeepCompareEffect(() => {
        if (slots.length > 0) {
            if (bookables.length) {
                const newHydratedSlots = hydrateSlots(slots, bookables)
                setHydratedSlots(newHydratedSlots)
                return
            }

            getUsCentersBookable(activity).then(res => {
                if (res?.data) {
                    const tempCenters = Object.values(res.data)
                    const sortedCenters = sortCenters(tempCenters)
                    setBookables(sortedCenters)
                    const newHydratedSlots = hydrateSlots(slots, sortedCenters)
                    setHydratedSlots(newHydratedSlots)
                }
            })
        }
    }, [ activity, slots ])

    const slotsList = useMemo(() => {
        return hydratedSlots?.map((slot) => {
            const url = getSlotSearchUrl(path("/reserver"), slot)
            return <FavoriteSlot key={slot.id} notification={slot.status === 0} url={url} slot={slot}/>
        })
    }, [ hydratedSlots,  activity ])

    return (
        <div className={classNames("favorite-slots-wrapper", {
            empty: slotsList.length === 0,
        })}>
            {!userLoaded ? (
                <Preloader small/>
            ) : (
                <>
                    <span className={"favorite-slots-label"}>
                        Mes créneaux favoris
                    </span>
                    <div className={"favorite-slots-list"}>
                        {hasFavoriteSlots ? slotsList : (
                            <p>Renseigne tes créneaux favoris pour faciliter tes réservations et créer une alerte dès qu’{tu("booking.favoriteSlotField")} se libère !</p>
                        )}
                    </div>
                    <ButtonCta
                        className={"buttonFit"}
                        text={hasFavoriteSlots ? "Gérer tes créneaux" : "Ajouter"}
                        onClick={() => goToFavoriteSlots(!hasFavoriteSlots)}
                        isAlt
                    />
                </>
            )}
        </div>
    )

    function filterSlotsByActivity(slots, activity) {
        return slots.filter(slot => slot.activityId === activity)
    }
}
