import dayjs from "dayjs"
import { bool, number, string } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector, useStore } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import ButtonCta from "../../../components/Buttons/button/button-cta"
import CheckBlock from "../../../components/Forms/CheckBlock/CheckBlock"
import Preloader from "../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../components/popin/help/phoneCall"
import Step from "../../../components/stepsManagement/Step"
import CommonHandler from "../../../entries/main/business/CommonHandler"
import useQueryParametersObject from "../../../hooks/routing/useQueryParametersObject"
import useResetScroll from "../../../hooks/useResetScroll"
import usePath from "../../../routes/services/usePath"
import { setFormEntry } from "../../../store/forms/actions"
import { goToStep } from "../../../store/stepsManagement/actions"
import { getUserRegistrationLeagueTeams } from "../../league/api/api.js"
import useGatherUserRegistrations from "../hooks/useGatherUserRegistrations"
import { cancelCupPreregistration, getUserTeams } from "../urbanCup/api"
import TeamForm from "../urbanCup/components/TeamForm/TeamForm"

function ChoixEquipe(props) {
    useResetScroll()
    const dispatch = useDispatch()
    const emptyTeam = {
        blazon: null,
        id: null,
        name: null,
    }

    const { denyReturn = true, tunnelType } = props

    const teamForm = useRef(null)
    const step = useRef(null)
    const store = useStore()

    const gatherRegistrations = useGatherUserRegistrations(tunnelType)
    const periodStart = dayjs(new Date()).subtract(3, "year").format("YYYY-MM-DD")

    const searchParamsObject = useQueryParametersObject()

    const ChoixEquipeForm = useSelector(state => state.forms.ChoixEquipe)

    const selectedTeam = ChoixEquipeForm ? ChoixEquipeForm.selectedTeam : null

    const [ cupRegistration, setCupRegistration ] = useState()
    const [ teams, setTeams ] = useState(null)
    const [ ajaxLoadingRegistrations, setAjaxLoadingRegistrations ] = useState(false)
    const [ ajaxLoadingTeams ] = useState(false)
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ displayForm, setDisplayForm ] = useState(selectedTeam && Object.keys(selectedTeam).length !== 0)
    const [ noReturn, setNoReturn ] = useState(denyReturn)
    const [ currentSubs, setCurrentSubs ] = useState(null)

    const [ searchParams, setSearchParams ] = useSearchParams()

    /* DOM HELPERS */
    const help = <PhoneCallHelp tunnelType={tunnelType}/>

    const registration = useSelector(state => state.forms.TournamentRegistration)
    const SubCount = useSelector(state => state.forms.RegistrationCount)
    const currentStep = useSelector(state =>  state.stepManagement.currentStep)
    const tunnelTournamentRegistration = useSelector(state =>  state.forms.tunnelTournamentRegistration)

    const navigate = useNavigate()
    const path = usePath()

    useEffect(
        () => {
            if (!cupRegistration) {
                if (registration && teams) {
                    setCupRegistration(registration)
                    let registrationTeam = teams.filter(
                        x => x.id === registration.teamId,
                    )[0]
                    setDisplayForm(true)
                    setTimeout(() => {
                        scrollToChildrenForm()
                    }, 300)
                    let newTeam
                    switch (tunnelType) {
                    case "CUP":
                        newTeam = registration.cupTeam
                        break

                    case "LEAGUE":
                        newTeam = registration.championshipTeam
                        break
                    }

                    if (registrationTeam) {
                        if (newTeam.teamName) {
                            registrationTeam.name = newTeam.teamName
                        }
                        dispatch(
                            setFormEntry(
                                "ChoixEquipe", {
                                    ...store.getState().forms.ChoixEquipe,
                                    selectedTeam: registrationTeam,
                                },
                            ),
                        )
                    } else {
                        dispatch(
                            setFormEntry(
                                "ChoixEquipe", {
                                    ...store.getState().forms.ChoixEquipe,
                                    selectedTeam: {
                                        blazon: registration.teamPicture,
                                        id: newTeam.id,
                                        name: newTeam.teamName ?
                                            newTeam.teamName
                                            :
                                            newTeam.name,
                                    },
                                },
                            ),
                        )
                    }
                }
            }
        }, [ registration, teams ],
    )

    useEffect(
        () => {
            if (!SubCount) {
                setAjaxLoadingRegistrations(true)
                gatherRegistrations({
                    offset: 0,
                    orderByAsc: false,
                    pageSize: 50,
                    periodStart: periodStart,
                    status: "[2,1,3]",
                }).then(
                    (res) => {
                        if (res) {
                            setCurrentSubs(res.items)
                            res.count === 0 ?
                                setNoReturn(true)
                                :
                                setNoReturn(false)
                        }
                        setAjaxLoadingRegistrations(false)
                    },
                )
            } else if (SubCount > 0) {
                setNoReturn(false)
            } else {
                if (!currentSubs) {
                    setCurrentSubs([])
                }
            }
            updateTeams()

            if (!selectedTeam) {
                removeSelectedTeam(true)
            }
        }, [],
    )

    useEffect(
        () => {
            const teamIdFromUrl = searchParams.get("teamId")
            if (teamIdFromUrl && teams) {
                let registrationTeam = teams.filter(
                    x => x.id === parseInt(teamIdFromUrl),
                )[0]

                setDisplayForm(true)

                dispatch(
                    setFormEntry(
                        "ChoixEquipe", {
                            ...store.getState().forms.ChoixEquipe,
                            selectedTeam: registrationTeam,
                        },
                    ),
                )
            }
        },[ teams ],
    )

    useEffect(
        () => {
            if (selectedTeam && currentSubs && currentSubs.length > 0) {
                let ids = currentSubs.filter(
                    x => x.teamId === selectedTeam.id,
                ).map(
                    x => {
                        switch (tunnelType) {
                        case "CUP":
                            return x.cup.id
                        case "LEAGUE":
                            return x.league.id
                        }
                    },
                )
                dispatch(
                    setFormEntry(
                        "RegistrationCount",
                        currentSubs.length,
                    ),
                )
                dispatch(
                    setFormEntry(
                        tunnelType === "CUP" ?
                            "alreadySubscribedCupIds"
                            :
                            "alreadySubscribedLeagueIds",
                        ids,
                    ),
                )
            }
        }, [ selectedTeam, currentSubs ],
    )

    useEffect(
        () => {
            const teamIdFromUrl = searchParams.get("teamId")
            if (currentStep > props.numero - 1 && !teamIdFromUrl) {
                if (formTeamIsInvalid() && currentStep === 2) {
                    // dead case
                } else if (formTeamIsInvalid() && currentStep === 1) {
                    dispatch(goToStep(0))
                } else if (formTeamHasChanged()) {
                    dispatch(goToStep(props.numero - 1))
                    storeTeamInfo().then(
                        () => {
                            dispatch(goToStep(currentStep))
                        },
                    )
                } else {
                    if (teamForm.current) {
                        const tf = teamForm.current.getInfo()
                        if (selectedTeam.name !== tf.teamName) {
                            dispatch(
                                setFormEntry("ChoixEquipe", {
                                    selectedTeam: {
                                        ...selectedTeam,
                                        ...teamForm.current.getInfo(),
                                    },
                                }),
                            )
                        } else {
                            const recap = step.current.getRecap()
                            if (!recap) {
                                setTeamRecap()
                            }
                        }
                    }
                }
            }
        }, [ props.cls, currentStep ],
    )

    function formTeamIsInvalid() {
        if (teamForm.current) {
            let formTeam = teamForm.current.getInfo()
            return !formTeam.name && !formTeam.teamName
        } else {
            return true
        }
    }

    function formTeamHasChanged() {
        if (teamForm.current) {
            let formTeam = teamForm.current.getInfo()
            if (registration) {
                const teamIdHasChanged = (
                    selectedTeam.id !== null &&
                    selectedTeam.id !== undefined &&
                    selectedTeam.id !== registration.teamId
                )
                const teamNameHasChanged = (
                    // formTeam.teamName !== selectedTeam.name ||
                    formTeam.teamName !== registration.teamName
                )
                const hasChanged = (
                    (teamIdHasChanged || teamNameHasChanged)
                )
                return hasChanged
            } else {
                return false
            }
        } else {
            return false
        }
    }

    function updateTeams(blazon) {

        let gatherMethod
        switch (tunnelType) {
        case "CUP":
            gatherMethod = getUserTeams
            break

        case "LEAGUE":
            gatherMethod = getUserRegistrationLeagueTeams
            break
        }
        if (blazon) {
            dispatch(
                setFormEntry("ChoixEquipe", {
                    selectedTeam: {
                        ...selectedTeam,
                        ...teamForm.current.getInfo(),
                    },
                }),
            )
        } else {
            setAjaxLoading(true)
            gatherMethod().then(
                (res) => {
                    setAjaxLoading(false)
                    setTeams(res.data.data.sort(
                        (a, b) => {
                            let atrim = a.name.charAt(0).toUpperCase() + a.name.slice(1)
                            let btrim = b.name.charAt(0).toUpperCase() + b.name.slice(1)
                            if (atrim < btrim) {
                                return -1
                            } else if (atrim === btrim) {
                                return 0
                            } else {
                                return 1
                            }
                        },
                    ))
                    if (res.data.data.length === 0) {
                        setDisplayForm(true)
                        setTimeout(() => {
                            scrollToChildrenForm()
                        }, 300)

                        dispatch(
                            setFormEntry(
                                "tunnelTournamentRegistration",
                                {
                                    ...tunnelTournamentRegistration,
                                    isNewRegistration: true,
                                    teamDataChange: true,
                                },
                            ),
                        )
                    } else if (selectedTeam) {
                        let updatedSelectedTeam = res.data.data.filter(
                            x => x.id === selectedTeam.id,
                        )[0]
                        if (updatedSelectedTeam) {
                            setSearchParams({
                                ...searchParamsObject,
                                teamId: updatedSelectedTeam.id,
                            })
                            dispatch(
                                setFormEntry("ChoixEquipe", {
                                    ...store.getState().forms.ChoixEquipe,
                                    selectedTeam: updatedSelectedTeam,
                                }),
                            )
                            setTimeout(() => {
                                scrollToChildrenForm()
                            }, 300)
                        }
                    }
                },
            )
        }
    }

    function buildTeamForm(event) {
        event.stopPropagation()
        event.preventDefault()

        const newSelectedId = parseInt(event.currentTarget.id)
        const newSelectedTeam = teams.filter(x => x.id === newSelectedId)[0]
        const newSelectedTeamId = newSelectedTeam.id

        const selectedTeamIdChanged = (selectedTeam && selectedTeam.id !== newSelectedTeamId)
        const selectedTeamDidNotChange = (selectedTeam && selectedTeam.id === newSelectedTeamId)

        if (selectedTeamIdChanged) {
            setSearchParams({
                ...searchParamsObject,
                teamId: newSelectedTeam.id,
            })
            dispatch(
                setFormEntry(
                    "ChoixEquipe", {
                        ...ChoixEquipeForm,
                        selectedTeam: newSelectedTeam,
                    },
                ),
            )
            setDisplayForm(true)
            setTimeout(
                () => {
                    scrollToChildrenForm()
                }, 300,
            )
            dispatch(
                setFormEntry(
                    "tunnelTournamentRegistration",
                    {
                        ...tunnelTournamentRegistration,
                        isNewRegistration: true,
                        teamDataChange: true,
                    },
                ),
            )
        } else if (selectedTeamDidNotChange) {
            removeSelectedTeam()
            setDisplayForm(false)
        }
    }

    function removeSelectedTeam(shallNotClearQuery) {
        if (!shallNotClearQuery) {
            setSearchParams({})
        }
        dispatch(
            setFormEntry(
                "ChoixEquipe", {
                    ...store.getState().forms.ChoixEquipe,
                    selectedTeam: emptyTeam,
                },
            ),
        )
        unvalidate()
    }

    function renderBlankForm() {
        removeSelectedTeam()
        setDisplayForm(true)
        setTimeout(
            () => {
                scrollToChildrenForm()
            }, 300,
        )
        dispatch(
            setFormEntry(
                "tunnelTournamentRegistration",
                {
                    ...tunnelTournamentRegistration,
                    isNewRegistration: true,
                    teamDataChange: true,
                },
            ),
        )
    }

    /* handle scroll auto to children form */
    function scrollToChildrenForm() {
        let scrollElement = document.getElementById("team-form")
        if (scrollElement) {
            const rectChildForm = scrollElement.getBoundingClientRect()
            let article = document.getElementById("step-article-1")

            // mobile
            if (window.innerWidth <= 640) {
                const y = rectChildForm.top + window.scrollY + rectChildForm.height
                const _document = document.scrollingElement || document.documentElement
                CommonHandler.scrollTo(_document, y, 400)
            } else {
                const y = rectChildForm.bottom + article.scrollTop + rectChildForm.height
                let baseElement = document.getElementById("step-article-1")
                CommonHandler.scrollTo(baseElement, y, 400)
            }
        }
    }

    function validate() {
        if (step.current) {
            setTeamRecap()
            step.current.validate()
        }
    }

    function unvalidate() {
        if (step.current) {
            step.current.unvalidate()
        }
    }

    function storeTeamInfo() {
        return new Promise(
            (resolve) => {

                if (teamForm.current) {
                    dispatch(
                        setFormEntry("ChoixEquipe", {
                            selectedTeam: {
                                ...selectedTeam,
                                ...teamForm.current.getInfo(),
                            },
                        }),
                    )
                }
                if (formTeamHasChanged()) {
                    setAjaxLoading(true)
                    dispatch(
                        setFormEntry(
                            "TournamentRegistration",
                            undefined,
                        ),
                    )
                    cancelCupPreregistration(registration.id).then(
                        () => {
                            dispatch(
                                setFormEntry(
                                    "tunnelTournamentRegistration",
                                    {
                                        ...tunnelTournamentRegistration,
                                        isNewRegistration: true,
                                        teamDataChange: true,
                                    },
                                ),
                            )
                            setAjaxLoading(false)
                            resolve()
                        },
                    )
                } else {
                    dispatch(
                        setFormEntry(
                            "tunnelTournamentRegistration",
                            {
                                ...tunnelTournamentRegistration,
                                isNewRegistration: true,
                                teamDataChange: true,
                            },
                        ),
                    )
                    resolve()
                }
                setTeamRecap()
            },
        )
    }

    function setTeamRecap() {
        if (step.current) {
            let tf = teamForm.current
            step.current.setRecap(
                <div>
                    {
                        tf ?
                            tf.getInfo().teamName
                            :
                            selectedTeam.name
                    }
                </div>,
            )
        }
    }

    function getReturnText() {
        switch (tunnelType) {
        case "CUP":
            return "Voir mes tournois"
        case "LEAGUE":
            return "Voir mes inscriptions"
        }
    }

    const selectedTeamName = selectedTeam?.name ? selectedTeam.name : selectedTeam?.teamName

    return (
        <Step
            {...props}
            title="Mon équipe"
            help={ help }
            helpId={"monEquipeHelp"}
            onReturn={
                () => {
                    switch (tunnelType) {
                    case "CUP":
                        navigate(path("/cup"))
                        break
                    case "LEAGUE":
                        navigate(path("/urbanleague/inscriptionLeague"))
                        break
                    }
                }
            }
            className="noMarginBottom teamChoice"
            onValidation={storeTeamInfo}
            promise
            noReturn={noReturn}
            returnText={getReturnText()}
            ref={step}
        >
            <div className="formsHolder noHPadding c-row justify-center layoutSmall">
                <div className="c-col c-col--8 c-col--sm--12">
                    {ajaxLoading || ajaxLoadingRegistrations || ajaxLoadingTeams ?
                        <Preloader fixed={true}/>
                        : (
                            <div className="radioList--small">

                                { teams &&
                                Object.values(teams).map(
                                    (team) => (
                                        <CheckBlock
                                            callback={buildTeamForm}
                                            checked={selectedTeam ?
                                                parseInt(team.id) === parseInt(selectedTeam.id)
                                                :
                                                false
                                            }
                                            value={team.id}
                                            name="teams"
                                            key={team.id}
                                        >
                                            <div className="radioList__item--logo">
                                                <img src={team.blazon ? team.blazon : "/assets/images/placeholder-team.svg"}/>
                                            </div>
                                            <div className="radioList__item__subTitle">
                                                {team.name.toUpperCase()}
                                            </div>

                                            <div className="radioList__item__info captain-name">
                                                {team.captain}
                                            </div>
                                        </CheckBlock>
                                    ),
                                )
                                }

                                {
                                    (teams && Object.values(teams).length > 0) &&
                                    (<div className="radioList__item action">
                                        <ButtonCta
                                            className="buttonCta--iconMore"
                                            onClick={renderBlankForm}
                                            text="Ajouter&nbsp;une nouvelle&nbsp;équipe"
                                            noSkew
                                            noShadow
                                        />
                                    </div>)
                                }
                            </div>)
                    }
                </div>
            </div>

            <div className="formsHolder noHPadding c-row justify-center align-start layoutSmall">
                <div className="c-col c-col--8 c-col--sm--12 no-padding" id="team-form">
                    {((displayForm && selectedTeam) || (teams && teams.length === 0)) && (
                        <TeamForm
                            tunnelType={tunnelType}
                            teamId={selectedTeam.id}
                            initialBlazon={selectedTeam.blazon}
                            initialTeamName={
                                (teams && teams.length === 0) ?
                                    null
                                    :
                                    selectedTeamName
                            }
                            onValidation={validate}
                            onUnvalidation={unvalidate}
                            onFileChangeUpdate={updateTeams}
                            ref={teamForm}
                            key={selectedTeam.id}
                        />
                    )}
                </div>
            </div>
        </Step>
    )
}

ChoixEquipe.propTypes = {
    cls: string,
    denyReturn: bool,
    numero: number,
    tunnelType: string,
}

export default ChoixEquipe
