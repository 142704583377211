/* eslint-disable no-irregular-whitespace */

import { bool, func, object, string } from "prop-types"

import "./UserLine.scss"

function UserLine({ user, cls, onClick, disabled }) {

    function doSomethingWithUser() {
        /* istanbul ignore else */
        if (onClick && !disabled) {
            onClick(user)
        }
    }

    return (
        <div
            id={"u-" + user.id}
            className={
                "UserLine" + 
                (cls ? ` ${cls}` : "") + 
                (disabled ? " disabled" : "")
            }
            data-testid="clickUser"
            onClick={doSomethingWithUser}
        >
            <div className="userInfo">
                <div className="avatar">
                    <img src={
                        user.pictureUrl ?
                            user.pictureUrl
                            :
                            "/assets/images/menu-user-open.svg"
                    }/>
                </div>
                <div className="text">
                    <span className="firstName">{user.firstname} </span>
                    <span className="lastName">{user.lastname}</span>
                </div>
            </div>
        </div>
    )
}

UserLine.propTypes = {
    cls: string,
    disabled: bool,
    onClick: func,
    user: object,
}

export default UserLine
