export class LeaguePath {
    static listingLeague = "/urbanleague/inscriptionLeague"
    static newLeague = "/urbanleague/new"
    static newLeagueWithId = ":id"
    static leagueInfosBase = "/urbanleague/myLeague"
    static leagueInfos = "/urbanleague/myLeague/:teamId"
    static leagueInfosHome = "general"
    static leagueInfosCalendar = "calendrier"
    static leagueInfosRanking = "classement"
    static leagueTeamDetails = "/urbanleague/team/:teamId/details"
    static leagueMatchBase = "/urbanleague/myLeague/:teamId/match"
    static leagueMatchDetails = ":matchId"
    static leagueMatchCompo = "composition"
    static leagueMatchRoster = "effectif"
    static leagueMatchSummary = "resume"
    static leagueMatchVideo = "videos"
    static leagueRanking = "/urbanleague/classement"
    static leaguePalmares = "/urbanleague/palmares"
    static leaguePalmaresDetails = "/urbanleague/palmares/:leagueId/:teamId"
    static leaguePlayerDetails = "/urbanleague/team/:teamId/player/:playerId/details"
    static toLeagueInfos(teamId) {
        if (teamId) {
            return `/urbanleague/myLeague/${teamId}`
        }
    }

    static toLeagueInfosHome(teamId) {
        if (teamId) {
            return `/urbanleague/myLeague/${teamId}/general`
        } else {
            return "/urbanleague/myLeague/general"
        }
    }

    static toLeagueTeamDetails(teamId) {
        if (teamId) {
            return `/urbanleague/team/${teamId}/details`
        }
    }

    static toLeagueMatchDetails(teamId, matchId) {
        if (matchId) {
            return this.toLeagueInfos(teamId) + `/match/${matchId}/`
        }
    }

    static toLeagueMatchCompo(teamId, matchId) {
        if (matchId) {
            return this.toLeagueInfos(teamId) + `/match/${matchId}/composition`
        }
    }

    static toLeagueMatchRoster(teamId, matchId) {
        if (matchId) {
            return this.toLeagueInfos(teamId) + `/match/${matchId}/effectif`
        }
    }

    static toLeagueMatchSummary(teamId, matchId) {
        if (matchId) {
            return this.toLeagueInfos(teamId) + `/match/${matchId}/resume`
        }
    }

    static toLeagueMatchVideos(teamId, matchId) {
        if (matchId) {
            return this.toLeagueInfos(teamId) + `/match/${matchId}/videos`
        }
    }

    static toLeagueInfosCalendar(teamId) {
        if (teamId) {
            return `/urbanleague/myLeague/${teamId}/calendrier`
        } else {
            return "/urbanleague/myLeague/calendrier"
        }
    }

    static toLeagueInfosRanking(teamId) {
        if (teamId) {
            return `/urbanleague/myLeague/${teamId}/classement`
        } else {
            return "/urbanleague/myLeague/classement"
        }
    }

    static toLeaguePalmaresDetails(leagueId, teamId) {
        if (teamId && leagueId) {
            return `/urbanleague/palmares/${leagueId}/${teamId}`
        } else {
            return "/urbanleague/palmares"
        }
    }

    static toLeaguePlayerDetails(teamId, playerId) {
        if (playerId) {
            return `/urbanleague/team/${teamId}/player/${playerId}/details`
        }
    }
}
