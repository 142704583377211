import dayjs from "dayjs"
import { bool, object } from "prop-types"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import { changeGamePlayerStatus } from "../../../pages/league/api/myLeague.js"
import {
    isGameForfeit,
    navigateToGameDetails,
} from "../../../pages/league/service/service.js"
import { useLeagueStore } from "../../../pages/league/store/useLeagueStore.js"
import { LeaguePath } from "../../../routes/ApplicationPaths/LeaguePaths.js"
import ButtonCta from "../../Buttons/button/button-cta"
import Preloader from "../../loaders/preloader/preloader.jsx"
import StartCountDown from "../StartCountDown/StartCountDown"
import VersusDisplay from "../VersusDisplay/VersusDisplay"

import "./NextMatch.scss"

export default function NextMatch({ match, noCalendar, firstGame }) {
    const navigate = useNavigate()
    const location = useLocation()
    const [ isLoading, setIsLoading ] = useState(false)
    const { selectedLeagueTeam, setShouldRevalidate, setCurrentGame, setSelectedTeamData } = useLeagueStore()
    const userLoaded = useSelector((state) => state.userLoaded)

    if (!match) {
        return (
            <div className="next-match no-match">
                <h2 className="title">{noCalendar ? "Prochain match" : "saison terminée"}</h2>
                <p className="no-match">{noCalendar ? "Le calendrier sera bientôt disponible." : "Tous les matchs sont passés ! Réinscris sans attendre ton" +
                    " équipe pour la prochaine saison..."}</p>
                {!noCalendar && (
                    <ButtonCta
                        text="Nouvelle inscription"
                        onClick={() => {
                            navigate(LeaguePath.newLeague)
                        }}
                    />
                )}
            </div>
        )
    }

    const changeUserStatus = async (status) => {
        setIsLoading(true)
        const res = await changeGamePlayerStatus(match?.id, userLoaded.contactId, status, selectedLeagueTeam.id)

        if (res?.data?.players) {
            const newMatch = match
            newMatch.currentUserStatus = status

            setCurrentGame({})
            setSelectedTeamData(prev => ({
                gameSummary: {
                    ...prev.gameSummary,
                    nextGame: newMatch,
                },
            }))
            setShouldRevalidate(true)
        }
        setIsLoading(false)
    }
    
    return (
        <div className="next-match">
            <h2 className="title">{firstGame ? "Nouvelle saison" : "Prochain match"}</h2>
            {firstGame && (
                <span>Tiens toi prêt pour ton premier match.</span>
            )}

            <VersusDisplay
                big
                thunder
                team1={match?.localTeam}
                team2={match?.visitorTeam}
            />

            <div className="start">
                <img src="/assets/icons/calendar-orange.svg" className="date" />
                <span>{dayjs(match?.date).format("dddDD/MM/YYYY")}</span>
                <img src="/assets/icons/heure-orange.svg" className="hour" />
                <span>{dayjs(match?.date).format("HH:mm")}</span>
            </div>

            {isGameForfeit(match) ? (
                <div className="forfeitMessage">Forfait</div>
            ) : (
                <StartCountDown date={match?.date}/>
            )}

            {match?.currentUserStatus === 0 && !isGameForfeit(match) ? isLoading ? <Preloader small cls={"game-loader"}/> : (<div className="buttonsWrapper">
                <ButtonCta
                    text="absent"
                    icon="/assets/icons/decline.svg"
                    isCta2Alt
                    onClick={() => changeUserStatus(2)}
                />
                <ButtonCta
                    text="présent"
                    icon="/assets/icons/accept.svg"
                    onClick={() => changeUserStatus(1)}
                />
            </div>) : (
                <ButtonCta text="Feuille de match" onClick={() => navigateToGameDetails(navigate, selectedLeagueTeam.id, match.id, {
                    from: location.pathname,
                })} />
            )}
        </div>
    )
}

NextMatch.propTypes = {
    firstGame: bool,
    match: object,
    noCalendar: bool,
}
