import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock-upgrade"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Navigate, Outlet, useLocation, useSearchParams } from "react-router-dom"

import FirstConnection from "./components/firstConnection/FirstConnection.jsx"
import Footer from "./components/layout/footer/footer"
import Preloader from "./components/loaders/preloader/preloader"
import MenuBuilder from "./components/menu/menuBuilder"
import OverlayMenu from "./components/menu/overlayMenu/overlay_menu"
import OverlayProfileMenu from "./components/menu/overlayProfileMenu/overlayProfileMenu"
import { useTheme, useThemeInitialized, useThemeUpdate } from "./components/ThemeContext/ThemeContext.jsx"
import appConfig from "./config.json"
import Header from "./entries/main/componentsContainers/header"
import OverlayConfirmation from "./entries/main/componentsContainers/overlay_confirmation"
import OverlayLevel3 from "./entries/main/componentsContainers/overlay_level3"
import useRedirections from "./hooks/routing/redirection/useRedirections"
import useAuth from "./hooks/useAuth"
import useCookies from "./hooks/useCookies"
import useIsMobile from "./hooks/useIsMobile"
import useThemeFeatures from "./hooks/useThemeFeatures"
import UpdateApp from "./pages/UpdateApp/UpdateApp"
import ModalHandler from "./providers/Modal/ModalHandler.jsx"
import RouterManager from "./routes/RoutesMapping.jsx"
import usePath from "./routes/services/usePath"
import { sendNotifTokenToOzmo } from "./services/handleAppToken"

function App() {
    const [ customRedirection, setCustomRedirection ] = useState(null)
    const [ firstConnectionCompleted, setFirstConnectionCompleted ] = useState(false)

    const [ queryParameters ] = useSearchParams()
    const setTheme = useThemeUpdate()

    const isMobile = useIsMobile()
    const auth = useAuth()
    const location = useLocation()
    const { isWebview, isAppV2 } = useCookies()
    const [ themeInitialized ] = useThemeInitialized()

    const userLoaded = useSelector(state => state.userLoaded)
    const path = usePath()
    const themeFeatures = useThemeFeatures()
    const theme = useTheme()
    const [ searchParams ] = useSearchParams()

    const { isLoading } = useRedirections()

    const openFirstConnectionPopin = () => {
        ModalHandler.show(FirstConnection, {
            currentUser: userLoaded,
        }).then(() => {
            setTheme(theme)
            setFirstConnectionCompleted(true)
            clearAllBodyScrollLocks()
        })
        disableBodyScroll(document.getElementsByClassName(".skylight-first-connection .modal-content"), { allowTouchMove: () => true })
    }

    useEffect(() => {
        document.body.classList.remove("orientation-portrait")
        document.body.classList.remove("orientation-landscape")
        document.body.classList.add("orientation-" + (isMobile ? "portrait" : "landscape"))
    }, [ isMobile ])

    const params = []

    for (let entry of searchParams.entries()) {
        params.push(entry)
    }

    useEffect(
        () => {
            if (userLoaded?.id && isWebview) {sendNotifTokenToOzmo(userLoaded.id)}

            if (userLoaded?.firstConnection && !firstConnectionCompleted) {
                openFirstConnectionPopin()
            }
        }, [ userLoaded, isWebview ],
    )

    useEffect(() => {
        const urlTheme = searchParams?.get("theme")
        if (urlTheme && !themeInitialized) {
            setTheme(urlTheme)
        }
    }, [ theme ])

    useEffect(
        () => {
            const action = queryParameters?.get("action")
            if (action) {
                const urlTheme = queryParameters?.get("theme")
                const urlPrefixe = urlTheme && urlTheme === "theme-padel" ? "/padel" : ""
                if (action === "leagueSubscription")  {return setCustomRedirection(urlPrefixe + "/urbanleague/new")}
                if (action === "kidsStageSubscription")  {return setCustomRedirection(themeFeatures([ "/kids/stagesDeFoot/new", "/padel/coursStages/inscription" ]))}
                if (action === "kidsSchoolSubscription") {setCustomRedirection(urlPrefixe + `/?action=kidsSchoolSubscription&center=${queryParameters?.get("center")}&type=${queryParameters?.get("type")}`)}
            } else {setCustomRedirection(null)}
        }, [ queryParameters ],
    )

    if (!isAppV2 && isWebview) {
        return <UpdateApp isMaintenanceMode={import.meta.env.VITE_WEBVIEW_MAINTENANCE_MODE === "true"}/>
    }

    return (
        <RouterManager>
            {(auth === null || isLoading) && <Preloader fixed cls={"opaque fullPage"} />}
            {!isAppV2 && (<div className="mobileMenu show-st">
                {auth && <Header homeUrl={appConfig.homeUrl}/>}
            </div>)}

            <div className="desktopMenu hide-st">
                {auth && <MenuBuilder />}
            </div>

            <main id="content" className={classNames("content", {
                "isAppV2": isAppV2,
                "webview": isWebview || isAppV2,
            })}>
                {auth !== null  && (
                    auth ?
                        (
                            themeInitialized ?
                                <Outlet/>
                                : <Preloader fixed/>
                        )
                        : (
                            <Navigate
                                to={
                                    searchParams?.get("theme") === "theme-padel" ?
                                        "/padel/login"
                                        :
                                        path("/login")
                                }
                                state={{
                                    afterLoginUrl:  customRedirection ?? location.pathname + window.location.search,
                                }}
                            />
                        )
                )}
            </main>

            <OverlayLevel3/>
            <OverlayConfirmation/>
            {auth && !isAppV2 && (
                <>
                    <OverlayMenu />
                    <OverlayProfileMenu/>

                    <Footer customFooterClass={classNames({
                        hide: isWebview && isAppV2,
                    })}/>
                </>
            )}
        </RouterManager>
    )
}

export default App
