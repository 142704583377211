import { func } from "prop-types"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getLocationPaymentScreenAdvertising } from "./api"
import ButtonCta from "../../../../components/Buttons/button/button-cta"
import PlayerLine from "../../../../components/reservation/PlayerLine/PlayerLine"
import { useIsWebview, useTheme } from "../../../../components/ThemeContext/ThemeContext"
import { getArdoises, printBill, printCommand, printCreditPrepayment, printPrepayment } from "../../../../globalAPI/api"
import useResetScroll from "../../../../hooks/useResetScroll"
import usePath from "../../../../routes/services/usePath"
import alphabeticalPlayerSort from "../../../../services/Sorting/alphabeticalPlayerSort"
import { useLocationStore } from "../../store/store.js"

import "./LocationPaymentsManagement.scss"

function LocationPaymentsManagement() {
    useResetScroll()
    const { currentLocation: location, ads, setAds, actionOpenPaymentModal } = useLocationStore()
    const path = usePath()
    const [ locationHasArdoise, setLocationHasArdoise ] = useState(false)

    const { price, amountDue, discount, prepayments = [], preCreditAllocations = [] } = location
    const total = discount ? price - discount.discount : price
    const paid = total - amountDue
    const userLoaded = useSelector(state => state.userLoaded)
    const theme = useTheme()
    const isWebview = useIsWebview()

    // Merging prepayments and preCreditAllocations
    const payments = [ ...prepayments, ...preCreditAllocations ]
        .filter(payment => payment.cancelationDate === null || payment.cancelationDate === undefined)
        .sort((a, b) => a.buyerName.localeCompare(b.buyerName))

    const didNotPayPlayers = amountDue > 0 ?
        location.invites.filter(
            invite => (
                invite.status === 1
            ) && payments.findIndex(
                payment => payment.contact.id === invite.playerId,
            ) === -1,
        ).sort(alphabeticalPlayerSort)
        :
        []

    const comingGame = new Date() < new Date(location.start)
    
    const navigate = useNavigate()
    const hasArdoises = useSelector(state => state.hasArdoises)
    const paidPercentage = paid === 0 ? 0 : (paid * 100 / total).toFixed(2)
    const haveDiscount = location?.discount?.code
    const userPaid = payments?.find(payment => payment.contact?.id === userLoaded.contactId)

    const download = async (entity, type, isCredit) => {
        type === "bill" ?
            await printBill(entity.id)
            :
            type === "command" ?
                await printCommand(entity.id)
                :
                isCredit ? await printCreditPrepayment(entity?.paymentId) : await printPrepayment(entity.id)
    }

    let progressCursorStyle = {
        left: paidPercentage + "%",
    }
    let progressLabelStyle = {
        ...progressCursorStyle,
    }
    if (paidPercentage < 25) {
        progressLabelStyle.transform = `translate(0%, -100%) ${theme === "theme-padel" ? "skew(10deg)" : ""}`
    } else if (paidPercentage > 75) {
        progressLabelStyle.transform = `translate(-100%, -100%)  ${theme === "theme-padel" ? "skew(10deg)" : ""}`
    }

    useEffect(() => {
        if (theme !== ads?.theme || !ads) {
            getLocationPaymentScreenAdvertising(theme).then(
                (res) => {
                    setAds({
                        ...res,
                        theme: theme,
                    })
                },
            )
        }
        return () => {}
    }, [ theme ])

    useEffect(
        () => {
            if (hasArdoises) {getArdoises().then(res => {
                if (res.hitCount && res.items.find(ardoise => ardoise.reservationId === location.id)) {setLocationHasArdoise(true)}
            })}
        }, [ hasArdoises, location?.id ],
    )

    return (
        <div className="location-payments-management">
            {comingGame || (amountDue > 0 && !locationHasArdoise) ? (
                <>
                    <div className="location-progress-bar">
                        <progress
                            max={total}
                            value={paid}
                        >
                            {paid}
                        </progress>
                        <p className={paid === total ? "progress-label fully-paid" : "progress-label"}
                            style={progressLabelStyle}
                        >
                            {(paid % 1 === 0 ? paid : paid.toFixed(2)) + `€ payé${paid > 1 ? "s" : ""}` }
                        </p>
                        <p className={paid === total ? "progress-total fully-paid" : "progress-total"}>{(total % 1 === 0 ? total : total.toFixed(2)) + "€ TOTAL"}</p>
                        <p className="progress-cursor"
                            style={progressCursorStyle}>
                        </p>
                    </div>
                    {amountDue === 0 && <p className="noDue">Tout est en règle, hâte de te revoir sur nos terrains ! </p>}
                </>
            ) : locationHasArdoise ? (
                <div className="ardoise">
                    <p>
                        La réservation n'est pas entièrement payée. C’est assez inhabituel pour être souligné. A toi de jouer !
                    </p>
                    <ButtonCta
                        color="orange"
                        text="Régler mon ardoise"
                        onClick={() => {
                            navigate(path("/profil/ardoises"))
                        }}
                    />
                </div>
            ) : (
                <div className="facture">
                    <p>Tout est en règle, hâte de te revoir sur nos terrains ! </p>
                    { (location?.isOwner && (location?.prepayments?.find(payment => !payment.cancelationDate && userLoaded.contactId === payment.contact.id)?.billId || location?.commandId)) && (
                        <ButtonCta
                            color="orange"
                            text="Télécharger la facture"
                            onClick={() => {
                                const billId = location?.prepayments?.find(payment => !payment.cancelationDate && userLoaded.contactId === payment.contact.id)?.billId
                                const downloadId =  {
                                    id: billId || location?.commandId,
                                }
                                if (!isWebview && downloadId) {download(downloadId, billId ? "bill" : "command")}
                                else {navigate(path("/profil/factures"))}
                            }}
                        />
                    )}
                </div>
            )}

            {(amountDue !== 0 && comingGame && (!haveDiscount || (haveDiscount && (!userPaid)))) && (
                location.acceptPrePayment ? (
                    <ButtonCta
                        isAlt
                        text="Payer"
                        onClick={actionOpenPaymentModal}
                    />
                ) : (
                    <div className="noPrepayment">
                        Ta réservation est proche !<br />
                        Le paiement en ligne n'est plus disponible.<br />
                        Règle à l'accueil de ton centre.
                    </div>)
            )}
            {(payments?.filter(payment => !payment.cancelationDate)?.length > 0 || didNotPayPlayers?.length > 0) && (
                <div className="prepayments">
                    <h2 className="prepayments-title">Détails des paiements en ligne</h2>
                    {payments.filter(
                        prepayment => prepayment.contact.id === location.organizer.id || location.invites.find(
                            invite => invite.playerId === prepayment.contact.id,
                        ),
                    ).map(
                        (prepayment) => (
                            <PlayerLine
                                buyer={prepayment}
                                buyerContact={
                                    location.invites.find(
                                        invite => invite.playerId === prepayment.contact.id,
                                    )
                                }
                                download={
                                    !comingGame && userLoaded.contactId === prepayment.contact.id &&
                                (!prepayment.creditId || (prepayment.creditId && prepayment.paymentId)) ?
                                        () =>
                                            download(prepayment, "prepayment", !!prepayment?.paymentId) : null
                                }
                                key={location.id + "-" + prepayment.id + "-" + prepayment.contact.id}
                            />
                        ),
                    )}
                    {didNotPayPlayers.length > 0 && didNotPayPlayers.map(
                        player => (
                            <PlayerLine
                                buyer={player}
                                key={location.id + "-" + player.id + "-" + player.playerId}
                            />
                        ),
                    )}
                </div>
            )}
            {ads && ads.image && ads.theme === theme &&
                (<a className="paymentsAd" href={ads.url} target={ads?.newTab ? "_blank" : null} rel="noreferrer">
                    <picture>
                        <source
                            srcSet={ads.image || ads.image_mobile}
                            media="(min-width: 640px)">
                        </source>
                        <img src={ads.image_mobile ||  ads.image} alt=""/>
                    </picture>
                </a>)
            }
        </div>
    )    
}

LocationPaymentsManagement.propTypes = {
    setPaymentPopInOpen: func,
}

export default LocationPaymentsManagement
