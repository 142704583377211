import classNames from "classnames"
import { bool, oneOfType, string } from "prop-types"

import "./preloader.scss"

function Preloader({ fixed, cls, alt, small = false }) {
    return (
        <div
            className={classNames(cls, {
                "fixed-preloader": fixed,
                preloader: !fixed,
                small: small,
            })}
        >
            <div style={{ backgroundImage: `url('/assets/images/${alt ? "loader-white.svg" : "loader.svg"}')` }}></div>
        </div>
    )
}

Preloader.propTypes = {
    alt: bool,
    cls: string,
    fixed: oneOfType([ bool, string ]),
    small: bool,
}

export default Preloader
