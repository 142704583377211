import PropTypes from "prop-types"
import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import { navigateToPlayerPage, removeLeadingSpaces } from "../../../pages/league/service/service.js"
import { mapToObject, sortTableData } from "../../../utilities/helpers.js"
import ButtonCta from "../../Buttons/button/button-cta.jsx"
import Table from "../Table/Table"

import "./ScorerRanking.scss"

export default function ScorerRanking({ isLoading, data }) {
    const navigate = useNavigate()
    const [ showAll, setShowAll ] = useState(false)

    const sortedScorers = useMemo(() => {
        return data ? sortTableData(data, { key: "goals", order: "DESC" }, { key: "firstname", order: "ASC" }) : []
    }, [ data ])

    const onPlayerClick = (player, hash = null) => {
        navigateToPlayerPage(
            navigate,
            player?.championshipPlayerId,
            hash,
            player.championshipTeamId,
        )
    }

    const columns = [
        {
            align: "left", name: "name", render: (row) => {
                const player = mapToObject(row?.values)
                const firstName = removeLeadingSpaces(player?.firstname)

                return (
                    <div className={"playerLine"}
                        onClick={() => onPlayerClick(player)}>
                        {firstName ?
                            <span>{firstName} {player?.lastname.slice(0, 1) + "."}</span> :
                            <span>{player?.lastname}</span>
                        }
                        {player?.teamName && <span className={"teamName"}>{player?.teamName}</span>}
                    </div>
                )
            },
        },
        { align: "center", name: "goals" },
        {
            align: "center", name: "videos", render: (row) => {
                const player = mapToObject(row?.values)

                if (!player?.videos.length) {
                    return
                }
                return (
                    <a
                        onClick={() => onPlayerClick(player, "videos")}
                    >
                        <img alt="goals button" src="/assets/icons/filmed.svg"/>
                    </a>
                )
            },
        },
    ]

    const hasVideo = sortedScorers?.some((row) => row?.values?.get("videos").length)
    if (!hasVideo) {
        columns.pop()
    }

    if (isLoading || !sortedScorers?.length) {
        return
    }

    const displayedScorers = showAll ? sortedScorers : sortedScorers.slice(0, 10)

    return (
        <div className={"scorersWrapper"}>
            <Table
                customClass="scorerRanking"
                title="buteurs"
                columns={columns}
                columnDisplayNames={columnsDisplayNames}
                dataList={displayedScorers}
                animateRows
            />
            {sortedScorers.length > 10 && (
                <ButtonCta onClick={() => setShowAll(!showAll)} text={showAll ? "Voir moins" : "Voir plus"}/>
            )}

        </div>
    )
}

const columnsDisplayNames = new Map([
    [ "name", "joueur" ],
    [ "goals", "B" ],
    [ "videos", "Vidéos" ],
])

ScorerRanking.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.object,
}
