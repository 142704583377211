import classNames from "classnames"
import dayjs from "dayjs"
import { bool, func, object } from "prop-types"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import {
    cancelSuperSubResponse,
    rateOrganizer,
} from "./api"
import LevelStars from "../../../components/SuperSub/SuperSubRequest/LevelStars"
import Preloader from "../../../entries/main/../../components/loaders/preloader/preloader"
import { createFriendshipInvitationFromEmail } from "../../../globalAPI/api"
import useThemeHeader from "../../../hooks/useThemeHeader"
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import superSubLevels from "../../../model/enums/superSubLevels"
import usePath from "../../../routes/services/usePath"
import getBaseUrl from "../../../services/getBaseUrl"
import ButtonCta from "../../Buttons/button/button-cta"
import TextArea from "../../Forms/textArea/TextArea"
import HourDisplay from "../../Time/HourDisplay"
import RatingComponent from "../RatingComponent/RatingComponent"

import "./MatchBlock.scss"

function MatchBlock({ match, alreadyPlayed = false, selectMatch, isSelected, areFriends }) {
    const [ thisMatch, setThisMatch ] = useState(match)
    const { id, request, isRatingActivated, organizerRating, status } = thisMatch
    const { level, price, organizer, reservation, isFree } = request
    const { phoneNumber, emailAddress, firstname, lastname, pictureUrl } = organizer ? organizer : {}
    const { centerName, duration, start, end, resourceTypeDisplay } = reservation ? reservation : {}

    const { windowWidth } = useWindowDimensions()

    let startDate, endDate, startDataString, hour
    const today = new Date()
    if (start) {
        startDate = new Date(start)
        startDataString = dayjs(startDate).format("ddd DD/MM")
        hour = <HourDisplay date={startDate} displayZero={true}/>
    }
    if (end) {
        endDate = new Date(end)
    }

    const unrated = organizerRating === null
    const path = usePath()
    const themeHeader = useThemeHeader()

    const [ comment, setComment ] = useState("")
    const [ note, setNote ] = useState((isRatingActivated && unrated) ? 0 : organizerRating)
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ displayContactNumber, setDisplayContactNumber ] = useState(false)
    const [ invitationSent, setInvitationSent ] = useState(false)
    const [ customMessage, setCustomMessage ] = useState(undefined)

    /* ACTIONS */
    const navigate = useNavigate()

    function cancelSub() {
        setAjaxLoading(true)
        cancelSuperSubResponse(id).then(
            () => {
                navigate(path("/confirmcommand"), {
                    state: {
                        customContentIdentifier : "findMatch",
                        customLink: "/supersub/findMatch",
                        message: "Ta demande a bien été annulée. Si tu as effectué un paiement, tu ne seras pas débité.",
                        subtitle: null,
                        success: true,
                        title: "Demande annulée",
                        tunnelType: "SUPERSUB",
                    },
                })
                setAjaxLoading(false)
            },
        )
    }

    const formatPhoneNumber = (number) =>  number[0] === "+" ?
        number.substring(0,3) + " " + number.substring(3,4) + " " + number.substring(4,6) + " " + number.substring(6,8) + " " + number.substring(8,10) + " " + number.substring(10,12)
        : number.substring(0,2) + " " + number.substring(2,4) + " " + number.substring(4,6) + " " + number.substring(6,8) + " " + number.substring(8,10)

    function rateMatch() {
        setAjaxLoading(true)
        rateOrganizer(id, note, comment).then(
            (res) => {
                setThisMatch(res?.data?.data)
                setAjaxLoading(false)
                setCustomMessage("Ta note a bien été prise en compte")
                setTimeout(
                    () => {
                        setCustomMessage(undefined)
                    },
                    10000,
                )
            },
        )
    }

    function addToFriendList() {
        /* istanbul ignore else */
        if (!invitationSent && !ajaxLoading) {
            setAjaxLoading(true)
            createFriendshipInvitationFromEmail(
                emailAddress,
                getBaseUrl(),
                null,
                themeHeader,
            ).then(
                () => {
                    setInvitationSent(true)
                    setAjaxLoading(false)
                },
            )
        }
    }

    const organizerNameAndStatus = (
        <span className="organizerNameAndStatus">
            {(lastname && firstname) && (
                <span className="organizerName">
                    {firstname} {lastname.substring(0, 1)}.
                </span>
            )}
            { today < endDate &&
                (<em className="matchStatus">
                    {status === 0 &&
                        "Demande en cours"
                    }
                    {status === 1 &&
                        "Demande validée"
                    }
                </em>)
            }
        </span>
    )

    return (
        <div
            id={
                "matchblock-" + thisMatch.id
            }
            className={
                "o-match-block" +
                ((alreadyPlayed && isRatingActivated && unrated) ?
                    " unrated"
                    :
                    ""
                )  +
                (isSelected && isRatingActivated && unrated ? " selected" : "")
            }
            data-testid="matchBlock"
            onClick={() => {
                if (selectMatch && isRatingActivated && unrated) {
                    selectMatch(match.id)
                }
            }}
        >
            { ajaxLoading &&
                /* istanbul ignore next */
                <Preloader fixed />
            }
            <div className="top">
                <div className="avatar">
                    <img src={pictureUrl ? pictureUrl : "/assets/images/empty-avatar.svg"}/>
                    {
                        windowWidth <= 900
                    &&
                        organizerNameAndStatus
                    }
                </div>
                <div className="o-match-block__info">
                    {
                        windowWidth > 900
                    &&
                        organizerNameAndStatus
                    }
                    <div className="reservationInfos">
                        {
                            windowWidth > 900 ?
                                (<>
                                    <span>
                                        <img src="/assets/icons/center-orange.svg"/>
                                        { centerName }
                                    </span>

                                    <span>
                                        <img src="/assets/icons/heure-orange.svg"/>
                                        { hour }
                                    </span>

                                    <span className="stars-span">
                                        <LevelStars
                                            level={level}
                                            isStrokeStars={true}
                                        />
                                        { superSubLevels[level] }
                                    </span>

                                    <span>
                                        <img src="/assets/icons/terrain-orange.svg"/>
                                        { resourceTypeDisplay }
                                    </span>

                                    <span>
                                        <img src="/assets/icons/chrono-orange.svg"/>
                                        { duration &&
                                                duration.split(" ").join("").replace("heures", "h").replace("heure", "h")
                                        }
                                    </span>

                                    <span>
                                        <img src="/assets/icons/euro.svg"/>
                                        { isFree ?  "Offert" : price + " €" }
                                    </span>

                                    <span>
                                        <img src="/assets/icons/calendar-orange.svg"/>
                                        <span className="date">{ startDataString }</span>
                                    </span>
                                </>)
                                :
                                (<>
                                    <span>
                                        <img src="/assets/icons/center-orange.svg"/>
                                        { centerName }
                                    </span>

                                    <span>
                                        <img src="/assets/icons/calendar-orange.svg"/>
                                        <span className="date">{ startDataString }</span>
                                    </span>

                                    <span>
                                        <img src="/assets/icons/terrain-orange.svg"/>
                                        { resourceTypeDisplay }
                                    </span>

                                    <span>
                                        <img src="/assets/icons/heure-orange.svg"/>
                                        { hour }
                                    </span>

                                    <span className="stars-span">
                                        <LevelStars
                                            level={level}
                                            isStrokeStars={true}
                                        />
                                        { superSubLevels[level] }
                                    </span>

                                    <span>
                                        <img src="/assets/icons/chrono-orange.svg"/>
                                        { duration && duration.split(" ").join("").replace("heure", "h")}
                                    </span>

                                    <span>
                                        <img src="/assets/icons/euro.svg"/>
                                        { price } &euro;
                                    </span>
                                </>)
                        }

                    </div>
                </div>
                <div className="actions">
                    { today > endDate ? (
                        <RatingComponent
                            label={!isRatingActivated ? "NOTATION IMPOSSIBLE" : unrated ? "NOTE TON MATCH" : "MATCH NOTÉ"}
                            initialValue={note}
                            disabled={!isRatingActivated || !unrated}
                            customMessage={customMessage}
                            updateRatingCallback={
                                (val) => {
                                    setNote(val)
                                }
                            }
                        />
                    ) : (
                        <>
                            {status === 0 && (
                                <ButtonCta
                                    text="Annuler"
                                    isAlt
                                    onClick={cancelSub}
                                />
                            )}
                            {status === 1 && (
                                <>

                                    <ButtonCta
                                        customCls={classNames("button", { sent: invitationSent })}
                                        onClick={invitationSent ? undefined : addToFriendList}
                                        text={areFriends ?
                                            "Tu es déjà ami(e)s" :
                                            invitationSent ?
                                                "Demande d'ami envoyée"
                                                : "Ajouter à la liste des amis"
                                        }
                                        disabled={areFriends}
                                        isCta2Alt
                                    />
                                    <ButtonCta
                                        customCls="button"
                                        noHover={displayContactNumber}
                                        onClick={
                                            () => {
                                                setDisplayContactNumber(true)
                                            }
                                        }
                                        link={
                                            displayContactNumber ?
                                                "tel:" + phoneNumber
                                                :
                                                undefined
                                        }
                                        text={
                                            displayContactNumber ?
                                                formatPhoneNumber(phoneNumber)
                                                :
                                                "Contacter"
                                        }
                                        isCta2Alt
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            { (today > endDate && isRatingActivated && unrated) &&
                (<div className="bottom">
                    <TextArea
                        isEmpty={comment.length === 0}
                        className="comment-textarea"
                        labelText="Commentaire"
                        maxLength="500"
                        onChange={setComment}
                        isRequired={note <= 3}
                        value={comment}
                        placeholder=""
                    />
                    <ButtonCta 
                        text="Valider"
                        
                        onClick={rateMatch}
                        disabled={
                            (note <= 3 && comment.length < 3)
                            || !note
                        }
                    />
                </div>)
            }
        </div>
    )
}

MatchBlock.propTypes = {
    alreadyPlayed: bool,
    areFriends: bool,
    isSelected: bool,
    match: object,
    selectMatch: func,
}

export default MatchBlock
