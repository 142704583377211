import classNames from "classnames"
import dayjs from "dayjs"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom"

import { checkGymlibCodeValidity } from "./api.js"
import BookingInfo from "./BookingInfo"
import GymlibLine from "./components/GymlibLine/GymlibLine"
import PartsSelection from "./components/PartsSelection/PartsSelection"
import useIsPaymentEnabled from "./hooks/useIsPaymentEnabled"
import getReturnText from "./services/getReturnText"
import getType from "./services/getType"
import ButtonCta from "../../../components/Buttons/button/button-cta"
import CurrencyDisplay from "../../../components/currencies/CurrencyDisplay"
import RadioGroup from "../../../components/Inputs/Radio/RadioGroup"
import { TextInput } from "../../../components/Inputs/TextInput/TextInput.jsx"
import Preloader from "../../../components/loaders/preloader/preloader"
import { ErrorModal } from "../../../components/Modal/ErrorModal/ErrorModal.jsx"
import CbAliasError from "../../../components/popin/CbAliasError/CbAliasError"
import PhoneCallHelp from "../../../components/popin/help/phoneCall"
import playersNumber from "../../../components/reservation/LocationBlock/playersNumber"
import Step from "../../../components/stepsManagement/Step"
import AvoirCheckbox from "../../../entries/main/contents/main/helpers/AvoirCheckbox"
import { deleteCreditCard, getCreditCard, getShareCalculation, getUsCentersBookable } from "../../../globalAPI/api"
import useAddDiscountCode from "../../../hooks/reservation/useAddDiscountCode"
import useProceedCheckout from "../../../hooks/reservation/useProceedCheckout"
import useRemoveDiscountCode from "../../../hooks/reservation/useRemoveDiscountCode"
import useUrbanLanguage from "../../../hooks/urbanLanguage/useUrbanLanguage"
import useIsMobile from "../../../hooks/useIsMobile.js"
import useMediaPath from "../../../hooks/useMediaPath"
import useResetScroll from "../../../hooks/useResetScroll"
import useThemeFeatures from "../../../hooks/useThemeFeatures"
import useThemeHeader from "../../../hooks/useThemeHeader"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import usePath from "../../../routes/services/usePath"
import UserAgentHandler from "../../../services/UserAgentHandler"
import { setFormEntry } from "../../../store/forms/actions"
import { resetNativeHistoryToCurrentLocation } from "../../../utilities/helpers"
import {
    checkAvailabilities,
    extendPreBookValidity,
    getPreBook,
    modifyPreBookMaxAttendees,
    preBook,
} from "../../reservation/tunnelReservation/api"
import { getDiscountCode, getTypeName } from "../../reservation/tunnelReservation/function"
import {
    durationNumber,
    durationString,
    formArgs,
} from "../../reservation/tunnelReservation/NewBookingTunnel/services/function"
import { useBookingStore } from "../../reservation/tunnelReservation/NewBookingTunnel/services/store"
import sortCenters from "../../reservation/tunnelReservation/services/sortCenters"
import PayWithUsCard from "../PayWithUsCard/PayWithUsCard"

import "./OrderSummary.scss"
import "./NewBookingOrderSummary.scss"

export const  NewBookingOrderSummary = () => {
    useResetScroll()
    const { preBookingId } = useParams()
    const { tu } = useUrbanLanguage()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const themeFeatures = useThemeFeatures()
    const mediaPath = useMediaPath()
    const isMobile = useIsMobile()
    const themeHeader = useThemeHeader()
    const path = usePath()
    const tunnelType = "BOOKING"
    const {
        setFromOrderSummary,
        summaryData,
        setSummaryData,
        shouldTriggerSlotNotification,
    } = useBookingStore()
    const preReservation = summaryData?.preReservation
    const help = <PhoneCallHelp tunnelType={tunnelType}/>

    const cgvUrl = themeFeatures([ "https://www.urbansoccer.fr/cgv-urbansoccer/","https://www.urbanpadel.fr/mentions-legales/cgv-urbanpadel/ " ])

    const promoCodeInput        = useRef(null)
    const step                  = useRef(null)

    // ## Store Values
    const avoirFormData = useSelector(state => state.forms.avoirFormData)
    const hasArdoises = useSelector(state => state.hasArdoises)

    const freeCancelDate = preReservation ? dayjs(preReservation.start).add(-2, "day").format("DD/MM/YY HH[h]mm") : null
    const freeCancelDatePassed = preReservation && dayjs(preReservation.start).add(-2, "day").isBefore(dayjs())
    const players = preReservation?.maxAttendees ?? playersNumber.find(type => type.types.includes(preReservation?.resourceType))?.players

    const alreadyHaveDiscount = preReservation && !!preReservation.discount
    // ## State
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ codeError, setCodeError ] = useState(false)
    const [ promoCode, setPromoCode ] = useState("")
    const [ cbCard, setCbCard ] = useState(null)
    const [ isUsingUserCB, setIsUsingUserCB ] = useState(false)
    const [ isUsingUserCB2, setIsUsingUserCB2 ] = useState(false)
    const [ paymentEnabled, setPaymentEnabled ] = useState(false)
    const [ saveCard, setSaveCard ] = useState(false)
    const [ duration, setDuration ] = useState(null)
    const [ timeDisplay, setTimeDisplay ] = useState(null)
    const [ specialLoading, setSpecialLoading ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ showAcceptCGV, setShowAcceptCGV ] = useState(false)
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ haveDiscountFromPreviousStep, setHaveDiscountFromPreviousStep ] = useState(false)
    const [ firstSubscription, setFirstSubscription ] = useState(null)
    const [ amountTotal, setAmountTotal ] = useState(0)
    const [ promoCodeDiscount, setPromoCodeDiscount ] = useState(0)
    const [ totalTVA, setTotalTVA ] = useState(0)
    const [ firstSubHasDiscountCode, setFirstSubHasDiscountCode ] = useState(false)
    const [ partsPlayers, setPartsPlayers ] = useState({ part: "", players: players })
    const [ priceToPay, setPriceToPay ] = useState(preReservation?.price)
    const [ shouldRecalculate, setShouldRecalculate ] = useState(false)
    const [ pricePerPart, setPricePerPart ] = useState(null)
    const [ pricePerPartCache, setPricePerPartCache ] = useState({})
    const [ isGymlib, setIsGymlib ] = useState("")
    const [ gymlibCode, setGymlibCode ] = useState("")
    const [ validGymlibCode, setValidGymlibCode ] = useState({
        code: "",
        payout: 0,
    })

    const haveErrorMessage = preReservation?.Message
    const radioOptions = [
        {
            label: "J'ai un code promo",
            value: "notGymlib",
        },
        {
            label: <GymlibLine />,
            value: "isGymlib",
        },
    ]
    // ## Hooks
    const addCodeToSub = useAddDiscountCode(tunnelType)
    const deleteCodeFromSub = useRemoveDiscountCode(tunnelType)

    const isPaymentEnabled = useIsPaymentEnabled(tunnelType, paymentEnabled)

    const {
        proceedCheckout,
        payzenForm,
    } = useProceedCheckout(
        {
            askRegisterPay: isUsingUserCB2,
            customData: firstSubscription ?
                {
                    centerId: firstSubscription.centerId,
                    haveDiscountFromPreviousStep: haveDiscountFromPreviousStep,
                    id: firstSubscription.id,
                    start: firstSubscription.start,
                }
                :
                null,
            firstSubscription: firstSubscription,
            isUsingUserCB: isUsingUserCB,
            lyraPaymentUrl: path("/reserver/paiement/" + firstSubscription?.id),
            setAjaxLoading: setAjaxLoading,
            tunnelType: tunnelType,
        },
    )

    useEffect(() => {
        if (hasArdoises) {
            onReturn()
        }
    }, [ hasArdoises ])

    // use initialize order summary for tunnelType
    useEffect(
        () => {
            if (preReservation) {
                setDuration(preReservation.duration + " - " + getTypeName(preReservation.resourceType, preReservation.resourceTypeDisplay))
                setTimeDisplay(dayjs(preReservation.start).format("ddd DD/MM - HH[h]mm"))
                setFirstSubscription(preReservation)
                setAmountTotal(preReservation.amountDue)
                setTotalTVA(preReservation.taxes)
            }
            return () => {}
        }, [ preReservation ],
    )

    const isResultOK = (res) => {
        if (!res || res?.Message) {return false}
        return res
    }

    const addCode = () => {
        addCodeToSub(firstSubscription.id, promoCode.toUpperCase()).then(
            (res) => {
                if (isResultOK(res)) {
                    handleRegistrationUpdateResult(res)
                    setCodeError(false)
                } else {
                    setCodeError(true)
                }
                setAjaxLoading(false)
            }, () => {
                setCodeError(true)
                setAjaxLoading(false)
            },
        )
    }

    const checkGymlibCode = () => {
        setAjaxLoading(true)
        checkGymlibCodeValidity(gymlibCode, preBookingId, partsPlayers.players).then(
            (res) => {
                if (res?.status === 200 && res?.data?.data?.data?.payout !== undefined) {
                    setValidGymlibCode({
                        code: gymlibCode,
                        payout: res?.data?.data?.data?.payout,
                    })
                    setCodeError(false)
                } else if (res?.status === 403 && res?.data?.data?.Code === 204) {
                    ModalHandler.show(ErrorModal, {
                        message: "Ta préréservation a expiré, tu peux recommencer ta réservation en cliquant sur le" +
                            " bouton.",
                        redirectionText: "Réserver",
                        redirectionUrl: path("/reserver"),
                        title: "Préréservation expirée",
                    }).then(() => navigate(path("/reserver")))
                } else {
                    setValidGymlibCode(null)
                    setCodeError(true)
                }
            }).finally(() => setAjaxLoading(false))
    }

    useEffect(() => {
        if (validGymlibCode?.code) {
            checkGymlibCode()
        }
    }, [ partsPlayers ])

    const deleteGymlibCode = () => {
        setGymlibCode("")
        setIsGymlib("")
        setPromoCode("")
        setValidGymlibCode(null)
        setCodeError(false)
    }

    function addPromoCode() {
        setAjaxLoading(true)
        if (avoirFormData?.totalWithAvoir !== undefined) {
            dispatch(
                setFormEntry("avoirFormData", {
                    ...avoirFormData,
                    avoirAmount: undefined,
                    checkAvoir: false,
                    totalWithAvoir: undefined,
                    tvaWithAvoir: undefined,
                }),
            )
        }
        if (preReservation) {
            extendPreBookValidity(preReservation.id)
        }
        if (firstSubscription.discountCodes && firstSubscription.discountCodes.length > 0) {
            let hasNoOldCode = true
            firstSubscription.discountCodes.forEach(
                code => {
                    if (!code.secondRegistrationDiscount) {
                        hasNoOldCode = false

                        deleteCodeFromSub(firstSubscription.id, code.code).then(
                            (res) => {
                                handleRegistrationUpdateResult(res)
                                addCode()
                            },
                        )
                    }
                },
            )
            if (hasNoOldCode) {
                addCode()
            }
        } else {
            addCode()
        }
    }

    function deleteDiscountCode() {
        setAjaxLoading(true)
        if (preReservation) {
            extendPreBookValidity(preReservation.id)
        }
        deleteCodeFromSub(
            firstSubscription.id,
            promoCode,
        ).then(handleRegistrationUpdateResult)
    }

    function handleCGV(e) {
        setPaymentEnabled(e.target.checked)
    }

    function handleRegistrationUpdateResult(res) {
        let data = res.preReservation

        setSummaryData({
            preReservation: data,
        })

        setFirstSubscription(data)
        setFirstSubHasDiscountCode(res.preReservation.discount)

        dispatch(
            setFormEntry("avoirFormData", {
                ...avoirFormData,
                recalcAvoir: true,
            }))
        setAjaxLoading(false)
        setShouldRecalculate(true)
    }

    function useUserCB(e) {
        setIsUsingUserCB(e.target.checked)
        setIsUsingUserCB2(e.target.checked)
    }

    const handleSearchParams = (prebook, otherParams = {}) => {
        const params = {
            activity: searchParams.get(formArgs.ACTIVITY_ID),
            centerId: searchParams.get(formArgs.CENTER_ID),
            date: searchParams.get(formArgs.DATE),
            discountId: searchParams.get(formArgs.PREBOOK_DISCOUNT_ID),
            discountLabel: haveDiscountFromPreviousStep && prebook ? prebook?.discount?.label
                && encodeURI(prebook.discount.label) : searchParams.get(formArgs.PREBOOK_DISCOUNT_LABEL),
            time: searchParams.get(formArgs.HOUR),
            typeresource: searchParams.get(formArgs.TYPE_IDS),
            ...otherParams,
        }
        Object.keys(params).forEach((param) => !params[param] && delete params[param])
        return  params
    }

    async function onReturn() {
        setFromOrderSummary(true)
        await dispatch(setFormEntry(
            "BookingFiltersFilters", {
                summaryActivity: searchParams.get(formArgs.ACTIVITY_ID),
            },
        ))

        const params = handleSearchParams()

        navigate({
            pathname: path("/reserver"),
            search: createSearchParams(params).toString(),
        })
    }

    const handlePrebookDiscount = (availableDiscountCodes, preBook) => {
        if (preBook.discount) {
            const findDiscount = availableDiscountCodes.find(discount => discount.label === preBook.discount.label)
            if (findDiscount) {
                dispatch(
                    setFormEntry(
                        "BookingFiltersFilters", {
                            filters: {
                                discount: findDiscount,
                            },
                        },
                    ),
                )
            }
        }
    }

    const getCenters = () => {
        getUsCentersBookable(themeHeader).then(
            res => {
                if (res?.data) {
                    const tempCenters = Object.values(res.data)
                    const sortedCenters = sortCenters(tempCenters)
                    const preBook = preReservation
                    const targetCenter = res.data[preBook?.centerId]
                    const activityForType = targetCenter?.resourceTypes.find(cat => cat.key === preBook.resourceType)?.categories[0]
                    const checkAvailabilitiesData = {
                        categories: JSON.stringify([ activityForType?.id ]),
                        centerId: preBook.centerId,
                        durations: preBook.resourceType === 11 ? JSON.stringify([ 45, 90, 120 ]) : JSON.stringify([ 60, 90, 120 ]),
                        periodStart: new Date(preBook.start.split("T")[0]).toISOString(),
                    }
                    checkAvailabilities(checkAvailabilitiesData, themeHeader).then(
                        (resCheck) => {
                            if (resCheck && resCheck.length > 0 && resCheck[1] && !resCheck[1].Message) {
                                const availableDiscountCodes = getDiscountCode(resCheck[1])
                                let data = {
                                    ...summaryData,
                                    bookableCenter: sortedCenters,
                                    bookablesListing: resCheck[1],
                                    selectedActivity: activityForType,
                                }
                                handlePrebookDiscount(availableDiscountCodes, preBook)
                                setSummaryData(data)
                            }
                        },
                    )
                }
            },
        )
    }
    
    useEffect(
        () => {
            setFromOrderSummary(true)
            if (alreadyHaveDiscount) {
                setHaveDiscountFromPreviousStep(true)
            }
            if (summaryData && !summaryData.bookablesListing) {
                getCenters()
            }
            return () => {}
        }, [],
    )

    useEffect(
        () => {
            if (!haveDiscountFromPreviousStep && searchParams.get(formArgs.PREBOOK_DISCOUNT_LABEL)) {setHaveDiscountFromPreviousStep(true)}
        }, [ searchParams, haveDiscountFromPreviousStep ],
    )

    useEffect(
        () => {
            const today = dayjs().format("YYYY-MM-DD")
            if (preReservation?.discount) {
                setPromoCodeDiscount(preReservation.discount.discount)
            } else {
                setPromoCodeDiscount(0)
            }
            setSpecialLoading(true)
            if (!summaryData) {
                const params = {
                    activity: searchParams.get(formArgs.ACTIVITY_ID),
                    centerId: searchParams.get(formArgs.CENTER_ID),
                    date: searchParams.get(formArgs.DATE),
                    discountId: searchParams.get(formArgs.PREBOOK_DISCOUNT_ID),
                    discountLabel: searchParams.get(formArgs.PREBOOK_DISCOUNT_LABEL),
                    duration: searchParams.get(formArgs.PREBOOK_DURATION),
                    hour: searchParams.get(formArgs.PREBOOK_HOUR),
                    mn: searchParams.get(formArgs.PREBOOK_MN),
                    resourceType: searchParams.get("resourceType"),
                    typeresource: searchParams.get(formArgs.TYPE_IDS),
                }

                if (shouldTriggerSlotNotification) {
                    params.creneauID = searchParams.get(formArgs.SLOT_ID)
                }

                Object.keys(params).forEach((param) => !params[param] && delete params[param])
                if (preBookingId && preBookingId !== "wp" && today <= params.date) {
                    getPreBook(preBookingId).then(
                        (res) => {
                            if (res?.Message) {
                                preBook({
                                    centerId: params?.centerId,
                                    discountConfigId: params?.discountId,
                                    duration: params?.duration,
                                    preferredSlotId: params?.creneauID,
                                    resourceType: params?.typeresource,
                                    start: params?.date + "T" + params?.hour + ":" + params?.mn + ":00",
                                    theme: themeHeader,

                                }).then(
                                    (resPre) => {
                                        if (resPre?.[1]?.preReservation && (params?.date + "T" + params?.hour + ":" + params?.mn + ":00") === dayjs(resPre[1].preReservation.start).format("YYYY-MM-DDTHH:mm:ss")) {
                                            setSummaryData({
                                                preReservation: resPre[1].preReservation,
                                            })
                                            navigate({
                                                pathname: path(`/reserver/${resPre[1].preReservation.id}`),
                                                search: createSearchParams(handleSearchParams(resPre[1].preReservation)).toString(),
                                            })
                                        } else {
                                            onReturn()
                                        }
                                    },
                                )
                            } else {
                                if (res.preReservation?.discount?.code && !params.discountLabel) {
                                    setPromoCode(res.preReservation.discount.code)
                                    setFirstSubHasDiscountCode(res.preReservation.discount)
                                }
                                extendPreBookValidity(preBookingId).then(() => {
                                    setSummaryData({
                                        preReservation: res.preReservation,
                                    })
                                })
                            }
                            if (params.discountLabel) {setHaveDiscountFromPreviousStep(true)}
                        },
                    ).finally(() => setSpecialLoading(false))
                    // FROM WORDPRESS CASE
                } else if (params?.centerId
                    && params?.date
                    && params?.hour
                    && params?.mn
                    && params?.duration
                    && params?.resourceType
                    && today <= params.date
                ) {
                    preBook({
                        centerId: params?.centerId,
                        discountConfigId: params?.discountId,
                        duration: params?.duration,
                        preferredSlotId: params?.creneauID,
                        resourceType: params?.resourceType,
                        start: params?.date + "T" + params?.hour + ":" + params?.mn + ":00",
                        theme: themeHeader,
                    }).then(
                        (resu) => {
                            if (resu[1].preReservation && (params?.date + "T" + params?.hour + ":" + params?.mn + ":00") === dayjs(resu[1].preReservation.start).format("YYYY-MM-DDTHH:mm:ss")) {
                                setSummaryData({
                                    preReservation: resu[1].preReservation,
                                })
                                navigate({
                                    pathname: path(`/reserver/${resu[1].preReservation.id}`),
                                    search: createSearchParams(handleSearchParams(resu[1].preReservation)).toString(),
                                })
                            } else {
                                onReturn()
                            }
                            if (params.discountLabel) {setHaveDiscountFromPreviousStep(true)}
                        },
                    ).finally(() => setSpecialLoading(false))
                } else {onReturn()}
            }
            setSpecialLoading(false)
            return () => {}
        }, [ summaryData ],
    )

    useEffect(() => {
        if (cbCard === null) {
            setAjaxLoading(true)
            getCreditCard().then(
                (result) => {
                    setAjaxLoading(false)
                    if (result?.data?.data?.tokenDetails) {
                        setCbCard(result.data.data)
                    }
                    if (result?.data?.data?.errorCode) {
                        deleteCreditCard()
                        ModalHandler.show(CbAliasError)
                    }
                },
            )
        }
        resetNativeHistoryToCurrentLocation(window.location.href)
        window.addEventListener("popstate", onReturn)

        return () => {
            window.removeEventListener("popstate", onReturn)
        }
    }, [])

    useEffect(() => {
        setShowAcceptCGV(false)
        return () => {}
    }, [ isPaymentEnabled ])

    useEffect(() => {
        async function calculatePrice() {
            const location = preReservation
            const { part, players } = partsPlayers

            if (part && players && location) {
                const total = haveDiscountFromPreviousStep ? location.discountedPrice : location.price
                const cacheKey = `${total}-${players}`
                let pricePerPart = pricePerPartCache[cacheKey]

                if (!pricePerPart) {
                    setAjaxLoading(true)

                    const response = await getShareCalculation(location.id, players)
                    pricePerPart = response?.pricePerPart
                    setPricePerPartCache(prevCache => ({ ...prevCache, [cacheKey]: pricePerPart }))
                    setAjaxLoading(false)

                }

                if (pricePerPart !== undefined) {
                    const discount = !haveDiscountFromPreviousStep && location.discount?.discount ? location.discount.discount : 0

                    const gymlibPayout = validGymlibCode?.payout || 0
                    let tempPrice = (pricePerPart * part) - discount - (gymlibPayout)

                    if (part === players) {
                        tempPrice = total - discount - gymlibPayout
                    }

                    const price = parseFloat((tempPrice).toFixed(2))

                    setPriceToPay(price)
                    setAmountTotal(price)
                    setTotalTVA((price / 6).toFixed(2))
                    setSearchParams(handleSearchParams(location, { part, players, pricePerPart }))

                    setPricePerPart(pricePerPart)
                }
            }

            if (avoirFormData?.totalWithAvoir !== undefined) {
                dispatch(
                    setFormEntry("avoirFormData", {
                        ...avoirFormData,
                        avoirAmount: undefined,
                        checkAvoir: false,
                        totalWithAvoir: undefined,
                        tvaWithAvoir: undefined,
                    }),
                )
            }
        }

        calculatePrice()
        setShouldRecalculate(false)
    }, [ partsPlayers, preReservation, shouldRecalculate, validGymlibCode ])

    useEffect(() => {
        if (!firstSubscription) {return}

        // Calculate initial tva
        let tva = firstSubscription.discountedPrice - firstSubscription.discountedNetPrice
        let totalWithAvoir = amountTotal

        // Adjust amounts based on avoirFormData, if present
        if (avoirFormData?.totalWithAvoir !== undefined) {
            totalWithAvoir = avoirFormData.totalWithAvoir
        } else {
            setShouldRecalculate(true)
        }
        if (avoirFormData?.tvaWithAvoir !== undefined) {
            tva = avoirFormData.tvaWithAvoir
        }

        setAmountTotal(totalWithAvoir)
        setTotalTVA(tva)

    }, [ avoirFormData, firstSubscription?.id ])

    const modalProps = {
        proceedCheckout: () => {
            ModalHandler.hide(PayWithUsCard)
            proceedCheckout(false, priceToPay, false, isUsingUserCB2)
        },
        setUsCardAmount: setPriceToPay,
        usCardAmount: priceToPay,
    }

    const openModal = () => {
        ModalHandler.show(PayWithUsCard, modalProps)
    }

    useEffect(() => {
        ModalHandler.update(PayWithUsCard, modalProps)
    }, [ priceToPay ])

    useEffect(() => {
        if (isGymlib === "isGymlib") {
            setFormEntry("avoirFormData", {
                ...avoirFormData,
                avoirAmount: undefined,
                checkAvoir: false,
                totalWithAvoir: undefined,
                tvaWithAvoir: undefined,
            }),
            setCodeError(false)
            setPromoCode("")
            if (!haveDiscountFromPreviousStep && alreadyHaveDiscount) {
                deleteDiscountCode()
            }
        } else {
            setGymlibCode("")
            setCodeError(false)
            setValidGymlibCode(null)
        }
    }, [ isGymlib ])

    useEffect(() => {
        if (validGymlibCode) {
            dispatch(
                setFormEntry("avoirFormData", {
                    ...avoirFormData,
                    avoirAmount: undefined,
                    checkAvoir: false,
                    totalWithAvoir: undefined,
                    tvaWithAvoir: undefined,
                }),
            )
        }
    }, [ validGymlibCode ])

    return (
        <div className="stepManager">
            <div className="progressBar">
                <div className="progressBar__inner" style={{ width: "66%" }}></div>
            </div>
            <Step
                title={"2/ RÉCAPITULATIF"}
                help={help}
                helpId={"OrderSummaryHelp"}
                ref={step}
                cls={classNames("orderSummary noMarginBottom arrows-on-sides", {
                    "padding-bottom": UserAgentHandler.isAndroid() || UserAgentHandler.isIOS(),
                    [tunnelType?.toLowerCase()]: tunnelType,
                })}
                returnText={getReturnText()}
                onReturn={onReturn}
                otherId='last'
            >
                {(ajaxLoading || specialLoading) &&
                    <Preloader fixed />
                }
                {payzenForm}
                <div
                    className={"formsHolder c-row c-mrg--btm--4 c-mrg--btm--sm--1 orderSummary layoutSmall c-col c-col--10 " + tunnelType}>
                    {haveErrorMessage || !summaryData ? (
                        <div className="error">
                            {summaryData && <img src={mediaPath([ "/assets/images/terrain-barre.svg", "/assets/images/padel/icons/icons-terrain-barre.svg" ])} />}
                            <p>{haveErrorMessage}</p>
                        </div>
                    ) : (
                        <>
                            <div className="c-col c-col--content c-col--xm--12">
                                <div className="cartPriceDetails">
                                    <BookingInfo
                                        activity={summaryData?.selectedActivity?.name || ((preReservation?.resourceType || preReservation?.resourceType === 0) && getType(preReservation.resourceType)) }
                                        centerName={preReservation?.centerName}
                                        timeDisplay={timeDisplay}
                                        duration={duration}
                                        discount={preReservation?.discount}
                                        discountPrice={haveDiscountFromPreviousStep && preReservation?.discount && (preReservation?.discountedPrice)}
                                        price={preReservation?.price}
                                        pricePerPart={pricePerPart}
                                        error={error}
                                        freeCancelDate={freeCancelDate}
                                        freeCancelDatePassed={freeCancelDatePassed}
                                    />
                                </div>
                            </div>

                            <div className="c-col c-col--sidebar c-col--xm--12">
                                <div className="cartBox">                         
                                    <div className="cartBox__content column" ref={promoCodeInput}>
                                        <PartsSelection
                                            callback={value => {
                                                setPartsPlayers(prev => ({ ...prev, ...value }))
                                            }}
                                            specialDiscountCalculation={!!(!haveDiscountFromPreviousStep && preReservation?.discount)}
                                            disablePartSelection={haveDiscountFromPreviousStep || isGymlib === "isGymlib"}
                                            fromPrebook={preReservation}
                                            checkPlayerNumber={isGymlib === "isGymlib"}
                                        />
                                        <RadioGroup
                                            options={radioOptions}
                                            selectedValue={isGymlib}
                                            onRadioChange={setIsGymlib}
                                            className="gymLibRadio"
                                            disabled={{
                                                "isGymlib": !!validGymlibCode?.payout || !!(firstSubHasDiscountCode),
                                                "notGymlib": !!validGymlibCode?.payout || !!(firstSubHasDiscountCode || alreadyHaveDiscount),
                                            }}
                                        />
                                        {isGymlib && (
                                            <div className="discount-div">
                                                <TextInput
                                                    label={isGymlib === "isGymlib" ? "Code Gymlib" : "Code promo"}
                                                    value={isGymlib === "isGymlib" ? gymlibCode : promoCode}
                                                    customRootCls="codeInput gymlibCode"
                                                    type={isGymlib === "isGymlib" ? "number" : "text"}
                                                    inputMode={isGymlib === "isGymlib" ? "numeric" : "text"}
                                                    onChange={isGymlib === "isGymlib" ? setGymlibCode : setPromoCode}
                                                    disabled={isGymlib === "isGymlib" ? validGymlibCode : firstSubHasDiscountCode || (haveDiscountFromPreviousStep && alreadyHaveDiscount)}
                                                    onEnter={() => {
                                                        isGymlib === "isGymlib" ?
                                                            validGymlibCode ?
                                                                deleteGymlibCode()
                                                                :
                                                                checkGymlibCode()
                                                            :
                                                            !haveDiscountFromPreviousStep && (firstSubHasDiscountCode || alreadyHaveDiscount) ?
                                                                () => {
                                                                    deleteDiscountCode()
                                                                    setCodeError(false)
                                                                    setPromoCode("")
                                                                }
                                                                :
                                                                addPromoCode()
                                                    }}
                                                    actions={[ {
                                                        onClick:
                                                            isGymlib === "isGymlib" ?
                                                                validGymlibCode ?
                                                                    deleteGymlibCode
                                                                    :
                                                                    checkGymlibCode
                                                                :
                                                                !haveDiscountFromPreviousStep && (firstSubHasDiscountCode || alreadyHaveDiscount) ?
                                                                    () => {
                                                                        deleteDiscountCode()
                                                                        setCodeError(false)
                                                                        setPromoCode("")
                                                                    }
                                                                    :
                                                                    addPromoCode,
                                                        render: ((!haveDiscountFromPreviousStep && (firstSubHasDiscountCode || alreadyHaveDiscount)) || validGymlibCode) ? (
                                                            <img src={mediaPath([ "/assets/icons/cross-orange.svg", "/assets/images/padel/icons/cross-close.svg" ])}/>
                                                        ) : (
                                                            <ButtonCta
                                                                text="OK"
                                                                noSkew
                                                                noShadow
                                                                disabled={isGymlib === "isGymlib" ? !gymlibCode : !promoCode}
                                                            />),
                                                    } ]}
                                                    onBlur={() => {
                                                        isMobile ? (
                                                            isGymlib === "isGymlib" ?
                                                                validGymlibCode ?
                                                                    deleteGymlibCode()
                                                                    :
                                                                    checkGymlibCode()
                                                                :
                                                                !haveDiscountFromPreviousStep && (firstSubHasDiscountCode || alreadyHaveDiscount) ?
                                                                    () => {
                                                                        deleteDiscountCode()
                                                                        setCodeError(false)
                                                                        setPromoCode("")
                                                                    }
                                                                    :
                                                                    addPromoCode()
                                                        ) : null
                                                    }}
                                                    iconSize={"medium"}
                                                />
                                            </div>
                                        )}

                                        {(codeError || validGymlibCode) && (
                                            <div className="rowPromo">
                                                <div className="info">{validGymlibCode ? "Code activé" : "Code incorrect"}</div>
                                            </div>
                                        )}
                                        {promoCodeDiscount > 0 && !haveDiscountFromPreviousStep && (
                                            <div className="rowPromo">
                                                <div className="info">Remise :</div>
                                                <div className="price">
                                                    - <CurrencyDisplay price={promoCodeDiscount}/>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {isGymlib !== "isGymlib" && (
                                        <AvoirCheckbox
                                            totalAmount={priceToPay}
                                            tva={totalTVA}
                                            disabled={(alreadyHaveDiscount && amountTotal === 0 && avoirFormData.totalWithAvoir !== 0) || validGymlibCode}
                                            formName="avoirFormData"

                                            isPreregistration={preReservation}
                                            customActionMessage={"Utiliser mon avoir pour payer"}
                                        />
                                    )}
                                    <div className="cartBox__info column biggerPadding noMargin">
                                        <div className="rowPrice big">
                                            <div className="info">Total</div>
                                            <div className="price">
                                                <CurrencyDisplay
                                                    formatZero
                                                    price={avoirFormData?.totalWithAvoir ? avoirFormData.totalWithAvoir : amountTotal}
                                                />
                                            </div>
                                        </div>

                                        <div className="rowPrice">
                                            <div className="info">Dont TVA :</div>
                                            <div className="price">
                                                <CurrencyDisplay formatZero={true} price={totalTVA}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cartBox__cta">
                                        <div className="c-inputHolder--checkbox">
                                            <input
                                                type="checkbox"
                                                id="cartCGV"
                                                checked={paymentEnabled}
                                                value=""
                                                onChange={handleCGV}
                                            />
                                            <label>
                                                J’accepte les&nbsp;
                                                <a onClick={() => {
                                                    window.open(cgvUrl, "_blank")
                                                }}>
                                                    Conditions Générales de Vente
                                                </a>
                                            </label>
                                        </div>

                                        {!(cbCard && Object.keys(cbCard).length > 0) && (<div className="c-inputHolder--checkbox cb-div">
                                            <input
                                                type="checkbox"
                                                id="saveCard"
                                                checked={saveCard}
                                                value=""
                                                onChange={() => setSaveCard(!saveCard)}
                                            />
                                            <label>
                                                J'enregistre ma carte
                                            </label>
                                        </div>)}

                                        {cbCard && Object.keys(cbCard).length > 0 && (
                                            <div className="c-inputHolder--checkbox cb-div">
                                                <input
                                                    type="checkbox"
                                                    id="cb"
                                                    onChange={useUserCB}
                                                />
                                                <label>
                                                    <span>J'utilise ma carte enregistrée </span>
                                                    <span>({cbCard.tokenDetails.pan})</span>
                                                </label>
                                            </div>
                                        )}
                                        
                                        {showAcceptCGV && (
                                            <div className="acceptCGV">
                                                <img
                                                    alt={"Notification"}
                                                    src={
                                                        mediaPath([
                                                            "/assets/icons/notifications.svg",
                                                            "/assets/images/padel/icons/icons-notifications.svg",
                                                        ])}
                                                    className="alert"
                                                />
                                                Tu n'as pas accepté les CGV
                                            </div>
                                        )}

                                        <div className="orderSummaryButtonsHolder odd">
                                            <ButtonCta
                                                className={classNames("buttonCta--big", {
                                                    "disabled": !isPaymentEnabled,
                                                })}
                                                isCta2
                                                text={(priceToPay === 0 || avoirFormData?.totalWithAvoir === 0) ? "Finaliser" : "Payer"}
                                                onClick = {
                                                    isPaymentEnabled ?
                                                        () => {
                                                            setAjaxLoading(true)
                                                            setError(false)
                                                            if (preReservation) {
                                                                const prebook = preReservation
                                                                getPreBook(prebook.id).then(
                                                                    (res) => {
                                                                        if (res?.Message) {
                                                                            const preBookDuration = durationNumber[durationString.findIndex(duration => duration === prebook.duration)]
                                                                            let slotId = null
                                                                            if (shouldTriggerSlotNotification) {
                                                                                slotId = searchParams?.get(formArgs.SLOT_ID)
                                                                            }

                                                                            preBook({
                                                                                centerId: prebook.centerId,
                                                                                discountConfigId: prebook.discount ? prebook.discount.id : null,
                                                                                duration: preBookDuration,
                                                                                maxAttendees: partsPlayers?.players,
                                                                                preferredSlotId: slotId,
                                                                                resourceType: prebook.resourceType,
                                                                                start: dayjs(prebook.start).format("YYYY-MM-DDTHH:mm:ss"),
                                                                                theme: themeHeader,
                                                                            }).then(
                                                                                (resPre) => {
                                                                                    if (resPre?.[1]?.preReservation && dayjs(prebook.start).format("YYYY-MM-DDTHH:mm:ss") === dayjs(resPre[1].preReservation.start).format("YYYY-MM-DDTHH:mm:ss")) {
                                                                                        proceedCheckout(undefined, priceToPay, "", isUsingUserCB2, saveCard, validGymlibCode ? gymlibCode : false)
                                                                                    } else {
                                                                                        setError(res.Message)
                                                                                        setAjaxLoading(false)
                                                                                    }
                                                                                },
                                                                            )
                                                                        } else {
                                                                            modifyPreBookMaxAttendees(prebook.id, partsPlayers?.players).then(() => {
                                                                                extendPreBookValidity(prebook.id).then(() => {
                                                                                    proceedCheckout(undefined, priceToPay, "", isUsingUserCB2, saveCard, validGymlibCode ? gymlibCode : false)
                                                                                })
                                                                            })
                                                                        }
                                                                    },
                                                                )
                                                            }
                                                        }
                                                        :
                                                        () => setShowAcceptCGV(true)
                                                }
                                            />
                                        </div>
                                        {(avoirFormData?.totalWithAvoir === 0 || amountTotal === 0) && !isUsingUserCB && (
                                            <div className="avoirInfo">
                                                En cliquant sur "Finaliser", une empreinte de carte bancaire va  t'être demandée.
                                            </div>
                                        )}
                                        <div
                                            className="payWithUsCard"
                                            onClick={() => {
                                                isPaymentEnabled ? openModal() : setShowAcceptCGV(true)
                                            }}
                                        >
                                            Régler en carte ou chèques {tu("name")} ?
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Step>
        </div>
    )
}
