import classNames from "classnames"
import { node, string } from "prop-types"
import { createContext, useContext, useEffect, useState } from "react"

import useCookies from "../../hooks/useCookies.js"
import useFavicon from "../../hooks/useFavicon"

const ThemeContext = createContext()

export function useTheme() {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error("useTheme must be used within a ThemeProvider")
    }
    return context.theme
}

export function useThemeUpdate() {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error("useThemeUpdate must be used within a ThemeProvider")
    }
    return context.updateTheme
}

export function useIsWebview() {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error("useIsWebview must be used within a ThemeProvider")
    }
    return context.isWebview
}

export function useThemeInitialized() {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error("useThemeInitialized must be used within a ThemeProvider")
    }
    return [ context.themeInitialized, context.setThemeInitialized ]
}

export function ThemeProvider({ children, theme: externalTheme }) {
    const defaultTheme = "theme-soccer"
    const { isWebview, isAppV2 } = useCookies()
    const favicon = useFavicon()

    const [ theme, setTheme ] = useState(externalTheme || defaultTheme)
    const [ themeInitialized, setThemeInitialized ] = useState(false)

    function updateTheme(newTheme) {
        setTheme(newTheme)
        localStorage.setItem("lastUniverse", newTheme)
        setThemeInitialized(true)
    }

    useEffect(() => {
        favicon([ "/faviconSoccer.ico", "/faviconPadel.ico", theme ])
    }, [ theme ])

    useEffect(() => {
        if (externalTheme) {
            updateTheme(externalTheme)
            setThemeInitialized(true)
        }
    }, [ externalTheme ])

    const value = {
        isWebview,
        setThemeInitialized,
        theme,
        themeInitialized,
        updateTheme,
    }

    return (
        <ThemeContext.Provider value={value}>
            <div className={classNames("theme", theme, { isAppV2 })}>
                {children}
            </div>
        </ThemeContext.Provider>
    )
}

ThemeProvider.propTypes = {
    children: node,
    theme: string,
}
