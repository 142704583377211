import { useSelector } from "react-redux"

import Menu from "./menu"
import initializeMenu from "../../services/menu/initializeMenu"
import initializeMenuPadel from "../../services/menu/initializeMenuPadel"
import { useTheme } from "../ThemeContext/ThemeContext"

function MenuBuilder() {
    const theme = useTheme()
    const hasArdoises = useSelector(state => state.hasArdoises)
    const nextBooking = useSelector(state => state.nextBooking)
    const hasUnpaidBills = useSelector(state => state.hasUnpaidBills)
    const user = useSelector(state => state.userLoaded)

    const data = {
        hasArdoises,
        hasUnpaidBills,
        nextBooking,
        userLoaded: user,
    }

    const menuLevel1 = theme === "theme-soccer" ? initializeMenu(data).menuslevel1 : initializeMenuPadel(data).padelMenuslevel1
    const menuLevel2 = theme === "theme-soccer" ? initializeMenu(data).menuslevel2 : initializeMenuPadel(data).padelMenuslevel2

    return <Menu menuLevel1={menuLevel1} menuLevel2={menuLevel2} />
}

export default MenuBuilder
