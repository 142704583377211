import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"
import { useNavigate, useRouteError } from "react-router-dom"

import ButtonCta from "../../components/Buttons/button/button-cta.jsx"

import "./ErrorBoundary.scss"

export function ErrorBoundary() {
    const error = useRouteError()
    const navigate = useNavigate()
    const [ isOpen, setIsOpen ] = useState(false)

    const toggleOpen = () => setIsOpen(!isOpen)

    return (
        <div className={"error-boundary"}>
            <h1>Une erreur est survenue</h1>
            <ButtonCta onClick={() => navigate("/")} text={"Retour à l'accueil"}/>
            <div className={"error-boundary__container"}>
                <motion.div initial={false} animate={{ opacity: isOpen ? 1 : 0.5 }} onClick={toggleOpen}
                    className="error-boundary__toggle">
                    Voir le détails
                </motion.div>
                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                                collapsed: { height: 0, opacity: 0 },
                                open: { height: "auto", opacity: 1 },
                            }}
                            transition={{ duration: 0.15, ease: "easeInOut" }}
                            className="error-boundary__details"
                        >
                            <hr/>
                            {error?.message && <motion.div layout className={"error-boundary__message"}>{error.message}</motion.div>}
                            {error?.stack && <motion.pre layout className={"error-boundary__stack"}>{error.stack}</motion.pre>}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    )
}
