
import classNames from "classnames"
import PropTypes from "prop-types"
import Skeleton from "react-loading-skeleton"
import { useLocation, useNavigate } from "react-router-dom"

import { modifyTeamPicture } from "../../../pages/league/api/leagueTeam.js"
import { useLeagueStore } from "../../../pages/league/store/useLeagueStore.js"
import { LeaguePath } from "../../../routes/ApplicationPaths/LeaguePaths.js"
import { TeamPicture } from "../TeamPicture/TeamPicture.jsx"

import "../TeamSwitcher/TeamSwitcher.scss"

export const TeamHeading = ({ team }) => {
    const canEdit = team?.canEdit
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const {
        setShouldRevalidate,
        resetLeagueTeamCache,
        setSelectedTeamData,
        setSelectedLeagueTeam,
    } = useLeagueStore()

    const onImageChange = (file) => {
        return modifyTeamPicture(file, team.id).then(() => {
            setShouldRevalidate(true)
            resetLeagueTeamCache()
            setSelectedLeagueTeam({})
            setSelectedTeamData({})
        }).catch(error => {
            // eslint-disable-next-line no-console
            console.error("Failed to change image:", error)
            throw error
        })
    }

    const isAlreadyOnTeamPage = pathname === LeaguePath.toLeagueTeamDetails(team?.id)

    const redirectToTeam = () => {
        if (!isAlreadyOnTeamPage) {
            navigate(LeaguePath.toLeagueTeamDetails(team.id), { state: {
                hasPreviousHistory: true,
            } })
        }
    }

    return (
        <div className={classNames("team-switcher", {
            "clickable": !isAlreadyOnTeamPage,
        })} onClick={redirectToTeam}>
            {team?.name ? <TeamPicture image={team?.blazon} size={48} editable={canEdit} editCallback={onImageChange} /> : <Skeleton width={48} height={48} />}
            <div className={"team-switcher-content"}>
                <p data-testid="switchTeam" className={isAlreadyOnTeamPage ? "no-action" : null}>
                    {team?.name || <Skeleton width={240} />}
                </p>
                <span>{team?.leagueName || <Skeleton width={160} />}</span>
            </div>
        </div>
    )
}

TeamHeading.propTypes = {
    team: PropTypes.object,
}
