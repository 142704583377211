import { _apiCall, _fakedApiCallWIthDelay } from "../../../backend/axios.js"
import { returnAnswerData } from "../../../backend/helpers.js"

export function getUserLeagueTeams() {
    return _apiCall(
        "read/user/league/teams",
        "GET",
        {

        },
    ).then(
        (response) => {
            return response?.data?.data || []
        },
    )
}

export function getUserLeaguePalmares() {
    return _apiCall(
        "read/user/league/palmares",
        "GET",
    ).then(
        (response) => {
            return response?.data?.data || []
        },
    )

}

export function getLeagueGames(leagueId, teamid) {
    return _apiCall(
        `read/user/league/${leagueId}/games/all/${teamid}`,
        "GET",
    ).then(
        (response) => {
            return response?.data?.data || []
        },
    )
}

export function getLeagueRanking(leagueId) {
    return _apiCall(
        `read/league/${leagueId}/ranking`,
        "GET",
    ).then(
        (response) => {
            return response?.data?.data || []
        },
    )
}

export function getLeagueTeamPrizes(teamId) {
    return _fakedApiCallWIthDelay(
        `read/league/teams/${teamId}/prizes/summary`,
        "GET",
    ).then(returnAnswerData)
}

export function getLeagueTeamPlayers(teamId) {
    return _apiCall(
        `read/league/teams/${teamId}/players`,
        "GET",
    ).then(returnAnswerData)
}

export function getPlayerProfile(playerId) {
    return _apiCall(
        `read/league/players/${playerId}`,
        "GET",
    ).then(returnAnswerData)
}

export function getPlayerVideos(teamId, playerId) {
    return _apiCall(
        `read/league/team/${teamId}/players/${playerId}/videos`,
        "GET",
    ).then(returnAnswerData)
}

export function getLeagueTeamById(teamId) {
    return _apiCall(
        `read/league/teams/${teamId}`,
        "GET",
    ).then(returnAnswerData)
}

export function getLeagueTeamPublicById(teamId) {
    return _apiCall(
        `read/league/teams/${teamId}/public`,
        "GET",
    ).then(returnAnswerData)
}

export function changeGamePlayerStatus(gameId, playerId, status, teamId) {
    return _apiCall(
        "modify/league/game/player/status",
        "POST",
        null,
        {
            gameId,
            playerId,
            status,
            teamId,
        },
    ).then(returnAnswerData)
}

export function getLeagueGameDetailed(gameId, teamId) {
    return _apiCall(
        `read/league/team/${teamId}/game/${gameId}/detailed`,
        "GET",
    ).then(returnAnswerData)
}

export function getLeagueAds(themeHeader) {
    return _apiCall(
        `read/league/ads/${themeHeader}`,
        "GET",
    ).then(returnAnswerData)
}

export function getWordpressPosts(count = 3) {
    return _apiCall(
        `read/league/posts/${count}`,
        "GET",
    ).then(returnAnswerData)
}
