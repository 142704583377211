import "./services/errorHandler.js"

import * as ReactTooltip from "@radix-ui/react-tooltip"
import dayjs from "dayjs"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { SkeletonTheme } from "react-loading-skeleton"
import { Provider } from "react-redux"

import "dayjs/locale/fr"
import { ThemeProvider } from "./components/ThemeContext/ThemeContext"
import CookieProvider from "./providers/CookieProvider.jsx"
import { TrackerProvider } from "./providers/TrackerProvider"
import { Router } from "./routes/RoutesMapping.jsx"
import handleRedirections from "./services/handleRedirections"
import { loadUrlQueryParameters } from "./store/actions/scaffold"
import store, { history } from "./store/store"

import "./style/app.scss"
import "react-loading-skeleton/dist/skeleton.css"

dayjs.locale("fr")

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
    <StrictMode>
        <SkeletonTheme borderRadius={0}>
            <ReactTooltip.Provider
                delayDuration={50}>
                <Provider store={store}>
                    <CookieProvider>
                        <ThemeProvider>
                            <TrackerProvider>
                                <Router/>
                            </TrackerProvider>
                        </ThemeProvider>
                    </CookieProvider>
                </Provider>
            </ReactTooltip.Provider>
        </SkeletonTheme>
    </StrictMode>,
)

store.dispatch(loadUrlQueryParameters(history.location.search))
handleRedirections(store, history)
