import classNames from "classnames"
import { motion } from "framer-motion"
import { number, oneOf, shape, string } from "prop-types"
import { useEffect, useState } from "react"

import "./ProgressCircle.scss"

export default function ProgressCircle({ label, color, fill, size = 105, strokeWidth = 11 }) {
    const circumference = 2 * Math.PI * (size / 2 - strokeWidth / 2)
    const [ percent, setPercent ] = useState(circumference)
    const stroke = {
        main: "#ff7832",
        secondary: "#FFBB97",
        tertiary: "#D2D2D2",
    }

    const radius = (size - strokeWidth) / 2
    const center = size / 2

    useEffect(() => {
        if (fill) {
            const floored = Math.floor(fill * 100)
            setPercent(circumference * ((100 - floored) / 100))
        } else {
            setPercent(circumference)
        }
    }, [ fill ])

    return (
        <div className={classNames("progressCircle", { [color]: color, smaller: size < 60 })}>
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} style={{ transform: "rotate(-90deg)" }}>
                <circle r={radius} cx={center} cy={center} fill="transparent" stroke="#353535" strokeWidth={strokeWidth}></circle>
                <motion.circle
                    r={radius}
                    cx={center}
                    cy={center}
                    fill="transparent"
                    stroke={fill === 0 ? "transparent" : stroke[color]}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={percent}
                    initial={{ strokeDashoffset: circumference }}
                    animate={{ strokeDashoffset: percent }}
                    transition={{ delay: 0.6, duration: .5, ease: "easeInOut" }}
                ></motion.circle>

            </svg>
            <div className="label">
                <div className="number">{label?.number}</div>
                <div className="text">{label?.text}</div>
            </div>
        </div>
    )
}

ProgressCircle.propTypes = {
    color: oneOf([ "main", "secondary", "tertiary" ]),
    fill: number,
    label: shape({
        number: number,
        text: string,
    }),
    size: number,
    strokeWidth: number,
}

ProgressCircle.defaultProps = {
    color: "main",
}
