import { useMemo } from "react"

import MatchEventCard from "../../../../components/league/MatchEventCard/MatchEventCard.jsx"
import { useLeagueStore } from "../../store/useLeagueStore.js"

import "./LeagueMatchSummary.scss"

const LeagueMatchSummary = () => {
    const { currentGame } = useLeagueStore()

    const gameSummary = useMemo(() => {
        const summarizeGoals = (goals, teamId) => {
            const summarizedGoals = goals.filter(event => event.teamId === teamId)
                .reduce((acc, goal) => {
                    const existingGoal = acc.find(g => g.contactId === goal.contactId)
                    if (!existingGoal) {
                        acc.push({ ...goal, count: 1 })
                    } else {
                        existingGoal.count++
                    }
                    return acc
                }, [])
                .sort((a, b) => b.count - a.count)

            const invitPlayerGoals = summarizedGoals.filter(goal => !goal.contactId)
            const otherGoals = summarizedGoals.filter(goal => goal.contactId)

            return [ ...otherGoals, ...invitPlayerGoals ]
        }
        
        const categorizeCards = (cards, teamId) =>
            cards.filter(event => event.teamId === teamId)
                .reduce((acc, card) => {
                    const faultType = card.faultType === 1 ? "1" : "2"
                    if (!acc[faultType]) {
                        acc[faultType] = []
                    }
                    acc[faultType].push(card)
                    return acc
                }, {})

        const sortCardsByName = cards => {
            if (cards["1"]) {
                cards["1"].sort((a, b) => a.playerName.localeCompare(b.playerName))
            }
            if (cards["2"]) {
                cards["2"].sort((a, b) => a.playerName.localeCompare(b.playerName))
            }
            return cards
        }

        return {
            team1: {
                cards: sortCardsByName(categorizeCards(currentGame?.cards, currentGame?.team1.teamId)),
                goals: summarizeGoals(currentGame?.goals, currentGame?.team1.teamId),
                teamName: currentGame?.team1.team,
            },
            team2: {
                cards: sortCardsByName(categorizeCards(currentGame?.cards, currentGame?.team2.teamId)),
                goals: summarizeGoals(currentGame?.goals, currentGame?.team2.teamId),
                teamName: currentGame?.team2.team,
            },
        }
    }, [ currentGame ])

    return (
        <div className="leagueMatchSummary">
            {gameSummary.team1 && <MatchEventCard {...gameSummary.team1}/>}
            {gameSummary.team2 && <MatchEventCard {...gameSummary.team2}/>}
        </div>
    )
}

export default LeagueMatchSummary
