import { clearAllBodyScrollLocks } from "body-scroll-lock-upgrade"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useSearchParams } from "react-router-dom"

import ConfirmationPopinContent from "./ConfirmationPopinContent"
import { FavoriteUniversePopIn } from "./FavoriteUniversePopIn.jsx"
import { getDashboardAds } from "./home.api"
import HomeBooking from "./HomeBooking/HomeBooking"
import ProfilHomeBlock from "./ProfilHomeBlock/ProfilHomeBlock"
import NextReservationDashboard
    from "../../components/authentification/next-reservation-dashboard/next-reservation-dashboard"
import FirstConnection from "../../components/firstConnection/FirstConnection"
import { TeamInvitModal } from "../../components/league/TeamInvitModal/TeamInvitModal.jsx"
import MessagePopin from "../../components/popin/MessagePopin/MessagePopin"
import { useTheme, useThemeUpdate } from "../../components/ThemeContext/ThemeContext"
import Preloader from "../../entries/main/../../components/loaders/preloader/preloader"
import {
    getLocation,
    getTokenLocation,
    handleLocationInvitation,
    retrieveLocationPublicInvitDetails,
} from "../../globalAPI/api"
import useFavoriteUniverse from "../../hooks/universe/useFavoriteUniverse"
import useCookies from "../../hooks/useCookies"
import ModalHandler from "../../providers/Modal/ModalHandler"
import { LeaguePath } from "../../routes/ApplicationPaths/LeaguePaths.js"
import UserAgentHandler from "../../services/UserAgentHandler"
import { loadUrlQueryParameters, loadUser } from "../../store/actions/scaffold"
import { acceptTeamInvitation } from "../league/api/leagueTeam.js"
import { getLeagueTeamById } from "../league/api/myLeague.js"
import { modifyFavoriteUniverse, resetFavoriteUniverse } from "../profil/api"

import "./home.scss"

function Home() {
    const dispatch = useDispatch()
    const queryParameters = useSelector(state => state.queryParameters)
    const userLoaded = useSelector(state => state.userLoaded)
    const globalLoader = useSelector(state => state.globalLoader)
    const invitationStatus = useSelector(state => state.forms.invitationStatus)

    const haveQueryParamsAndActionLoader = queryParameters?.action && globalLoader

    const FIRST_LINE_AD = "premiere_ligne_desktop"
    const FIRST_LINE_MOBILE_AD = "premiere_ligne_mobile"
    const SECOND_LINE_AD = "seconde_ligne"

    const [ encartPub1stLine, setEncartPub1stLine ] = useState(null)
    const [ encartPub2ndLine, setEncartPub2ndLine ] = useState(null)
    const [ encartPub1stLineMobile, setEncartPub1stLineMobile ] = useState(null)

    const [ loadingTheme, setLoadingTheme ] = useState(true)

    const theme = useTheme()
    const setTheme = useThemeUpdate()
    const { isWebview, isAppV2 } = useCookies()
    const { universeId } = useParams()
    const [ searchParams ] = useSearchParams()
    const location = useLocation()
    const navigate = useNavigate()
    const universe = useFavoriteUniverse()

    useEffect(() => {
        ModalHandler.update(FirstConnection, {
            currentUser: userLoaded,
        })
    }, [ userLoaded ])

    const loadAdsFromThemeCache = (theme) => {
        const adsCache = sessionStorage.getItem("adsCache")
        if (!adsCache) {return null}

        const adsCacheObj = JSON.parse(adsCache)
        return adsCacheObj[theme] || null
    }

    const cacheAdsForTheme = (theme, ads) => {
        const adsCache = sessionStorage.getItem("adsCache")
        const adsCacheObj = adsCache ? JSON.parse(adsCache) : {}

        adsCacheObj[theme] = ads
        sessionStorage.setItem("adsCache", JSON.stringify(adsCacheObj))
    }

    function buildDashboard() {
        const cachedAds = loadAdsFromThemeCache(theme)

        if (cachedAds) {
            setEncartPub1stLine(cachedAds.firstLine)
            setEncartPub1stLineMobile(cachedAds.firstLineMobile)
            setEncartPub2ndLine(cachedAds.secondLine)
        } else {
            getDashboardAds(theme).then((res) => {
                const ads = { firstLine: null, firstLineMobile: null, secondLine: null }

                res.forEach((ad) => {
                    if (ad.status) {
                        if (ad.title === FIRST_LINE_AD) {
                            ads.firstLine = { ...ad }
                        } else if (ad.title === FIRST_LINE_MOBILE_AD) {
                            ads.firstLineMobile = { ...ad }
                        } else if (ad.title === SECOND_LINE_AD) {
                            ads.secondLine = { ...ad }
                        }
                    }
                })

                setEncartPub1stLine(ads.firstLine)
                setEncartPub1stLineMobile(ads.firstLineMobile)
                setEncartPub2ndLine(ads.secondLine)

                cacheAdsForTheme(theme, ads)
            })
        }
    }

    function favoriteUniverseIsDefined(universe) {
        if (universe === null && userLoaded.firstConnection === false) {
            openUniverseChoicePopin()
        }
    }

    const changeFavoriteUniverse = (universe) => {
        modifyFavoriteUniverse(userLoaded, universe)
            .then((theme) => {
                setTheme(theme)
                hideUniversalChoicePopin()
            })
    }

    const modalProps = {
        afterClose: buildDashboard,
        withLeagueInvitation: queryParameters.invitation === "TYPE_FRIENDSHIP_AND_LEAGUE",
    }

    const openConfirmationPopin = (details, content) => {
        ModalHandler.show(ConfirmationPopinContent, {
            ...modalProps,
            locationDetails: details,
            type: content,
        }).then(clearAllBodyScrollLocks)
    }

    const openUniverseChoicePopin = () => {
        ModalHandler.show(FavoriteUniversePopIn, {
            action: changeFavoriteUniverse,
        }).then(clearAllBodyScrollLocks)
    }

    const hideUniversalChoicePopin = () => {
        ModalHandler.hide(FavoriteUniversePopIn)
    }

    const showLeagueTeamConfirmeModal = (title, message) => {
        localStorage.removeItem("publicJoinToken")
        ModalHandler.show(MessagePopin, {
            message: message,
            title: title,
        }).then(clearAllBodyScrollLocks)
    }

    useEffect(() => {
        if (!isWebview && !userLoaded.firstConnection) {favoriteUniverseIsDefined(userLoaded.favoriteUniverse)}

        const urlTheme = searchParams?.get("theme")
        if (urlTheme && theme !== urlTheme) {
            setTheme(queryParameters.theme)
            setLoadingTheme(false)
        } else {
            setLoadingTheme(false)
        }

        const action = queryParameters.action ?? searchParams?.get("action")
        const PJT = queryParameters.PJT ?? searchParams?.get("PJT")
        const id = queryParameters.id ?? searchParams?.get("id")

        switch (action) {
        case "locationPrivateInvit":
            openConfirmationPopin(null, "privateInvit")
            break
        case "tokenActivation":
            if (userLoaded !== undefined && Object.keys(userLoaded).length) {
                if (
                    queryParameters.invitation === "TYPE_FRIENDSHIP"
                        || queryParameters.invitation === "TYPE_FRIENDSHIP_AND_LEAGUE"
                        || queryParameters.invitation === "TYPE_FRIENDSHIP_AND_CUP"
                ) {
                    openConfirmationPopin(null, "friendship")
                } else if (queryParameters.invitation === "TYPE_LOCATION" || searchParams?.get("invitation") === "TYPE_LOCATION") {
                    if (!PJT || !id) {
                        throw new Error("Information missing")
                    }

                    const publicJoinToken =  JSON.parse(localStorage.getItem("publicJoinToken"))

                    if (publicJoinToken?.token) {
                        handleLocationInvitation(publicJoinToken.token).then((res) => {
                            localStorage.removeItem("publicJoinToken")
                            if (res.data.data.Message) {
                                navigate("/")
                                ModalHandler.show(MessagePopin, {
                                    message: res.data.data.Message,
                                    title: "Erreur",
                                })
                            } else {
                                if (publicJoinToken?.id) {
                                    getLocation(publicJoinToken.id).then((result) => {
                                        navigate(publicJoinToken.theme === "theme-soccer" ? "/confirmcommand" : "/padel/confirmcommand", {
                                            state: {
                                                locationDetails: result,
                                                message: "acceptBookingInvitation",
                                                subtitle: "",
                                                success: true,
                                                title: "Tu as rejoint le match !",
                                                token: publicJoinToken.token,
                                                tunnelType: "BOOKING",
                                            },
                                        })
                                    })
                                }
                            }
                        })
                    } else if (invitationStatus?.closed) {
                        return
                    } else {
                        getTokenLocation(decodeURIComponent(PJT)).then(res => {
                            retrieveLocationPublicInvitDetails(id).then((result) => {
                                if (result) {
                                    openConfirmationPopin({ ...result, ...res }, "location")
                                }
                            })
                        })
                    }
                } else if (queryParameters.invitation === "TYPE_LEAGUE_TEAM" || searchParams?.get("invitation") === "TYPE_LEAGUE_TEAM") {
                    const teamId = queryParameters.id ?? searchParams?.get("id")
                    const fromUser = queryParameters.fromUser ?? searchParams?.get("fromUser")
                    const publicJoinToken =  JSON.parse(localStorage.getItem("publicJoinToken"))
                    if (publicJoinToken?.token) {
                        acceptTeamInvitation(publicJoinToken?.token).then(() => {
                            navigate(LeaguePath.toLeagueInfosHome(publicJoinToken.id))
                            showLeagueTeamConfirmeModal("Confirmation", "Tu as rejoint l'équipe !")
                        })
                        return
                    }

                    if (teamId) {
                        getLeagueTeamById(teamId).then((team) => {
                            if (team?.players?.find(player => player.id === userLoaded.contactId)) {
                                navigate(LeaguePath.toLeagueInfosHome(team?.id))
                                showLeagueTeamConfirmeModal("Attention", `Tu fais déjà partie de l'équipe ${team?.name} !`)
                                return
                            }
                            if (team?.publicJoinToken) {
                                ModalHandler.show(TeamInvitModal, {
                                    acceptCallback: () => {
                                        acceptTeamInvitation(team?.publicJoinToken).then(() => {
                                            navigate(LeaguePath.toLeagueInfosHome(team?.id))
                                            showLeagueTeamConfirmeModal("Confirmation", `Tu as rejoint l'équipe ${team?.name} !`)
                                        }).finally(() => {
                                            ModalHandler.hide(TeamInvitModal)
                                        })
                                    },
                                    fromUser: fromUser,
                                    loggedIn: true,
                                    team: team,

                                }).then(() => {
                                    clearAllBodyScrollLocks()
                                    dispatch(loadUrlQueryParameters(null))
                                    navigate("/")
                                })
                            }
                        })
                    }
                }
            }
            break
        }

    }, [ userLoaded, location ])

    useEffect(()=>{
        buildDashboard()
    }, [ theme ])

    useEffect(() => {
        if (userLoaded && universeId && userLoaded.favoriteUniverse === null && !isWebview) {
            const newUniverse = universe(parseInt(universeId))
            modifyFavoriteUniverse(userLoaded, newUniverse)
                .then((theme) => {
                    setTheme(theme)
                    hideUniversalChoicePopin()
                    navigate("/")
                })
        }
    }, [ universeId ])

    useEffect(() => {
        if (userLoaded && location?.pathname === "/resetUniverse") {
            resetFavoriteUniverse(userLoaded).then((res) => {
                localStorage.removeItem("lastUniverse")
                dispatch(loadUser(res.data))
                navigate("/")
            })
        }
        return () => {
            if (queryParameters && Object.keys(queryParameters)?.length) {dispatch(loadUrlQueryParameters(null))}
        }
    }, [ location ])
    const themeDifferFromAds = (theme === "theme-soccer" && (encartPub1stLine?.type !== "soccer" || encartPub1stLineMobile?.type !== "soccer" || encartPub2ndLine?.type !== "soccer"))
        || (theme === "theme-padel" && (encartPub1stLine?.type !== "padel" || encartPub1stLineMobile?.type !== "padel" || encartPub2ndLine?.type !== "padel"))

    useEffect(() => {
        const haveThemeAndAds = !!(theme && encartPub1stLine && encartPub1stLineMobile && encartPub2ndLine)
        if (haveThemeAndAds && themeDifferFromAds) {
            buildDashboard()
        }

    }, [ theme, encartPub1stLine, encartPub1stLineMobile, encartPub2ndLine ])

    return (
        <React.Fragment>
            <Preloader cls={classNames("dashboard-preloader", { isVisible: haveQueryParamsAndActionLoader || loadingTheme || !userLoaded?.id })} fixed="true" />
            {!(haveQueryParamsAndActionLoader || loadingTheme || !userLoaded?.id) && (
                <div className={classNames("home-container", {
                    "padding-bottom": (UserAgentHandler.isIOS() || UserAgentHandler.isAndroid()) && !isAppV2,
                })}>

                    <div className="home-header">
                        {theme === "theme-soccer" && <img alt="" className="lightningGrey" src="/assets/images/lightning-grey.svg" />}
                        <span className="welcome">BIENVENUE</span>
                        <span className="mainColor">{(theme === "theme-padel" ? " " : "") + userLoaded.firstname}</span>
                    </div>
                    <div className="home-content">
                        <ProfilHomeBlock />
                        <HomeBooking />
                        {encartPub1stLine && !themeDifferFromAds && (
                            <a
                                href={encartPub1stLine.url && encartPub1stLine.url !== "#" ? encartPub1stLine.url : ""}
                                target={encartPub1stLine.newTab ? "_blank" : "_self"}
                                rel="noreferrer"
                                className="dashboard-first-ads"
                            >
                                <picture>
                                    <source
                                        srcSet={encartPub1stLineMobile?.image ?? encartPub1stLine.image}
                                        media="(max-width:1200px)"
                                        onError={() => {
                                            setEncartPub1stLineMobile(null)
                                        }}
                                    />
                                    <img
                                        alt=""
                                        src={encartPub1stLine.image}
                                        onError={() => {
                                            setEncartPub1stLine(null)
                                        }}
                                    />
                                </picture>
                            </a>
                        )}
                        <NextReservationDashboard yPadding={!encartPub2ndLine}/>
                        {encartPub2ndLine && !themeDifferFromAds && (
                            <a
                                href={encartPub2ndLine.url && encartPub2ndLine.link !== "#" ? encartPub2ndLine.url : ""}
                                target={encartPub2ndLine.newTab ? "_blank" : "_self"}
                                rel="noreferrer"
                                className="dashboard-second-ads"
                            >
                                <img alt="" src={encartPub2ndLine.image} onError={() => {
                                    setEncartPub2ndLine(null)
                                }} />
                            </a>
                        )}
                    </div>
                </div>
            )
            }
        </React.Fragment>
    )

}

export default Home
