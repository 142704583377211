import dayjs from "dayjs"
import { number } from "prop-types"
import { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getUserLeagueRegistrations } from "./api/api.js"
import LeagueListingElement from "../../components/league/LeagueListingElement/LeagueListingElement.jsx"
import Listing from "../../components/listing/Listing.jsx"
import EffectifPopIn from "../../components/popin/invitations/EffectifPopIn.jsx"
import PayFreeAmount from "../../components/reservation/PayFreeAmount/PayFreeAmount.jsx"
import useProceedCheckout from "../../hooks/reservation/useProceedCheckout.jsx"
import useUrbanLanguage from "../../hooks/urbanLanguage/useUrbanLanguage.js"
import { usePaginatedListing } from "../../hooks/usePaginatedListing.js"
import ModalHandler from "../../providers/Modal/ModalHandler.jsx"
import usePath from "../../routes/services/usePath.js"
import { clearAllForms, setFormEntry } from "../../store/forms/actions.js"

export const ListingLeague = ({ onglet }) => {
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ isModalOpen, setIsModalOpen ] = useState(false)
    const [ registration, setRegistration ] = useState({})

    const path = usePath()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { tu } = useUrbanLanguage()

    const periodStart = dayjs().subtract(3, "year").format("YYYY-MM-DD")

    const showModal = () => {
        ModalHandler.show(EffectifPopIn, {
            callback: () => getItems(true),
            tunnelType: "LEAGUE",
        }, { keepPreviousProps: true, removeOnHide: false }).then(
            () => {
                dispatch(
                    clearAllForms(),
                )
            },
        )
    }

    const {
        disableActions,
        displayToIndex,
        getItems,
        isLoading,
        listItems,
        loadMore,
        totalElements,
    } = usePaginatedListing(
        getUserLeagueRegistrations,
        {
            orderByAsc: false,
            periodStart: periodStart,
            status: "[2,1,3]",
        },
        (res) => {
            dispatch(
                setFormEntry(
                    "RegistrationCount",
                    res.count || res.length,
                ),
            )
            if (res.count === 0) {
                goToTunnel(true)
            } else {
                if (onglet === "new") {
                    goToTunnel(false)
                }
            }
        },
    )

    const goToTunnel = (noReturn = false) => {
        dispatch(
            clearAllForms(),
        )
        navigate(
            path("/urbanleague/new"),
            {
                state: {
                    denyReturn: noReturn,
                },
            },
        )
    }

    const {
        proceedCheckout,
        payzenForm,
    } = useProceedCheckout({
        firstSubscription: registration,
        fromListing: true,
        lyraPaymentUrl:`/order/payment/league/${registration.id}`,
        redirectToListing: registration.billStatus === 0,
        setAjaxLoading: setAjaxLoading,
        setPayFreeAmountPopIn: setIsModalOpen,
        tunnelType: "LEAGUE",
    })

    const content = useMemo(() => {
        return listItems.map((item, index) => {
            if (index > displayToIndex) { return }

            return (
                <LeagueListingElement
                    key={index}
                    callback={getItems}
                    leagueRegistration={item}
                    effectifCallback={
                        () => {
                            dispatch(
                                setFormEntry(
                                    "TournamentRegistration",
                                    item,
                                ),
                            )
                            showModal()
                        }
                    }
                    openPaiementPopIn={
                        () => {
                            setRegistration(item)
                            setIsModalOpen(true)
                        }
                    }
                />
            )
        })
    }, [ listItems, displayToIndex ])

    return (
        <>
            <Listing
                title={`Mes ${tu("league.name")}s`}
                newButtonText="Nouvelle inscription"
                type='LEAGUE'
                loadMore={loadMore}
                itemsData={content}
                isLoading={isLoading || ajaxLoading}
                goToTunnel={() => goToTunnel()}
                disableLoadMore={disableActions}
                canShowMore={displayToIndex + 1 < totalElements}
            />
            {payzenForm}
            <PayFreeAmount
                registration={registration}
                paiementCallback={proceedCheckout}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />
        </>

    )
}

ListingLeague.propTypes = {
    onglet: number,
}
