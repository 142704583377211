import classNames from "classnames"
import { bool, func, number, object, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"

import UserAgentHandler from "../../../services/UserAgentHandler"

import "./button-cta.scss"

function ButtonCta({
    id,
    text,
    className = "",
    icon,
    rounded,
    disabled,
    onClick,
    onlyIcon,
    link,
    data,
    noHover,
    hoverChangeIcon,
    isAlt,
    isCta2,
    isCta2Alt,
    isCta3,
    isCta3Alt,
    subText,
    longer,
    noSkew,
    noShadow,
    heightFull,
    noBorder,
    widthFull,
    littleIcon,
    title,
    download,
}) {
    const [ iconPath, setIconPath ] = useState("")
    const TagName = link ? "a" : "button"
    const isIOSAndroid = UserAgentHandler.isIOS() || UserAgentHandler.isAndroid()
    useEffect(() => {
        setIconPath(icon)
    }, [ icon ])

    return (
        <TagName
            {...data}
            id={id}
            download={download}
            className = {classNames("buttonCta", className, {
                "disabled": disabled,
                "heightFull": heightFull,
                "isAlt": isAlt,
                "isCta2": isCta2,
                "isCta2Alt": isCta2Alt,
                "isCta3": isCta3,
                "isCta3Alt": isCta3Alt,
                "littleIcon" : littleIcon,
                "longer": longer,
                "no-hover": noHover,
                "noBorder": noBorder,
                "noShadow": noShadow,
                "noSkew": noSkew,
                "onlyIcon": onlyIcon,
                "rounded": rounded,
                "widthFull": widthFull,
            })}
            href={link}
            onClick={onClick}
            disabled={disabled}
            tabIndex="0" //utiliser dans login
            onMouseEnter={!isIOSAndroid ? () => {
                if (icon && hoverChangeIcon) {
                    setIconPath(hoverChangeIcon)
                }
            } : undefined}
            onMouseLeave={!isIOSAndroid ? () => {
                if (icon && hoverChangeIcon) {
                    setIconPath(icon)
                }
            } : undefined}
            title={title}
        >
            { icon && <img src={iconPath}/> }
            { !onlyIcon && <span className="buttonText">{text}</span> }
            { subText && <small>{subText}</small> }
        </TagName>
    )
}

ButtonCta.propTypes = {
    className: string,
    data: object,
    disabled: bool,
    download: bool,
    heightFull: bool,
    hoverChangeIcon: oneOfType([ bool, string ]),
    icon: string,
    id: oneOfType([ number, string ]),
    isAlt: bool,
    isCta2: bool,
    isCta2Alt: bool,
    isCta3: bool,
    isCta3Alt: bool,
    link: string,
    littleIcon: bool,
    longer: bool,
    noBorder: bool,
    noHover: bool,
    noShadow: bool,
    noSkew: bool,
    onClick: func,
    onlyIcon: bool,
    rounded: bool,
    subText: oneOfType([ string, object ]),
    text: oneOfType([ string, object ]),
    title: oneOfType([ bool, string ]),
    widthFull: bool,
}

export default ButtonCta
