/* eslint-disable react/prop-types */
import { clearAllBodyScrollLocks } from "body-scroll-lock-upgrade"
import classNames from "classnames"
import { array, bool, func, object } from "prop-types"
import { useCallback, useEffect, useState } from "react"

import Preloader from "../../../entries/main/../../components/loaders/preloader/preloader"
import { getUSUserByName } from "../../../pages/teams/urbanCup/api"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import ButtonCta from "../../Buttons/button/button-cta"
import { TextInput } from "../../Inputs/TextInput/TextInput.jsx"
import Modal from "../../Modal/Modal"
import UserLine from "../../user/userLine/UserLine"

import "./MainInvitationsPopIn.scss"

// eslint-disable-next-line react/display-name
const AskMyUrbanNetworkUser = ModalHandler.create(({ alreadySelectedMatesIds, callback, visible, hide }) => {
    const minCharCountDebounce = 3
    const [ searchedFirstName, setSearchedFirstName ] = useState("")
    const [ searchedLastName, setSearchedLastName ] = useState("")
    const [ foundUsers, setFoundUsers ] = useState(null)
    const [ filteredFoundUsers, setFilteredFoundUsers ] = useState(null)
    const [ selectedUsers, setSelectedUsers ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ hiddenMessage, setHiddenMessage ] = useState(true)

    const [ , updateState ] = useState()
    const forceUpdate = useCallback(() => updateState({}), [])

    function callbackAndClose() {
        callback(selectedUsers)
        setHiddenMessage(false)
        setTimeout(
            () => {
                setFoundUsers(null)
                setSelectedUsers(null)
                setSearchedFirstName("")
                setSearchedLastName("")
                setHiddenMessage(true)
            }, 3000,
        )
    }

    function toggleUser(user) {
        let newSelectedUsers
        if (selectedUsers && selectedUsers.includes(user)) {
            newSelectedUsers = (selectedUsers ?? [])?.filter(
                x => x !== user,
            )
        } else {
            newSelectedUsers = selectedUsers ? selectedUsers : []
            newSelectedUsers.push(user)
        }
        setSelectedUsers(
            newSelectedUsers,
        )
        forceUpdate()
    }

    useEffect(
        () => {
            if (foundUsers) {
                let users = foundUsers ?? []
                if (Array.isArray(users) === false) {
                    users = Object.values(users)
                }

                const filteredFoundUsersLoc = foundUsers ?
                    (users)?.filter(
                        user => alreadySelectedMatesIds.includes(user.id) === false,
                    )
                    :
                    null

                setFilteredFoundUsers(filteredFoundUsersLoc)
                setIsLoading(false)
            }

        }, [ foundUsers, alreadySelectedMatesIds ],
    )

    function searchUserByNameAndLastName() {
        if (searchedLastName.length >= minCharCountDebounce) {
            let firstName = searchedFirstName
            if (firstName.length < minCharCountDebounce) {
                firstName = ""
            }
            setIsLoading(true)
            getUSUserByName(firstName, searchedLastName).then(
                (res) => {
                    if (res) {
                        setFoundUsers(res.data.data)
                    } else {
                        setFoundUsers(null)
                    }
                    setIsLoading(false)
                },
            )
        }
    }

    return (
        <Modal
            isOpen={visible}
            afterOpen={() => {
                clearAllBodyScrollLocks()
            }}
            onClose={hide}
            beforeClose={
                () => {
                    setSearchedFirstName("")
                    setSearchedLastName("")
                    setFoundUsers(null)
                    setSelectedUsers(null)
                }
            }
            className="askUrbanNetwork little"
        >
            <header>Réseau MyUrban</header>
            <section className="invitationSection">
                <form
                    onSubmit={e =>{
                        e.preventDefault()
                        searchUserByNameAndLastName()
                    }}
                    data-testid="submitForm"
                >
                    <TextInput
                        label="Nom"
                        value={searchedLastName}
                        onChange={setSearchedLastName}
                        required
                        maxWidth={400}
                        fullWidth
                    />

                    <TextInput
                        label="Prénom"
                        value={searchedFirstName}
                        onChange={setSearchedFirstName}
                        disabled={searchedLastName.length < minCharCountDebounce}
                        maxWidth={400}
                        fullWidth
                    />

                    <div className="search-user-btn">
                        <ButtonCta
                            isAlt={true}
                            text={isLoading ? /* istanbul ignore next */<Preloader /> : "Rechercher"}
                            disabled={searchedLastName.length < minCharCountDebounce}
                            onClick={searchUserByNameAndLastName}
                        />
                    </div>
                </form>

                {(searchedLastName.length < minCharCountDebounce) ?
                    (<label>
                                Entre le Nom puis le Prénom de ton coéquipier dans la barre de recherche du réseau MyUrban.
                    </label>)
                    :
                    
                    filteredFoundUsers && !isLoading && (
                        <>
                            <div className="userList">
                                {filteredFoundUsers.length === 0 ?
                                    (<div className="noFoundUser">
                                        Aucun utilisateur ne correspond à ta recherche
                                    </div>)
                                    :
                                    filteredFoundUsers?.map(
                                        (user) => (
                                            <UserLine user={user}
                                                onClick={toggleUser}
                                                cls={classNames({ selected: selectedUsers && selectedUsers.includes(user) })}
                                                key={user.id}
                                            />
                                        ),
                                    )
                                }
                            </div>
                            <div className={hiddenMessage ? "successfullInvitation" : "successfullInvitation visible"}>
                                L'invitation a bien été envoyée
                            </div>
                            <ButtonCta
                                isAlt={true}
                                text="Inviter"
                                disabled={selectedUsers === null || (selectedUsers && selectedUsers.length === 0)}
                                onClick={callbackAndClose}
                            />
                        </>)
                }
            </section>
        </Modal>
    )
})

AskMyUrbanNetworkUser.propTypes = {
    alreadySelectedMatesIds: array,
    callback: func,
    isOpen: bool,
    parentPopIn: object,
    setIsOpen: func,
    showParentCallback: func,
}

export default AskMyUrbanNetworkUser
