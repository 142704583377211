import classNames from "classnames"
import { arrayOf, bool, func, node, number, oneOfType, string } from "prop-types"
import { forwardRef } from "react"
import { useNavigate } from "react-router-dom"

import "./PageHeader.scss"

const PageHeader = forwardRef(
    function pageHeader({ title, goBack, button, hideGoBackDesktop, customCls }, ref) {

        let parentCls = goBack ? "goBackParent" : "pageHeaderParent"
        parentCls = hideGoBackDesktop ? parentCls + " hideGoBackDesktop" : parentCls

        const navigate = useNavigate()

        return (
            <div
                className={classNames("o-page-header", {
                    [customCls]: customCls,
                    goBack: goBack,
                })}
                ref={ref}
            >
                {goBack && (
                    <div
                        className="goBack"
                        data-testid="goBack"
                        onClick={() => {
                            navigate(typeof goBack === "boolean" ? -1 : goBack)
                        }}
                    >
                        <img src="/assets/icons/arrow-left-black.svg" />
                    </div>
                )}
                <div className="o-page-header__wrapper">
                    <div className={parentCls}>
                        <h1>{title}</h1>
                    </div>
                    {button &&
                        (<div className="o-page-header__wrapper__buttons">
                            {button}
                        </div>)
                    }
                </div>
            </div>
        )
    },
)

PageHeader.propTypes = {
    button: oneOfType([ node, arrayOf(node) ]),
    customCls: oneOfType([ string, number ]),
    goBack: oneOfType([ func, bool, string ]),
    hideGoBackDesktop: bool,
    title: string,
}

PageHeader.displayName = "PageHeader"

export default PageHeader
