import classNames from "classnames"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

import { teamDisplayType } from "../../../model/types/league.types.js"
import { navigateToTeamPage } from "../../../pages/league/service/service.js"
import { TeamPicture } from "../TeamPicture/TeamPicture.jsx"

import "./VersusDisplay.scss"

export default function VersusDisplay({ big, team1, team2, isMatchPlayed, thunder, isForfeit }) {
    const winningTeam = team1.scoreTeam > team2.scoreTeam ? "team1" : (team1.scoreTeam < team2.scoreTeam ? "team2" : null)

    const navigate = useNavigate()
    return (
        <div className={classNames("versus-display", {
            big: big,
        })}>
            <div className="team">
                <div data-testid="goToTeam1Page" className="wrapper" onClick={() => navigateToTeamPage(navigate, team1?.teamId)}>
                    <TeamPicture image={team1?.blazon} size={big ? 68 : 39} />
                    <span>{team1?.team}</span>
                </div>
            </div>
            <div className={classNames("versus", {
                haveThunder: thunder,
                isForfeit: isForfeit,
                noScore: !isMatchPlayed,
            })}>
                {thunder && <img src="/assets/icons/doubleThunder.svg" alt="thunder" className="thunder" />}

                {isMatchPlayed
                    ? (
                        isForfeit
                            ? (
                                <span className="big">Forfait</span>
                            )
                            : (
                                <>
                                    <div className={classNames("score", { big, winning: winningTeam === "team1" })}>{team1.scoreTeam}</div>
                                    <span>VS</span>
                                    <div className={classNames("score", { big, winning: winningTeam === "team2" })}>{team2.scoreTeam}</div>
                                </>
                            )
                    )
                    : (
                        <span className="big">{isForfeit ? "Forfait" : "VS"}</span>
                    )}
            </div>
            <div className="team">
                <div data-testid="goToTeam2Page" className="wrapper" onClick={() => navigateToTeamPage(navigate, team2?.teamId)}>
                    <TeamPicture image={team2?.blazon} size={big ? 68 : 39} />
                    <span>{team2?.team}</span>
                </div>
            </div>
        </div>
    )
}

VersusDisplay.propTypes = {
    big: PropTypes.bool,
    isForfeit: PropTypes.bool,
    isMatchPlayed: PropTypes.bool,
    team1: PropTypes.shape(teamDisplayType),
    team2: PropTypes.shape(teamDisplayType),
    thunder: PropTypes.bool,
}

