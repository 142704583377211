import classNames from "classnames"
import { array, bool, func, string } from "prop-types"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

import Preloader from "../../entries/main/../../components/loaders/preloader/preloader"
import useMediaPath from "../../hooks/useMediaPath"
import { clearAllForms } from "../../store/forms/actions"
import { goToStep } from "../../store/stepsManagement/actions"
import ButtonCta from "../Buttons/button/button-cta"
import PageHeader from "../layout/pageHeader/PageHeader.jsx"
import { LoadingBar } from "../LoadingBar/LoadingBar.jsx"

import "./Listing.scss"

function Listing(props) {
    const {
        title,
        newButtonText,
        loadMore,
        itemsData,
        canShowMore,
        isLoading,
        goToTunnel,
        type,
        smallHeader,
        disableLoadMore,
    } = props

    const dispatch = useDispatch()
    const mediaPath = useMediaPath()

    useEffect(() => {
        dispatch(goToStep(0))
        dispatch(clearAllForms())
    }, [])

    return (
        <div className={classNames("listing", {
            [type.toLowerCase()]: type,
        })}>
            <div className="listing__header">
                <PageHeader
                    title={title}
                    button={(
                        <ButtonCta
                            isAlt
                            littleIcon
                            icon={mediaPath([ "/assets/icons/icon--add.svg", "" ])}
                            hoverChangeIcon={mediaPath([ "/assets/icons/icon--add-white.svg", "" ])}
                            text={newButtonText}
                            onClick={goToTunnel}
                        />
                    )}
                    customCls={classNames({ smallHeader: smallHeader })}
                />
            </div>
            {isLoading &&
                <Preloader fixed />
            }
            <div className="listing__content">
                {itemsData}
            </div>
            {!isLoading && itemsData?.length > 0 && canShowMore && (
                <ButtonCta
                    isAlt
                    color="orange"
                    text={"Voir Plus"}
                    littleIcon
                    icon={mediaPath([ (disableLoadMore ? "/assets/icons/icon--add-white.svg" :  "/assets/icons/icon--add.svg"), "" ])}
                    hoverChangeIcon={mediaPath([ "/assets/icons/icon--add-white.svg", "" ])}
                    onClick={loadMore}
                    className="displayMore"
                    disabled={disableLoadMore}
                />
            )}
            {disableLoadMore && canShowMore &&  (
                <LoadingBar cls={"listing-loading-bar"}/>
            )}
        </div>
    )
}

Listing.propTypes = {
    canShowMore: bool,
    disableLoadMore: bool,
    goToTunnel: func,
    isLoading: bool,
    itemsData: array,
    loadMore: func,
    newButtonText: string,
    smallHeader: bool,
    title: string,
    type: string,
}

export default Listing
