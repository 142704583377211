import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import useMediaPath from "../../hooks/useMediaPath"
import { clearAllForms } from "../../store/forms/actions"

function Logo() {
    const mediaPath = useMediaPath()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <a id="cy-home-btn" data-testid="logo" onClick={(e) =>  {
            e.stopPropagation()
            e.preventDefault()
            dispatch(clearAllForms())
            navigate("/")
        }} >
            <img src={mediaPath([ "/assets/images/Logo-MyUrban-Soccer-2022.svg", "/assets/images/padel/logo/Logo-MyUrban-Padel-2022.svg" ])} />
        </a>
    )
}

export default Logo
