import classNames from "classnames"
import { bool } from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { acceptConvocation, declineConvocation, getLocation, getNextLocation } from "../../../globalAPI/api"
import useShowBanScreen from "../../../hooks/supersub/showBanScreen"
import useMediaPath from "../../../hooks/useMediaPath"
import dayValues from "../../../model/enums/dayValues"
import BookingInvitation from "../../../pages/reservation/infoReservation/components/BookingInvitation/BookingInvitation"
import { listConvocations } from "../../../pages/reservation/listingReservation/api"
import { useLocationStore } from "../../../pages/reservation/store/store.js"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import usePath from "../../../routes/services/usePath"
import ConfirmPopin from "../../ConfirmPopin/ConfirmPopin"
import CurrencyDisplay from "../../currencies/CurrencyDisplay"
import DashboardBlock from "../../dashboard/dashboard-block/dashboard-block"
import Preloader from "../../loaders/preloader/preloader"
import TextButton from "../../TextButton/TextButton"
import { useTheme } from "../../ThemeContext/ThemeContext"

import "./next-reservation-dashboard.scss"

function NextReservationDashboard({ yPadding }) {
    const userLoaded = useSelector(state => state.userLoaded)
    const mediaPath = useMediaPath()
    const { currentLocation, setCurrentLocation } = useLocationStore()
    
    const durations = {
        "1 heure": { label: "1 heure", value: "60" },
        "1 heure 30": { label: "1 heure 30", value: "90" },
        "2 heures": { label: "2 heures", value: "120" },
        "45 min": { label: "45 min", value: "45" },
    }

    const checkSupersubPossibility = type => type === 0 || type === 1 || type === 13 || type === 2 || type === 3 || type === 4 || type === 8
    const maxSupersubCount = 3

    const [ nextReservation, setNextReservation ] = useState(null)
    const [ loadingNextReservation, setLoadingNextReservation ] = useState(false)
    const [ nextResDetails, setNextResDetail ] = useState({})
    const [ isConvocation, setIsConvocation ] = useState(true)

    const showBanScreen = useShowBanScreen()
    const navigate = useNavigate()
    const theme = useTheme()
    const path = usePath()

    const missingAttendees = nextReservation?.maxAttendees && (nextReservation.maxAttendees - (nextReservation?.attendeesCount ? nextReservation.attendeesCount : 1))
    const missingAttendeesWording = missingAttendees > 1 ? "joueurs manquants" : "joueur manquant"

    function findSuperSub() {
        if (userLoaded.supersubBanned && !nextReservation?.isOwner) {
            showBanScreen("supersub")
        } else {
            navigate(path("/reservations/locations/" + nextReservation.id + "/searchsupersub"))
        }
    }

    useEffect(
        () => {
            if ((!nextReservation || nextReservation?.theme !== theme) && !!userLoaded?.id && !loadingNextReservation) {
                initNextRes()
            }
            return () => {}
        }, [ userLoaded, theme ],
    )

    function initNextRes() {
        setLoadingNextReservation(true)
        setNextReservation({})
        getNextLocation().then(
            (res) => {
                if (res?.length > 0) {
                    const nextRes = res.filter(loc => loc.status !== -2)[0]
                    nextRes.dateObj = new Date(nextRes.start)
                    nextRes.dateFinObj = new Date(nextRes.dateObj.getTime() + durations[nextRes.duration].value * 60000)

                    setNextReservation({
                        ...nextRes,
                        theme: theme,
                    })
                    getLocation(nextRes.id).then(
                        (res) => {
                            if (res.invites?.find(invite => invite.playerId === userLoaded.contactId && invite.status === 0)) {
                                listConvocations().then(result => {
                                    setIsConvocation(result?.data?.data?.find(convoc => convoc?.reservation?.id === res.id && convoc.status === 0))
                                })
                            } else {
                                setIsConvocation(false)
                            }
                            setNextResDetail(res?.id ? res : {})
                            setLoadingNextReservation(false)
                        },
                    )
                } else {
                    setNextReservation({})
                    setLoadingNextReservation(false)
                }
            },
        ).catch(() => setLoadingNextReservation(false))
    }

    function goToReserver() {
        navigate(path("/reserver"))
    }

    const openInvitePopin = () => ModalHandler.show(BookingInvitation, {
        addCallback: addCallback,
        location: nextResDetails,
    })

    const closePopin = (popin) => {
        ModalHandler.hide(popin)
    }

    const confirmPopinProps = {
        actions: {
            confirm: () => {
                acceptConvocation(isConvocation?.id).then(() => setIsConvocation(false)).finally(() => closePopin(ConfirmPopin))
            },
            decline: () => {
                declineConvocation(isConvocation?.id).then(() => {
                    setNextReservation({})
                    setNextResDetail({})
                }).finally(() => closePopin(ConfirmPopin))
            },
        },
        title: "Participer au match",
    }
    const showConfirmPopin = () => {
        ModalHandler.show(ConfirmPopin, confirmPopinProps)
    }

    useEffect(() => {
        ModalHandler.update(ConfirmPopin, confirmPopinProps)
    }, [ confirmPopinProps ])

    const addCallback = location => {
        setNextReservation(prev => ({ ...prev, ...location }))
        setNextResDetail(location)
    }

    useEffect(() => {
        if (nextResDetails) {
            ModalHandler.update(BookingInvitation, {
                addCallback: addCallback,
                location: nextResDetails,
            })
        }
    }, [ nextResDetails ])

    const navigateToNextReservation = () => {
        if (isConvocation) {
            return
        } else {
            if (currentLocation?.id === nextReservation?.id) {
                setCurrentLocation({})
            }
            navigate(path("/reservations/locations/" + nextReservation?.id))
        }
    }

    return (
        <React.Fragment>
            {
                ((!!nextReservation && nextReservation?.id) || loadingNextReservation) && (
                    <div className="next-reservation">
                        <DashboardBlock>
                            <React.Fragment>
                                {loadingNextReservation ? (
                                    <Preloader small cls={"preLoader"}/>
                                ) : (
                                    <div className={`nextResData ${yPadding ? "yPadding" : ""}`}>
                                        <div className={classNames("nextLabel", { isConvocation })} onClick={navigateToNextReservation}>Prochain&nbsp;Match</div>
                                        <div className="next-res-content">
                                            <div className={classNames("next-wrapper", { isConvocation })} onClick={navigateToNextReservation}>
                                                <div className="centreName">{nextReservation.centerName}</div>
                                                <div className="centreData">
                                                    {dayValues[nextReservation.dateObj.getDay()] }&nbsp;
                                                    {nextReservation.dateObj.toLocaleDateString()}
                                                </div>
                                                <div className="centreData">
                                                    {nextReservation.start.substr(11, 2)}h{nextReservation.start.substr(14, 2)}
                                                    &nbsp;à&nbsp;
                                                    {nextReservation.end.substr(11, 2)}h{nextReservation.end.substr(14, 2)}
                                                </div>
                                                <div className="centreData">{nextReservation.resourceTypeDisplay}</div>
                                                {(nextReservation?.discountedPrice > 0) && (
                                                    <div className="centreData strong">
                                                        <CurrencyDisplay price={(nextReservation?.discountedPrice ?? nextReservation.price) - nextReservation.amountDue}/>
                                                        &nbsp;payé{((nextReservation?.discountedPrice ?? nextReservation.price) - nextReservation.amountDue) > 1 ?  "s" : ""} sur&nbsp;
                                                        <CurrencyDisplay price={(nextReservation?.discountedPrice ?? nextReservation.price)}/>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="actionWrapper">
                                                <TextButton
                                                    text={missingAttendees > 0 ? `Encore ${missingAttendees} ${missingAttendeesWording} !` : ""}
                                                    buttonLabel={missingAttendees > 0 && nextReservation?.isOwner ? "Recruter des joueurs" : isConvocation ? "Participer" : "Gérer mon match"}
                                                    isAlt
                                                    action={missingAttendees > 0 && nextReservation?.isOwner ? () => openInvitePopin() : isConvocation ? () => showConfirmPopin() : () => navigateToNextReservation()}
                                                />
                                                {(
                                                    theme === "theme-soccer" &&
                                                    nextReservation.isSupersubRequestAvailable &&
                                                    nextReservation.supersubRemainingPlayers === 0 &&
                                                    nextReservation.supersubAskedPlayers < maxSupersubCount &&
                                                    checkSupersubPossibility(nextReservation.resourceType)
                                                ) && (
                                                    <div className="findSuperSub" onClick={findSuperSub}>
                                                        <div className="findSpan">
                                                            <img className="supersub-picto" src="/assets/icons/pictoSub.svg" />
                                                            <div className="supersub-wrapper">
                                                                <span className="findSpan">Trouver</span>
                                                                <div className="superSpan">
                                                                    <span>un&nbsp;supersub&nbsp;</span>
                                                                    <span className="arrowRightSpan">&gt;</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </React.Fragment>
                        </DashboardBlock>
                    </div>
                )
            }
            {
                (!nextReservation || !nextReservation.id) && !loadingNextReservation  && (
                    <img
                        alt="Aucun match à venir"
                        className="noMatchImage"
                        src={mediaPath([ "/assets/images/no-incoming-booking.png", "/assets/images/padel/no-incoming-booking.png" ])}
                        onClick={goToReserver}
                    />
                )
            }
        </React.Fragment>
    )

}

NextReservationDashboard.propTypes = {
    yPadding: bool,
}

export default NextReservationDashboard
